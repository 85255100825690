import { createAction } from 'redux-actions';
import { Notification } from '../../models/Notification';

export const showNotification = createAction(
    'SHOW_NOTIFICATION',
    (notification: Notification) => ({ notification })
);
export const hideNotification = createAction(
    'HIDE_NOTIFICATION',
    (id: string) => ({ id })
);
