import React from 'react';

export type Confirm = (question: string) => Promise<boolean>;

type ConfirmationModalState = {
    question?: string;
    resolveConfirm?: (value: boolean) => void;
    confirm?: Confirm;
};

export const ConfirmationModalContext = React.createContext<
    ConfirmationModalState
>({});
