import { SHARED_DATA_KEY } from './SharedDataActions';
import { setValue } from 'src/utils/object';
import { productUpdateReducer } from './SharedProductService';

export function removeItemFromArray(arr: any[], itemToRemove: any): any[] {
    const itemToRemoveIndex = arr.findIndex(
        item => item?._id === itemToRemove?._id
    );
    if (itemToRemoveIndex > -1) {
        const newArr = [...arr];
        newArr.splice(itemToRemoveIndex, 1);
        return newArr;
    }
    return arr;
}

export function removeItem(data: any, propName: string, item: any): any {
    return {
        ...data,
        [propName]: removeItemFromArray(data[propName], item)
    };
}

const updateReducers: ((
    key: SHARED_DATA_KEY,
    data: any,
    propName: string
) => any)[] = [productUpdateReducer];

export function setSharedDataValue(
    key: SHARED_DATA_KEY,
    data: any,
    propName: string,
    propValue: any
): any {
    const updatedData = setValue(data, propName, propValue);

    return updateReducers.reduce(
        (acc, reducer) => reducer(key, acc, propName),
        updatedData
    );
}
