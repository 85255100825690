module.exports = [
    { code: 'AD', name: 'Mix výrobcov', currency: 'PL' },
    { code: 'AL', name: 'ALLEKRZESLA', currency: 'PL' },
    { code: 'AT', name: 'ATSKIDS', currency: 'PL' },
    { code: 'BA', name: 'Babydirekt', currency: 'EUR' },
    { code: 'BM', name: 'BOCIEK MEBLE', currency: 'PL' },
    { code: 'DL', name: 'Dolmar', currency: 'PL' },
    { code: 'DO', name: 'DOMINIKA', currency: 'PL' },
    { code: 'DR', name: 'DREAMLAND', currency: 'PL' },
    { code: 'DY', name: 'DYWANOPOL', currency: 'PL' },
    { code: 'FA', name: 'FAFARA', currency: 'PL' },
    { code: 'FD', name: 'FUNDESK', currency: 'PL' },
    { code: 'FI', name: 'FIMEX', currency: 'PL' },
    { code: 'GB', name: '', currency: '' },
    { code: 'GL', name: 'GLIWICE - nábytok', currency: 'PL' },
    { code: 'GM', name: 'Glivice - matrace', currency: 'PL' },
    { code: 'HA', name: 'Halantex', currency: 'PL' },
    { code: 'HE', name: 'Herding', currency: 'EUR' },
    { code: 'HU', name: 'HUGON', currency: 'EUR' },
    { code: 'ID', name: 'IDZCZAK', currency: 'PL' },
    { code: 'JA', name: 'JACEK', currency: 'PL' },
    { code: 'JR', name: 'JUREK MEBLE', currency: 'PL' },
    { code: 'KL', name: 'Marini', currency: 'PL' },
    { code: 'LM', name: 'Laski Meble', currency: 'PL' },
    { code: 'LU', name: 'Lukdom', currency: 'PL' },
    { code: 'MG', name: 'Mogano ', currency: 'PL' },
    { code: 'NE', name: 'NELLYS', currency: 'EUR' },
    { code: 'NY', name: 'NYKS', currency: 'PL' },
    { code: 'OR', name: 'ORISET', currency: 'PL' },
    { code: 'RM', name: 'R-MAX', currency: 'PL' },
    { code: 'SB', name: 'Skiba', currency: 'PL' },
    { code: 'SC', name: 'SCARLETT', currency: 'EUR' },
    { code: 'SK', name: 'SKRZAT', currency: 'PL' },
    { code: 'WW', name: 'WoodWedding', currency: 'PL' },
    { code: 'ZD', name: 'ZDENKATRI', currency: 'PL' },
    { code: 'ZU', name: 'ZUZPOL', currency: 'EUR' }
];
