import { CartageGroup } from 'src/cartageGroups/CartageGroupTypes';
import { onlyUnique } from 'src/common/UtilsService';

export function getAllZipCodesFromGroups(groups: CartageGroup[]): string[] {
    const zipCodes = groups.reduce(
        (allZipCodes, { zipCodes }) => [...allZipCodes, ...zipCodes],
        []
    );

    return zipCodes.filter(onlyUnique);
}
