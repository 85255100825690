import { createAction } from 'redux-actions';
import { showError } from '../common/errors/ErrorsActions';
import { Order, OrderStatus } from '../models/Order';
import { get, post } from '../utils/request';
import { cleanUpOrderListQuery } from './OrderListService';
import { OrderListQuery } from './OrderListState';
import { Dispatch } from 'redux';
import { API_URLS } from 'src/common/ApiService';

export const resetOrders = createAction('RESET_ORDERS');
export const loadingOrders = createAction('LOADING_ORDERS');
export const loadOrdersSuccess = createAction(
    'LOAD_ORDERS_SUCCESS',
    (orders: Order[]) => ({
        orders
    })
);
export const loadOrdersFailure = createAction('LOAD_ORDERS_FAILURE');
export function loadOrders(query?: OrderListQuery) {
    return async (dispatch: Dispatch<any>) => {
        dispatch(loadingOrders());
        try {
            const orders = await get<Order[]>(
                API_URLS.orders,
                cleanUpOrderListQuery(query)
            );
            dispatch(loadOrdersSuccess(orders));
        } catch (err) {
            dispatch(loadOrdersFailure());
            dispatch(showError(err));
        }
    };
}

export const updateQuery = createAction(
    'UPDATE_QUERY',
    (query: OrderListQuery) => ({ query })
);

export const checkOrder = createAction(
    'CHECK_ORDER',
    (id: string, checked: boolean) => ({
        id,
        checked
    })
);

export const updatingOrdersStatus = createAction('UPDATING_ORDERS_STATUS');
export const updateOrdersStatusSuccess = createAction(
    'UPDATE_ORDERS_STATUS_SUCCESS',
    (ordersStatus: OrderStatus[]) => ({
        ordersStatus
    })
);
export const updateOrdersStatusFailure = createAction(
    'UPDATE_ORDERS_STATUS_FAILURE'
);
export function updateOrdersStatus(orderIds: string[], status: string) {
    return async (dispatch: Dispatch<any>) => {
        dispatch(updatingOrdersStatus());
        try {
            const ordersStatus = await post('/order/update-status', {
                status,
                ids: orderIds
            });
            dispatch(updateOrdersStatusSuccess(ordersStatus));
        } catch (err) {
            dispatch(updateOrdersStatusFailure());
            dispatch(showError(err));
            throw err;
        }
    };
}
