import { Button, Grid, Typography } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import i18next from 'i18next';
import React from 'react';
import { ChangeEvent, FormEvent } from 'react';
import { isTestingEnv } from 'src/common/EnvironmentService';
import { User } from '../models/User';

interface Props extends WithStyles {
    user: User;
    onLoginSubmit: (event: FormEvent<HTMLFormElement>) => void;
    onUserChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const decorate = withStyles(() => ({
    form: {
        width: '500px'
    },
    wrapper: {
        position: 'absolute',
        width: '100%',
        height: '100%'
    }
}));

export const LoginView = decorate(
    ({ classes, user, onLoginSubmit, onUserChange }: Props) => (
        <Grid
            alignItems="center"
            direction="column"
            justify="center"
            spacing={10}
            container
            className={classes.wrapper}
        >
            <h1>J.A.R.V.I.S.</h1>
            {isTestingEnv() && (
                <Typography variant="h6" color="error" component="div">
                    Test_env
                </Typography>
            )}
            <form noValidate className={classes.form} onSubmit={onLoginSubmit}>
                <Grid direction="column" container>
                    <TextField
                        name="username"
                        label={i18next.t('Login name')}
                        margin="normal"
                        autoFocus
                        value={user.username}
                        onChange={onUserChange}
                    />
                    <TextField
                        name="password"
                        label={i18next.t('Password')}
                        type="password"
                        margin="normal"
                        value={user.password}
                        onChange={onUserChange}
                    />
                    <Button variant="contained" color="primary" type="submit">
                        {i18next.t('Login')}
                    </Button>
                </Grid>
            </form>
        </Grid>
    )
);
