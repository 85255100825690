import {
    updateSharedData,
    SHARED_DATA_KEY
} from 'src/common/sharedData/SharedDataActions';
import { OrdersToShipQuery } from './ShippingPlanningService';

export const updateOrdersToShipQuery = (query: OrdersToShipQuery) =>
    updateSharedData(SHARED_DATA_KEY.shippingPlanning, 'query', query);

export const updateSelectedOrdersIds = (selectedOrdersIds: string[]) =>
    updateSharedData(
        SHARED_DATA_KEY.shippingPlanning,
        'selectedOrdersIds',
        selectedOrdersIds
    );
