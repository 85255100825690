import React, { useEffect } from 'react';
import { match } from 'react-router-dom';
import { Grid, CircularProgress } from '@material-ui/core';
import { ProductTabs } from './ProductTabs';
import { useFetchProductToStore } from './useFetchProductToStore';

interface Props {
    match: match<{
        productId: string;
    }>;
}

export const ProductView: React.FC<Props> = ({ match }) => {
    const { requestProduct, inProgress } = useFetchProductToStore(
        match.params.productId
    );
    useEffect(() => {
        requestProduct();
    }, [requestProduct]);

    if (inProgress) {
        return (
            <Grid container justify="center">
                <Grid item xs={1}>
                    <CircularProgress size={50} />
                </Grid>
            </Grid>
        );
    }

    return <ProductTabs />;
};
