import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    Paper
} from '@material-ui/core';
import { StyleRules, withStyles, WithStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import i18next from 'i18next';
import React from 'react';
import { Enum } from '../models/Enum';
import { User } from '../models/User';
import { renderEnumOptions } from '../utils/enums';
import { NewUserDialog } from './NewUserDialog';
import { UserAddress } from './UserAddress';
import { ChangeUserPasswordButton } from './ChangeUserPasswordButton';

interface Props extends WithStyles {
    users: User[];
    newUser: User;
    showNewUserDialog: boolean;
    userRoleEnum: Enum;
    onNewUserClick: () => void;
    onNewUserChange: (propName: string, propValue: string) => void;
    onNewUserSubmit: () => void;
    onNewUserCancel: () => void;
    onRemoveUserClick: (id: string) => void;
    onUserUpdate: (user: User) => void;
}

const decorate = withStyles(theme => {
    const styles: StyleRules = {
        button: {
            margin: theme.spacing(1)
        },
        delButtonContainer: {
            textAlign: 'right'
        }
    };
    return styles;
});

function renderUsers(
    users,
    onRemoveUserClick,
    onUserUpdate,
    userRoleEnum,
    classes
) {
    return users.map(user => {
        const onRoleChange = evt => {
            onUserUpdate({
                ...user,
                role: evt.target.value
            });
        };
        return (
            <TableRow key={user._id}>
                <TableCell>{user.username}</TableCell>
                <TableCell>
                    <TextField
                        select
                        margin="normal"
                        name="role"
                        value={user.role}
                        onChange={onRoleChange}
                    >
                        {renderEnumOptions(userRoleEnum)}
                    </TextField>
                </TableCell>
                <TableCell>
                    <UserAddress user={user} onUserUpdate={onUserUpdate} />
                </TableCell>
                <TableCell className={classes.delButtonContainer}>
                    <Button
                        size="small"
                        color="default"
                        onClick={() => onRemoveUserClick(user._id)}
                    >
                        <DeleteIcon />
                    </Button>
                </TableCell>
            </TableRow>
        );
    });
}

const UserListView = decorate(
    ({
        users,
        newUser,
        showNewUserDialog,
        userRoleEnum,
        onNewUserClick,
        onNewUserChange,
        onNewUserCancel,
        onNewUserSubmit,
        onRemoveUserClick,
        onUserUpdate,
        classes
    }: Props) => (
        <>
            <TableContainer component={Paper}>
                <Table className="table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{i18next.t('User')}</TableCell>
                            <TableCell>{i18next.t('Role')}</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {renderUsers(
                            users,
                            onRemoveUserClick,
                            onUserUpdate,
                            userRoleEnum,
                            classes
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <div style={{ textAlign: 'right' }}>
                {users.length && (
                    <ChangeUserPasswordButton
                        users={users}
                        className={classes.button}
                    />
                )}
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={onNewUserClick}
                >
                    {i18next.t('Add user')}
                </Button>
            </div>
            <NewUserDialog
                open={showNewUserDialog}
                newUser={newUser}
                userRoleEnum={userRoleEnum}
                onNewUserChange={onNewUserChange}
                onCancel={onNewUserCancel}
                onSubmit={onNewUserSubmit}
            />
        </>
    )
);

export { UserListView };
