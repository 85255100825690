import { Typography } from '@material-ui/core';
import React from 'react';
import { productStatus } from 'src/common/enums/enums';
import { ProductItem } from 'src/models/Order';
import { formatWeight } from 'src/utils/formatters';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import FitnessIcon from '@material-ui/icons/FitnessCenter';
import { Alert } from '@material-ui/lab';

interface Props {
    item: ProductItem;
    showOrderItemStatusIcon: boolean;
    showItemWeight: boolean;
    isOversized: boolean;
}

export const CartageProductToShip: React.FC<Props> = ({
    item,
    showOrderItemStatusIcon,
    showItemWeight,
    isOversized
}) => {
    const itemView = (
        <>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    whiteSpace: 'nowrap'
                }}
            >
                {item.quantity > 1 && (
                    <Typography
                        color="secondary"
                        display="inline"
                    >{`${item.quantity} x `}</Typography>
                )}
                <Typography display="inline">{`${item.productCode}`}</Typography>
                {showItemWeight && (
                    <Typography
                        display="inline"
                        variant="body2"
                    >{`, ${formatWeight(item.weight)}`}</Typography>
                )}
                {showOrderItemStatusIcon && (
                    <LocalShippingIcon
                        style={{ marginLeft: '5px' }}
                        color={
                            item.status === productStatus.READY_TO_SHIP
                                ? 'disabled'
                                : 'error'
                        }
                    />
                )}
            </div>
            <Typography display="block" variant="caption">
                {item.name}
            </Typography>
            <Typography display="block" variant="caption" color="textSecondary">
                {item.variant}
            </Typography>
        </>
    );

    if (!isOversized) {
        return <>{itemView}</>;
    }

    return (
        <Alert icon={<FitnessIcon fontSize="inherit" />} severity="warning">
            {itemView}
        </Alert>
    );
};
