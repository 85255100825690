import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import i18next from 'i18next';
import React from 'react';

interface Props extends WithStyles {
    errors: any[];
    onClose: () => void;
}

const decorate = withStyles({
    dialogContent: {
        wordBreak: 'break-all'
    }
});

export const ErrorsView = decorate(({ errors, classes, onClose }: Props) => {
    const error = errors[0];
    if (!error) {
        return null;
    }

    const errorWithoutPrefix = (error.message ?? error).replace(/^Error: /, '');
    return (
        <Dialog open>
            <DialogTitle id="simple-dialog-title">
                {i18next.t('Uppps some error ocurred')}
            </DialogTitle>
            <DialogContent>
                <DialogContentText
                    id="alert-dialog-description"
                    className={classes.dialogContent}
                >
                    {i18next.t(`apiError.${errorWithoutPrefix}`)}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={onClose}>
                    {i18next.t('Close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
});
