import {
    Button,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    Paper
} from '@material-ui/core';
import i18next from 'i18next';
import React from 'react';
import { EnumsState } from '../common/enums/EnumsState';
import { AdminRole } from '../components/AdminRole';
import { Shop } from '../models/Shop';
import { ShopDialog } from './ShopDialog';
import { ShopListRows } from './ShopListRows';

interface Props {
    isLoading: boolean;
    shops: Shop[];
    enums: EnumsState;
    showShopDialog: boolean;
    shop: Shop;
    onAddNewShopClick: () => void;
    onShopChange: (propName: string, propValue: string) => void;
    onShopSubmit: () => void;
    onShopCancel: () => void;
    onRemoveShopClick: (id: string) => void;
    onEditShopClick: (shop: Shop) => void;
}

const useStyles = makeStyles(theme => ({
    addButtonPosition: {
        margin: theme.spacing(1),
        textAlign: 'right'
    }
}));

export const ShopListView: React.FC<Props> = ({
    isLoading,
    shops,
    enums,
    shop,
    showShopDialog,
    onAddNewShopClick,
    onShopChange,
    onShopCancel,
    onShopSubmit,
    onRemoveShopClick,
    onEditShopClick
}) => {
    const classes = useStyles();

    return (
        <>
            <TableContainer component={Paper}>
                <Table className="table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{i18next.t('Name')}</TableCell>
                            <TableCell>{i18next.t('Type')}</TableCell>
                            <TableCell>{i18next.t('Country')}</TableCell>
                            <TableCell>{i18next.t('Last import')}</TableCell>
                            <TableCell>{i18next.t('Import')}</TableCell>
                            <AdminRole>
                                <TableCell />
                            </AdminRole>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <ShopListRows
                            isLoading={isLoading}
                            shops={shops}
                            onEditShopClick={onEditShopClick}
                            onRemoveShopClick={onRemoveShopClick}
                        />
                    </TableBody>
                </Table>
            </TableContainer>
            <AdminRole>
                <div className={classes.addButtonPosition}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onAddNewShopClick}
                    >
                        {i18next.t('Add shop')}
                    </Button>
                </div>
            </AdminRole>
            <ShopDialog
                open={showShopDialog}
                shop={shop}
                enums={enums}
                onCancel={onShopCancel}
                onSubmit={onShopSubmit}
                onShopChange={onShopChange}
            />
        </>
    );
};
