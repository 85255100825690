export function copyToClipboard(value: string): void {
    // does not works due to missing https
    // navigator.clipboard.writeText(cartage.name.replace(/\s/g, ''));
    // text area method
    const textArea = document.createElement('textarea');
    textArea.value = value;
    // make the textarea out of viewport
    textArea.style.position = 'fixed';
    textArea.style.left = '-999999px';
    textArea.style.top = '-999999px';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    window.document.execCommand('copy');
    textArea.remove();
}
