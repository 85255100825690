import React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { match } from 'react-router';
import { EnumsState } from '../common/enums/EnumsState';
import { AppState } from '../models/AppState';
import { Order, ProductItem } from '../models/Order';
import { getOrderCurrencySymbol } from '../utils/price';
import {
    addItem,
    addSubItem,
    removeSubItem,
    invalidateOrderCache,
    loadOrder,
    removeItem,
    updateOrder
} from './OrderActions';
import { filterValidProductStatuses } from './OrderService';
import { OrderView } from './OrderView';

interface Props {
    enums: EnumsState;
    match: match<{
        orderId: string;
    }>;
    orderId: string;
    order: Order;
    orders: Order[];
    isLoading: boolean;
    isEditing: boolean;
    userRole: string;
    onRemoveItem: (productItem: ProductItem) => void;
    onAddItem: () => void;
    onAddSubItem: (item: ProductItem) => void;
    onRemoveSubItem: (item: ProductItem, subItem: ProductItem) => void;
    onOrderChange: (propertyName: string, value: any) => void;
    onLoadOrder: (orderId: string) => void;
    onInvalidateOrderCache: () => void;
}

class OrderContainerBase extends Component<Props> {
    componentDidMount() {
        this.props.onLoadOrder(this.props.match.params.orderId);
    }

    componentWillUnmount(): void {
        this.props.onInvalidateOrderCache();
    }

    componentDidUpdate(prevProps: Props) {
        if (
            prevProps.match.params.orderId === this.props.match.params.orderId
        ) {
            return;
        }

        this.props.onLoadOrder(this.props.match.params.orderId);
    }

    render() {
        const { order, enums, isLoading, isEditing, userRole } = this.props;
        const validProductStatusesEnum = filterValidProductStatuses(
            enums.productStatus.enum,
            order && order.status
        );
        return (
            <>
                <OrderView
                    enums={enums}
                    validProductStatusEnum={validProductStatusesEnum}
                    order={order}
                    currency={getOrderCurrencySymbol(order)}
                    isLoading={isLoading}
                    isEditing={isEditing}
                    userRole={userRole}
                    onAddItem={this.props.onAddItem}
                    onAddSubItem={this.props.onAddSubItem}
                    onRemoveSubItem={this.props.onRemoveSubItem}
                    onRemoveItem={this.props.onRemoveItem}
                    // @ts-ignore select
                    onOrderChange={this.props.onOrderChange}
                />
            </>
        );
    }
}

const mapStateToProps = (state: AppState) => ({
    isLoading: state.order.isLoading,
    isEditing: state.order.isEditing,
    order: state.order.data,
    orders: state.orderList.data,
    enums: state.enums,
    userRole: state.session.user.role
});

const mapDispatchToProps = {
    onLoadOrder: loadOrder,
    onRemoveItem: removeItem,
    onAddItem: addItem,
    onAddSubItem: addSubItem,
    onRemoveSubItem: removeSubItem,
    onOrderChange: updateOrder,
    onInvalidateOrderCache: invalidateOrderCache
};

const OrderContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderContainerBase);

export { OrderContainer };
