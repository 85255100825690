import React from 'react';
import { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../models/AppState';
import { Enum } from '../models/Enum';
import { User } from '../models/User';
import {
    loadUsers,
    removeUser,
    resetNewUser,
    saveUser,
    showNewUserDialog,
    updateNewUser
} from './UserListActions';
import { UserListView } from './UserListView';

interface Props {
    users: User[];
    newUser: User;
    showNewUserDialog: boolean;
    userRoleEnum: Enum;
    onLoadUsers: () => void;
    onShowNewUserDialog: (value: boolean) => void;
    onNewUserChange: (propName: string, propValue: string) => void;
    onUserSave: (user: User) => Promise<void>;
    onResetNewUser: () => void;
    onRemoveUser: (id: string) => Promise<void>;
}

export class UserListContainerBase extends Component<Props> {
    constructor(props: Props) {
        super(props);

        props.onLoadUsers();
    }

    render(): ReactNode {
        const {
            users,
            showNewUserDialog,
            newUser,
            userRoleEnum,
            onNewUserChange,
            onUserSave
        } = this.props;
        return (
            <UserListView
                users={users}
                newUser={newUser}
                showNewUserDialog={showNewUserDialog}
                userRoleEnum={userRoleEnum}
                onNewUserChange={onNewUserChange}
                onNewUserClick={this.onNewUserClick}
                onNewUserCancel={this.onNewUserCancel}
                onNewUserSubmit={this.onNewUserSubmit}
                onRemoveUserClick={this.onRemoveUserClick}
                onUserUpdate={onUserSave}
            />
        ) as ReactNode;
    }

    onNewUserCancel = () => {
        this.props.onShowNewUserDialog(false);
    };

    onNewUserSubmit = async () => {
        await this.props.onUserSave(this.props.newUser);
        this.props.onLoadUsers();
        this.props.onShowNewUserDialog(false);
        this.props.onResetNewUser();
    };

    onNewUserClick = () => {
        this.props.onShowNewUserDialog(true);
    };

    onRemoveUserClick = (id: string) => {
        this.props.onRemoveUser(id);
    };
}

const mapStateToProps = (state: AppState) => ({
    users: state.userList.userList,
    newUser: state.userList.newUser,
    showNewUserDialog: state.userList.showNewUserDialog,
    userRoleEnum: state.enums.userRole.enum
});

const mapDispatchToProps = {
    onLoadUsers: loadUsers,
    onShowNewUserDialog: showNewUserDialog,
    onNewUserChange: updateNewUser,
    onUserSave: saveUser,
    onResetNewUser: resetNewUser,
    onRemoveUser: removeUser
};

const UserListContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(UserListContainerBase);

export { UserListContainer };
