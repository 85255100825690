import { Button, Divider, Grid, Typography } from '@material-ui/core';
import i18next from 'i18next';
import React from 'react';
import { API_URLS } from 'src/common/ApiService';
import { apiHost } from 'src/utils/request';
import DownloadIcon from '@material-ui/icons/GetApp';

export const OrdersExport: React.FC = () => {
    return (
        <>
            <h1 style={{ marginTop: '10px', marginBottom: '10px' }}>
                {i18next.t('ordersExport.heading')}
            </h1>

            <Grid container spacing={3}>
                <Grid container item spacing={3} alignItems="center">
                    <Grid item>
                        <Button
                            startIcon={<DownloadIcon />}
                            variant="contained"
                            href={`${apiHost}${API_URLS.openOrdersStockProductsStats}`}
                        >
                            {i18next.t('download')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Typography>
                            {i18next.t(
                                'ordersExport.openOrdersStockProductsStatsInfo'
                            )}
                        </Typography>
                    </Grid>
                </Grid>
                <Divider />
                <Grid container item spacing={3} alignItems="center">
                    <Grid item>
                        <Button
                            startIcon={<DownloadIcon />}
                            variant="contained"
                            href={`${apiHost}${API_URLS.ordersStockProductsStats}`}
                        >
                            {i18next.t('download')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Typography>
                            {i18next.t(
                                'ordersExport.ordersStockProductsStatsInfo'
                            )}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};
