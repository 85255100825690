import { Action, handleActions } from 'redux-actions';
import { replaceItemInArray } from '../common/UtilsService';
import { Shop } from '../models/Shop';
import * as actions from './ShopListActions';
import { defaultShop } from './ShopListService';
import { ShopListState } from './ShopListState';

const defaultState: ShopListState = {
    shops: [],
    isLoading: false,
    showShopDialog: false,
    shop: { ...defaultShop }
};

interface LoadOrderSuccessPayload {
    shop: Shop;
}
interface LoadShopsSuccessPayload {
    shops: Shop[];
}
interface ShowShopDialogPayload {
    show: boolean;
}
interface SetShopDialogPayload {
    shop: Shop;
}

interface UpdateShopPayload {
    propName: string;
    propValue: string;
}

const ShopListReducer = handleActions<ShopListState, any>(
    {
        [actions.loadingShops.toString()]: state => ({
            ...state,
            isLoading: true
        }),
        [actions.loadShopsSuccess.toString()]: (
            state,
            action: Action<LoadShopsSuccessPayload>
        ) => ({
            ...state,
            shops: [...action.payload.shops],
            isLoading: false
        }),
        [actions.loadShopsFailure.toString()]: state => ({
            ...state,
            isLoading: false
        }),
        [actions.showShopDialog.toString()]: (
            state,
            action: Action<ShowShopDialogPayload>
        ) => ({
            ...state,
            showShopDialog: action.payload.show
        }),
        [actions.updateShop.toString()]: (
            state,
            action: Action<UpdateShopPayload>
        ) => ({
            ...state,
            shop: {
                ...state.shop,
                [action.payload.propName]: action.payload.propValue
            }
        }),
        [actions.setShop.toString()]: (
            state,
            action: Action<SetShopDialogPayload>
        ) => ({
            ...state,
            shop: { ...action.payload.shop }
        }),
        [actions.loadShopSuccess.toString()]: (
            state,
            action: Action<LoadOrderSuccessPayload>
        ) => ({
            ...state,
            shops: replaceItemInArray(state.shops, action.payload.shop)
        })
    },
    defaultState
);

export { ShopListReducer };
