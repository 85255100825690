import { Dispatch } from 'redux';
import { createAction } from 'redux-actions';
import { showError } from '../common/errors/ErrorsActions';
import { Shop } from '../models/Shop';
import { del, get, patch, post } from '../utils/request';

export const loadShopSuccess = createAction(
    'LOAD_SHOP_SUCCESS',
    (shop: Shop) => ({ shop })
);

export const loadingShops = createAction('LOADING_SHOPS');
export const loadShopsSuccess = createAction(
    'LOAD_SHOPS_SUCCESS',
    (shops: Shop[]) => ({
        shops
    })
);
export const loadShopsFailure = createAction('LOAD_SHOPS_FAILURE');
export function loadShops() {
    return async (dispatch: Dispatch<any>) => {
        dispatch(loadingShops());
        try {
            const shops = await get<Shop[]>('/shop');
            dispatch(loadShopsSuccess(shops));
        } catch (err) {
            dispatch(loadShopsFailure());
            dispatch(showError(err));
        }
    };
}

export const savingShop = createAction('SAVING_SHOP');
export const saveShopSuccess = createAction('SAVE_SHOP_SUCCESS');
export const saveShopFailure = createAction('SAVE_SHOP_FAILURE');
export function saveShop(shop: Shop) {
    return async (dispatch: Dispatch<any>) => {
        dispatch(savingShop());
        try {
            if (shop._id != null) {
                await patch('/shop', shop._id, shop);
            } else {
                await post('/shop', shop);
            }
            // @ts-ignore select
            dispatch(loadShops());
            dispatch(saveShopSuccess());
        } catch (err) {
            dispatch(saveShopFailure());
            dispatch(showError(err));
        }
    };
}

export const showShopDialog = createAction(
    'SHOW_SHOP_DIALOG',
    (show: boolean) => ({
        show
    })
);
export const setShop = createAction('SET_SHOP', (shop: Shop) => ({
    shop
}));
export const updateShop = createAction(
    'UPDATE_SHOP',
    (propName: string, propValue: string) => ({
        propName,
        propValue
    })
);

export const removingShop = createAction('REMOVING_SHOP');
export const removeShopSuccess = createAction('REMOVE_SHOP_SUCCESS');
export const removeShopFailure = createAction('REMOVE_SHOP_FAILURE');
export function removeShop(id: string) {
    return async (dispatch: Dispatch<any>) => {
        dispatch(removingShop());
        try {
            await del('/shop', id);
            // @ts-ignore select
            dispatch(loadShops());
            dispatch(removeShopSuccess());
        } catch (err) {
            dispatch(removeShopFailure());
            dispatch(showError(err));
        }
    };
}
