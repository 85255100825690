import { EnumState } from '../../common/enums/EnumsState';
import { resolveEnumValue } from '../../utils/enums';
import { OrderStats } from './OverviewTypes';

function getOrdersCountForValue(
    ordersStatusOverview: OrderStats[],
    value: string
): number {
    const orderStatusOverview = ordersStatusOverview.find(
        orderStatusOverview => orderStatusOverview.value === value
    );

    if (orderStatusOverview) {
        return orderStatusOverview.count;
    }

    return 0;
}

export function getOrdersCounts(
    ordersStatusOverview: OrderStats[],
    orderValues: string[]
): number[] {
    return orderValues.map(status =>
        getOrdersCountForValue(ordersStatusOverview, status)
    );
}

export function getResolvedStatusesValues(
    orderStatusEnum: EnumState,
    orderStatuses: string[]
): string[] {
    return orderStatuses.map(status =>
        resolveEnumValue(orderStatusEnum.enum, status)
    );
}
