import { Typography } from '@material-ui/core';
import React from 'react';

import { TypographyProps } from '@material-ui/core/Typography';

export const Value: React.FC<TypographyProps> = ({ children, ...props }) => (
    <Typography
        style={{
            display: 'inline-block'
        }}
        variant="body1"
        gutterBottom
        {...props}
    >
        {children}
    </Typography>
);
