import { CircularProgress, Grid, WithStyles } from '@material-ui/core';
import { StyleRules, withStyles } from '@material-ui/core/styles';
import React from 'react';

const decorate = withStyles(theme => {
    const styles: StyleRules = {
        wrapper: {
            position: 'absolute',
            width: '100%',
            height: '100%'
        },
        loader: {
            margin: theme.spacing(2)
        }
    };
    return styles;
});

const InitialLoadingView = decorate(({ classes }: WithStyles) => (
    <Grid
        alignItems="center"
        direction="column"
        justify="center"
        spacing={10}
        container
        className={classes.wrapper}
    >
        <h1>J.A.R.V.I.S.</h1>
        <CircularProgress className={classes.loader} size={50} />
    </Grid>
));

export { InitialLoadingView };
