import React, { useCallback, ChangeEvent } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { StyleRules, withStyles, WithStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

interface Props extends WithStyles {
    value: string;
    placeholder?: string;
    onChange: (value: string) => void;
}

const decorate = withStyles(theme => {
    const styles: StyleRules = {
        searchForm: {
            fontFamily: theme.typography.fontFamily,
            position: 'relative',
            marginLeft: theme.spacing(1),
            borderRadius: 2,
            background: fade(theme.palette.common.white, 0.15),
            '&:hover': {
                background: fade(theme.palette.common.white, 0.25)
            },
            width: '100%'
        },
        search: {
            width: theme.spacing(9),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        input: {
            font: 'inherit',
            padding: `${theme.spacing(1)}px ${theme.spacing(
                1
            )}px ${theme.spacing(1)}px
            ${theme.spacing(9)}px`,
            border: 0,
            display: 'block',
            verticalAlign: 'middle',
            whiteSpace: 'normal',
            background: 'none',
            margin: 0, // Reset for Safari
            color: 'inherit',
            width: '100%',
            '&:focus': {
                outline: 0
            },
            '&::placeholder': {
                color: fade(theme.palette.common.white, 0.5)
            }
        }
    };

    return styles;
});

export const SearchInput = decorate(
    ({ value, placeholder, onChange, classes }: Props) => {
        const onSearchSubmit = useCallback(evt => {
            evt.preventDefault();
        }, []);

        const onChangeCallback = useCallback(
            (event: ChangeEvent<HTMLInputElement>) => {
                event.preventDefault();
                onChange(event.currentTarget.value);
            },
            [onChange]
        );

        const trimPastedValue = useCallback(
            (evt: any) => {
                evt.preventDefault();
                const paste = (evt.clipboardData.getData('text') ?? '').trim();

                const el = evt.currentTarget;
                const cursorPosStart = el.selectionStart;
                const cursorPosEnd = el.selectionEnd;
                const v = el.value;
                const textBefore = v.substring(0, cursorPosStart);
                const textAfter = v.substring(cursorPosEnd, v.length);
                const mergedText = textBefore + paste + textAfter;
                // insert text manually by updating model to trigger msd-elastic directive
                el.value = mergedText;
                // move cursor to correct position after paste
                setTimeout(function() {
                    el.selectionStart = el.selectionEnd =
                        cursorPosStart + paste.length;
                    onChange(mergedText);
                });
            },
            [onChange]
        );

        return (
            <form className={classes.searchForm} onSubmit={onSearchSubmit}>
                <div className={classes.search}>
                    <SearchIcon />
                </div>
                <input
                    className={classes.input}
                    value={value}
                    placeholder={placeholder}
                    onChange={onChangeCallback}
                    onPaste={trimPastedValue}
                />
            </form>
        );
    }
);
