import {
    Grid,
    Card,
    CardContent,
    Typography,
    FormControlLabel,
    Checkbox,
    Button
} from '@material-ui/core';
import i18next from 'i18next';
import React from 'react';
import moment from 'moment';
import CashIcon from '@material-ui/icons/AttachMoney';
import CardIcon from '@material-ui/icons/CreditCard';
import { useRequest } from 'src/common/useRequestHook';
import { RequestMethod } from 'src/utils/request';
import { API_URLS } from 'src/common/ApiService';

interface Props {
    showOnlyNotPaidOrders: boolean;
    disabled: boolean;
    selectedOrdersIds: string[];
    shippingDate: string;
    onShowUnpaidChange(evt): void;
    onOrdersPaymentUpdate(): void;
}

export const OrdersPayment: React.FC<Props> = ({
    showOnlyNotPaidOrders,
    disabled,
    selectedOrdersIds,
    shippingDate,
    onShowUnpaidChange,
    onOrdersPaymentUpdate
}) => {
    const [updateOrders, { inProgress: isUpdating }] = useRequest(
        RequestMethod.POST,
        API_URLS.setOrdersAsPaid
    );

    const setOrdersAsPaid = (paymentType: 'cash' | 'card') => {
        updateOrders({
            ordersIds: selectedOrdersIds,
            paymentType,
            date: moment(shippingDate).format('YYYY-MM-DD')
        }).then(onOrdersPaymentUpdate);
    };

    return (
        <Grid item container xs={12}>
            <Card style={{ width: '100%' }}>
                <CardContent>
                    <Typography>
                        {i18next.t('shippingPlanning.payments')}
                    </Typography>
                    <Grid container justify="space-between">
                        <Grid item xs={12} md={true}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={showOnlyNotPaidOrders}
                                        color="secondary"
                                        onChange={onShowUnpaidChange}
                                    />
                                }
                                label={i18next.t(
                                    'shippingPlanning.showOnlyNotPaidSection'
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={true}>
                            <Button
                                variant="contained"
                                color="default"
                                startIcon={<CashIcon />}
                                style={{ marginRight: '10px' }}
                                disabled={isUpdating || disabled}
                                onClick={() => setOrdersAsPaid('cash')}
                            >
                                {i18next.t('shippingPlanning.paidWithCash')}
                            </Button>
                            <Button
                                variant="contained"
                                color="default"
                                startIcon={<CardIcon />}
                                disabled={isUpdating || disabled}
                                onClick={() => setOrdersAsPaid('card')}
                            >
                                {i18next.t('shippingPlanning.paidWithCard')}
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
};
