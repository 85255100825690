import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback } from 'react';
import { SearchInput } from 'src/components/SerchInput';
import PostAddIcon from '@material-ui/icons/PostAdd';
import { updateQuery } from './ProductListActions';
import i18next from 'i18next';
import { selectProductListQuery } from './ProductListSelectors';
import { Tooltip, IconButton } from '@material-ui/core';
import { tooltipEnterDelay } from 'src/config';
import { appPaths } from 'src/AppRouterService';
import { NavLink } from 'react-router-dom';

export const ProductListAppBarView: React.FC = () => {
    const dispatch = useDispatch();
    const searchValue = useSelector(selectProductListQuery);

    const onChange = useCallback(
        (value: string) => {
            dispatch(updateQuery({ search: value }));
        },
        [dispatch]
    );

    return (
        <>
            <SearchInput
                value={searchValue}
                placeholder={i18next.t('productList.searchPlaceholder')}
                onChange={onChange}
            />
            <Tooltip
                title={i18next.t('productList.addNewProductBtn')}
                enterDelay={tooltipEnterDelay}
            >
                <div>
                    <IconButton
                        color="inherit"
                        component={NavLink}
                        to={appPaths.addNewProduct}
                    >
                        <PostAddIcon />
                    </IconButton>
                </div>
            </Tooltip>
        </>
    );
};
