import React, { useEffect } from 'react';
import {
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    Paper
} from '@material-ui/core';
import i18next from 'i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { useFetchedItems } from '../common/useFetchedItemsHook';
import { API_URLS } from '../common/ApiService';
import { OrderToShip } from './ShippingPlanningService';
import { OrdersToShipList } from './OrdersToShipList';
import { useDebounce } from 'use-debounce/lib';
import { useSelector } from 'react-redux';
import { selectShippingQuery } from './ShippingPlanningSelectors';
import { SEARCH_DELAY } from 'src/config';
import { useOrderToShipCheckAll } from './useOrdersToShipCheckAll';

interface Props {
    ordersWithAssignShipping: boolean;
}

export const OrdersToShipView: React.FC<Props> = ({
    ordersWithAssignShipping
}) => {
    const {
        items,
        isLoading,
        hasMore,
        fetchItems,
        fetchNextItems
    } = useFetchedItems<OrderToShip>(API_URLS.ordersToShip);

    const {
        isCheckAllChecked,
        isCheckAllIndeterminate,
        handleCheckChange
    } = useOrderToShipCheckAll(items);

    const [debouncedQuery] = useDebounce(
        useSelector(selectShippingQuery),
        SEARCH_DELAY
    );

    const hideDeliveryType = ordersWithAssignShipping;

    useEffect(() => {
        window.scrollTo({ top: 0 });
        fetchItems({
            search: debouncedQuery.search,
            shipping: ordersWithAssignShipping
                ? debouncedQuery.shipping
                : undefined
        });
    }, [debouncedQuery]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <TableContainer component={Paper}>
            <Table className="table">
                <TableHead className="no-print">
                    <TableRow>
                        <TableCell>
                            <Checkbox
                                checked={isCheckAllChecked}
                                indeterminate={isCheckAllIndeterminate}
                                onChange={handleCheckChange}
                            />
                        </TableCell>
                        <TableCell>
                            {i18next.t('shippingPlanning.orderNumber')}
                        </TableCell>
                        {!hideDeliveryType && (
                            <TableCell>
                                {i18next.t('shippingPlanning.shippingType')}
                            </TableCell>
                        )}
                        <TableCell>
                            {i18next.t('shippingPlanning.date')}
                        </TableCell>
                        <TableCell>
                            {i18next.t('shippingPlanning.address')}
                        </TableCell>
                        <TableCell>
                            {i18next.t('shippingPlanning.items')}
                        </TableCell>
                        <TableCell>
                            {i18next.t('shippingPlanning.note')}
                        </TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <InfiniteScroll
                    element={TableBody}
                    initialLoad={false}
                    loadMore={fetchNextItems}
                    hasMore={hasMore}
                >
                    <OrdersToShipList
                        isLoading={isLoading}
                        hideDeliveryType={hideDeliveryType}
                        hasMore={hasMore}
                        orders={items}
                    />
                </InfiniteScroll>
            </Table>
        </TableContainer>
    );
};
