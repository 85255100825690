import { Moment } from 'moment';
import { orderStatus, productStatus } from '../common/enums/enums';
import { Order, OrderStatus } from '../models/Order';
import { OrderListQuery } from './OrderListState';

const formatDate = (date: Moment) =>
    date ? date.format('YYYY-MM-DD') : undefined;

export const cleanUpOrderListQuery = (query: OrderListQuery = {}) => ({
    ...query,
    search: convertQueryProductStatusLabelToCode(query.search),
    dateFrom: formatDate(query.dateFrom),
    dateTo: formatDate(query.dateTo)
});

const productStatusName = 'stav-polozky';
const productStatusNameShort = 'sp';
const productStatusLabelReplacers = [
    {
        matcher: /sp:Žiadny/i,
        value: `sp:${productStatus.NONE}`
    },
    {
        matcher: /sp:Ziadny/i,
        value: `sp:${productStatus.NONE}`
    },
    {
        matcher: /sp:Skladom/i,
        value: `sp:${productStatus.STOCKED}`
    },
    {
        matcher: /sp:Pripraveny na odoslanie/i,
        value: `sp:${productStatus.READY_TO_SHIP}`
    },
    {
        matcher: /sp:Pripravený na odoslanie/i,
        value: `sp:${productStatus.READY_TO_SHIP}`
    },
    {
        matcher: /sp:Nie je skladom/i,
        value: `sp:${productStatus.NOT_STOCKED}`
    },
    {
        matcher: /sp:Objednaný/i,
        value: `sp:${productStatus.ORDERED}`
    },
    {
        matcher: /sp:Objednany/i,
        value: `sp:${productStatus.ORDERED}`
    },
    {
        matcher: /sp:Vybavený/i,
        value: `sp:${productStatus.DONE}`
    },
    {
        matcher: /sp:Vybaveny/i,
        value: `sp:${productStatus.DONE}`
    },
    {
        matcher: /sp:Vrátený/i,
        value: `sp:${productStatus.RETURNED}`
    },
    {
        matcher: /sp:Vrateny/i,
        value: `sp:${productStatus.RETURNED}`
    }
];
export function convertQueryProductStatusLabelToCode(query: string): string {
    if (!query) {
        return undefined;
    }
    const queryWithShortenProductStatus = shortenProductStatusName(query);

    return replaceProductStatusLabelWithCode(queryWithShortenProductStatus);
}

function shortenProductStatusName(query: string): string {
    return query.replace(`${productStatusName}:`, `${productStatusNameShort}:`);
}

function replaceProductStatusLabelWithCode(query: string): string {
    return productStatusLabelReplacers.reduce(replaceLabel, query);
}

function replaceLabel(query, replacer) {
    return query.replace(replacer.matcher, replacer.value);
}

export function getActiveFiltersCount(query: OrderListQuery) {
    let count = 0;
    if (query.status && query.status.length > 0) {
        count += 1;
    }

    if (query.paymentStatus !== undefined) {
        count += 1;
    }
    if (query.billingType && query.billingType.length > 0) {
        count += 1;
    }
    if (query.shipping && query.shipping.length > 0) {
        count += 1;
    }
    if (query.country && query.country.length > 0) {
        count += 1;
    }
    if (query.callStatus && query.callStatus.length > 0) {
        count += 1;
    }
    if (query.dateFrom || query.dateTo) {
        count += 1;
    }

    if (query.flags?.length > 0) {
        count += 1;
    }

    return count;
}

export function filterOrders(orders: Order[], status: string) {
    return orders.filter(order => order.status === status);
}

export function filterCheckedOrders(orders: Order[], checkedOrders: string[]) {
    return orders.filter(order => checkedOrders.includes(order._id));
}

export function getOrdersIds(orders: Order[]) {
    return orders.map(order => order._id);
}

export function isChangeOrdersStatusToDoneDisabled(
    orders: Order[],
    checkedOrders: string[]
) {
    const filteredOrders = filterCheckedOrders(orders, checkedOrders);
    return !filteredOrders.some(
        order => order.status === orderStatus.READY_TO_SHIP
    );
}

export function updateOrdersStatus(
    orders: Order[],
    ordersStatus: OrderStatus[]
) {
    const ordersStatusMap = ordersStatus.reduce(
        (map, orderStatus) => ({
            ...map,
            [orderStatus._id]: orderStatus.status
        }),
        {}
    );
    return orders.map(order => {
        if (ordersStatusMap[order._id]) {
            return {
                ...order,
                status: ordersStatusMap[order._id]
            };
        }
        return order;
    });
}
