import React from 'react';
import { TableLoader } from 'src/components/TableLoader';
import { TableRow, TableCell, Typography } from '@material-ui/core';
import i18next from 'i18next';
import { Shop } from 'src/models/Shop';
import { ShopRow } from './ShopRow';

const TABLE_COLUMNS_COUNT = 7;

interface Props {
    isLoading: boolean;
    shops: Shop[];
    onEditShopClick: (shop: Shop) => void;
    onRemoveShopClick: (id: string) => void;
}

export const ShopListRows: React.FC<Props> = ({
    isLoading,
    shops,
    onEditShopClick,
    onRemoveShopClick
}) => {
    if (isLoading) {
        return <TableLoader key="loader" colSpan={TABLE_COLUMNS_COUNT} />;
    }

    if (!isLoading && shops.length === 0) {
        return (
            <TableRow data-testid="no-shops" key="no-shops">
                <TableCell colSpan={TABLE_COLUMNS_COUNT}>
                    <Typography
                        variant="caption"
                        gutterBottom
                        align="center"
                        display="block"
                    >
                        {i18next.t('No shops found')}
                    </Typography>
                </TableCell>
            </TableRow>
        );
    }

    const rows = shops.map(shop => (
        <ShopRow
            key={shop._id}
            shop={shop}
            onEditShopClick={onEditShopClick}
            onRemoveShopClick={onRemoveShopClick}
        />
    ));
    return <>{rows}</>;
};
