import { useDispatch } from 'react-redux';
import React from 'react';
import i18next from 'i18next';
import {
    Tooltip,
    IconButton,
    FormControlLabel,
    Radio,
    RadioGroup
} from '@material-ui/core';
import { tooltipEnterDelay } from 'src/config';
import AddIcon from '@material-ui/icons/Add';
import { country } from 'src/common/enums/enums';
import {
    SHARED_DATA_KEY,
    updateSharedData
} from 'src/common/sharedData/SharedDataActions';
import { useCartageGroupSharedData } from './useCartageGroupSharedData';
import { NavLink } from 'react-router-dom';
import { appPaths } from 'src/AppRouterService';

interface Props {
    hideAddGroupBtn?: boolean;
}

export const CartageGroupListAppBarView: React.FC<Props> = ({
    hideAddGroupBtn
}) => {
    const dispatch = useDispatch();
    const { query } = useCartageGroupSharedData();

    const handleCountryChange = evt => {
        dispatch(
            updateSharedData(SHARED_DATA_KEY.cartageGroupList, 'query', {
                country: evt.currentTarget.value
            })
        );
    };

    return (
        <>
            <RadioGroup
                row
                style={{ flexWrap: 'nowrap', padding: '0 20px' }}
                value={query.country}
                onChange={handleCountryChange}
            >
                <FormControlLabel
                    value={country.SK}
                    control={<Radio />}
                    label={i18next.t('SK')}
                />
                <FormControlLabel
                    value={country.HU}
                    control={<Radio />}
                    label={i18next.t('HU')}
                />
                <FormControlLabel
                    value={country.CZ}
                    control={<Radio />}
                    label={i18next.t('CZ')}
                />
                <FormControlLabel
                    value={country.RO}
                    control={<Radio />}
                    label={i18next.t('RO')}
                />
            </RadioGroup>
            {!hideAddGroupBtn && (
                <Tooltip
                    title={i18next.t('cartageGroupList.addNewGroup')}
                    enterDelay={tooltipEnterDelay}
                >
                    <IconButton
                        color="inherit"
                        component={NavLink}
                        to={appPaths.addCartageGroup}
                    >
                        <AddIcon />
                    </IconButton>
                </Tooltip>
            )}
        </>
    );
};
