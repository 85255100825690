import React, { useCallback } from 'react';
import { JDatePicker } from 'src/components/JDatePicker';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { Moment } from 'moment';
import { useDispatch } from 'react-redux';
import {
    updateSharedData,
    SHARED_DATA_KEY
} from 'src/common/sharedData/SharedDataActions';

interface Props {
    name: string;
    label: string;
    value: ParsableDate;
}

export const ProductDatePicker: React.FC<Props> = ({ name, label, value }) => {
    const dispatch = useDispatch();
    const onChange = useCallback(
        (date: Moment) => {
            dispatch(
                updateSharedData(
                    SHARED_DATA_KEY.product,
                    name,
                    date.toISOString()
                )
            );
        },
        [dispatch, name]
    );

    return <JDatePicker label={label} value={value} onChange={onChange} />;
};
