import { Tooltip, IconButton } from '@material-ui/core';
import i18next from 'i18next';
import React, { useEffect } from 'react';
import { tooltipEnterDelay } from 'src/config';
import SaveIcon from '@material-ui/icons/Save';
import { useDispatch } from 'react-redux';
import { Order } from 'src/models/Order';
import { useConfirmationDialog } from 'src/common/confirmationDialog/useConfirmationDialog';
import { saveOrder } from '../OrderActions';
import { isUnpaidGoPayOrder } from './OrderAppBarService';
import { SaveBadge } from '../SaveBadge';

interface Props {
    order: Order;
    isEditing: boolean;
    isSaving: boolean;
    className: string;
}

const S_KEY_CODE = 83;
const isEventFromShopNoteEditor = evt => !!evt.target.closest('#shopNote-root');

export const SaveOrderButton: React.FC<Props> = ({
    isSaving,
    isEditing,
    order,
    className
}) => {
    const dispatch = useDispatch();
    const confirm = useConfirmationDialog();

    const handleSaveOrder = async () => {
        if (isUnpaidGoPayOrder(order)) {
            if (
                !(await confirm(i18next.t('order.saveGoPayOrderConfirmation')))
            ) {
                return;
            }
        }

        dispatch(saveOrder(order));
    };

    const handleSaveShortcut = evt => {
        if ((evt.ctrlKey || evt.metaKey) && evt.keyCode === S_KEY_CODE) {
            evt.preventDefault();
            if (isSaving || isEventFromShopNoteEditor(evt)) {
                return;
            }

            handleSaveOrder();
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleSaveShortcut, false);

        return () => {
            document.removeEventListener('keydown', handleSaveShortcut, false);
        };
    });

    return (
        <Tooltip title={i18next.t('Save order')} enterDelay={tooltipEnterDelay}>
            <div className={className} style={{ marginLeft: 10 }}>
                <IconButton
                    color="inherit"
                    disabled={isSaving}
                    onClick={handleSaveOrder}
                >
                    <SaveBadge isEditing={isEditing}>
                        <SaveIcon />
                    </SaveBadge>
                </IconButton>
            </div>
        </Tooltip>
    );
};
