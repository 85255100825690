import React from 'react';
import { TableLoader } from '../components/TableLoader';
import { resolveEnumValue } from '../utils/enums';
import { EnumsState } from 'src/common/enums/EnumsState';
import { Order } from 'src/models/Order';
import { TableRow, TableCell, Typography } from '@material-ui/core';
import i18next from 'i18next';
import { OrderRow } from './OrderRow';

const TABLE_COLUMNS_COUNT = 7;

interface Props {
    isLoading: boolean;
    orders: Order[];
    enums: EnumsState;
    hasMoreOrders: boolean;
    checkedOrders: string[];
}

export const OrderListRows: React.FC<Props> = ({
    isLoading,
    orders,
    enums,
    hasMoreOrders,
    checkedOrders
}) => {
    if (!isLoading && orders.length === 0) {
        return (
            <TableRow data-testid="no-orders">
                <TableCell colSpan={TABLE_COLUMNS_COUNT}>
                    <Typography
                        variant="caption"
                        gutterBottom
                        align="center"
                        display="block"
                    >
                        {i18next.t('No orders match current criterias')}
                    </Typography>
                </TableCell>
            </TableRow>
        );
    }

    const rows = orders.map(order => (
        <OrderRow
            key={order._id}
            id={order._id}
            checked={checkedOrders.includes(order._id)}
            orderNumber={order.orderNumber}
            deliveryType={order.delivery.type}
            shopName={order.shop.name}
            dateFrom={order.createdDate}
            status={resolveEnumValue(enums.orderStatus.enum, order.status)}
            billingName={order.customer.billing.name}
        />
    ));

    if (isLoading || hasMoreOrders) {
        rows.push(<TableLoader key="loader" colSpan={TABLE_COLUMNS_COUNT} />);
    }

    return <>{rows}</>;
};
