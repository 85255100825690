import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableContainer,
    Paper
} from '@material-ui/core';
import i18next from 'i18next';
import React, { useEffect, useMemo } from 'react';
import { CartageGroup } from 'src/cartageGroups/CartageGroupTypes';
import { useCartageGroupSharedData } from 'src/cartageGroups/useCartageGroupSharedData';
import { API_URLS } from 'src/common/ApiService';
import { useRequest } from 'src/common/useRequestHook';
import { TableLoader } from 'src/components/TableLoader';
import { RequestMethod } from 'src/utils/request';
import { CartagePlanningGroupRow } from './CartagePlanningGroupRow';
import { CartagePlanningOrdersWithoutGroupRow } from './CartagePlanningOrdersWithoutGroupRow';
import { getAllZipCodesFromGroups } from './CartagePlanningService';

export const CartagePlanningView: React.FC = () => {
    const { query } = useCartageGroupSharedData();
    const [
        fetchCartageGroups,
        { response: cartageGroups = [], inProgress }
    ] = useRequest<CartageGroup[]>(
        RequestMethod.GET,
        `${API_URLS.cartageGroups}?country=${query.country}`
    );

    const allGroupsZipCodes = useMemo(
        () => getAllZipCodesFromGroups(cartageGroups),
        [cartageGroups]
    );

    useEffect(() => {
        fetchCartageGroups();
    }, [fetchCartageGroups]);

    return (
        <TableContainer component={Paper}>
            <Table className="table">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            {i18next.t('cartagePlanning.name')}
                        </TableCell>
                        <TableCell />
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {inProgress && <TableLoader key="loader" colSpan={3} />}
                    {!cartageGroups.length && (
                        <TableRow>
                            <TableCell align="center" colSpan={3}>
                                {i18next.t('cartageGroupList.noGroupsDefined')}
                            </TableCell>
                        </TableRow>
                    )}
                    {cartageGroups.length > 0 && (
                        <>
                            <CartagePlanningOrdersWithoutGroupRow
                                country={query.country}
                                zipCodes={allGroupsZipCodes}
                            />
                            {cartageGroups.map(group => (
                                <CartagePlanningGroupRow
                                    key={group._id}
                                    group={group}
                                    country={query.country}
                                />
                            ))}
                        </>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
