import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    List,
    ListItem,
    ListItemText
} from '@material-ui/core';
import i18next from 'i18next';
import React from 'react';
import { ImportError, ImportOrdersResult } from '../../models/ImportResult';

interface Props {
    open: boolean;
    onClose: () => void;
    importResult: ImportOrdersResult;
}

function renderImportErrors(importErrors: ImportError[]) {
    return (
        <List>
            {importErrors.map(importError => (
                <ListItem key={importError.orderNumber}>
                    <ListItemText
                        primary={importError.orderNumber}
                        secondary={importError.error}
                    />
                </ListItem>
            ))}
        </List>
    );
}

export const OrdersImportResultDialogViewBase: React.SFC<Props> = ({
    open,
    onClose,
    importResult
}) => (
    <Dialog open={open}>
        <DialogTitle id="simple-dialog-title">
            {i18next.t('Import result')}
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {i18next.t('Successfully imported orders')}{' '}
                {importResult.imported.length}
                <br />
                {i18next.t('Already imported orders')}{' '}
                {importResult.alreadyImported.length}
                <br />
                <br />
                {i18next.t('Not imported orders')}{' '}
                {importResult.importErrors.length}
            </DialogContentText>
            {renderImportErrors(importResult.importErrors)}
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} color="primary" autoFocus>
                {i18next.t('Close')}
            </Button>
        </DialogActions>
    </Dialog>
);

export const OrdersImportResultDialogView = OrdersImportResultDialogViewBase;
