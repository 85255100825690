import { Grid } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import i18next from 'i18next';
import moment from 'moment';
import React, { useEffect } from 'react';
import { API_URLS } from 'src/common/ApiService';
import { useRequest } from 'src/common/useRequestHook';
import { RequestMethod } from 'src/utils/request';
import { ExpeditionChart } from './ExpeditionChart';
import { ExpeditionStats } from './ExpeditionService';

export const ExpeditionStatsView: React.FC = () => {
    const [fetchStats, { inProgress, response }] = useRequest<
        ExpeditionStats[]
    >(
        RequestMethod.GET,
        API_URLS.expeditionStats + moment().format('YY-MM-DD')
    );

    useEffect(() => {
        fetchStats();
    }, [fetchStats]);

    return (
        <>
            <h1 style={{ marginTop: '10px', marginBottom: '10px' }}>
                {i18next.t('expeditionStats.heading')}
            </h1>
            {inProgress && (
                <Grid container justify="center">
                    <Grid item xs={1}>
                        <CircularProgress size={50} />
                    </Grid>
                </Grid>
            )}
            {response && <ExpeditionChart stats={response} />}
        </>
    );
};
