import { TextField, Button } from '@material-ui/core';
import React, { useState } from 'react';
import { User } from 'src/models/User';
import SaveIcon from '@material-ui/icons/Save';

interface Props {
    user: User;
    onUserUpdate: (user: User) => void;
}

export const UserAddress: React.FC<Props> = ({ user, onUserUpdate }) => {
    const [address, setAddress] = useState(user.address ?? '');

    const handleUserSave = () => {
        onUserUpdate({
            ...user,
            address
        });
    };
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <TextField
                fullWidth
                placeholder="ulica číslo, PSČ obec"
                margin="normal"
                name="address"
                value={address}
                onChange={evt => setAddress(evt.target.value)}
            />
            <Button
                size="small"
                startIcon={<SaveIcon />}
                onClick={handleUserSave}
            >
                Adresu
            </Button>
        </div>
    );
};
