import React, { useCallback } from 'react';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useFetchProductToStore } from './useFetchProductToStore';
import { Tooltip, IconButton } from '@material-ui/core';
import i18next from 'i18next';
import { tooltipEnterDelay } from 'src/config';

interface Props {
    productId: string;
}
export const RefreshProductIconButton: React.FC<Props> = ({ productId }) => {
    const { requestProduct, inProgress } = useFetchProductToStore(productId);

    const handleButtonClick = useCallback(() => {
        requestProduct();
    }, [requestProduct]);
    return (
        <Tooltip
            title={i18next.t('product.refreshProduct')}
            enterDelay={tooltipEnterDelay}
        >
            <IconButton
                color="inherit"
                disabled={inProgress}
                onClick={handleButtonClick}
            >
                <RefreshIcon />
            </IconButton>
        </Tooltip>
    );
};
