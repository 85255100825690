import React from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import { formatUtcDate } from 'src/utils/formatters';
import { NavLink } from 'react-router-dom';
import i18next from 'i18next';
import { Cartage } from 'src/models/Cartage';

interface Props {
    cartage: Cartage;
}

export const CartageItem: React.FC<Props> = ({ cartage }) => (
    <TableRow>
        <TableCell>{cartage.name}</TableCell>
        <TableCell>{formatUtcDate(cartage.createdDate)}</TableCell>
        <TableCell>{formatUtcDate(cartage.shippingDate)}</TableCell>
        <TableCell>{cartage.driver}</TableCell>
        <TableCell>
            <NavLink to={`/cartage/${cartage._id}`}>
                {i18next.t('Display')}
            </NavLink>
        </TableCell>
    </TableRow>
);
