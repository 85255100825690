import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    Paper
} from '@material-ui/core';
import i18next from 'i18next';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import { EnumsState } from '../common/enums/EnumsState';
import { Order } from '../models/Order';
import { OrderListRows } from './OrderListRows';

interface Props {
    isLoading: boolean;
    orders: Order[];
    enums: EnumsState;
    hasMoreOrders: boolean;
    checkedOrders: string[];
    onLoadMoreOrders: () => void;
}
const OrderListView: React.FC<Props> = ({
    isLoading,
    orders,
    enums,
    hasMoreOrders,
    checkedOrders,
    onLoadMoreOrders
}) => (
    <TableContainer component={Paper}>
        <Table className="table">
            <TableHead>
                <TableRow>
                    <TableCell />
                    <TableCell>{i18next.t('Order number')}</TableCell>
                    <TableCell>{i18next.t('Shop')}</TableCell>
                    <TableCell>{i18next.t('Date from')}</TableCell>
                    <TableCell>{i18next.t('Status')}</TableCell>
                    <TableCell>{i18next.t('Client name')}</TableCell>
                    <TableCell />
                </TableRow>
            </TableHead>
            <InfiniteScroll
                element={TableBody}
                initialLoad={false}
                loadMore={onLoadMoreOrders}
                hasMore={hasMoreOrders}
            >
                <OrderListRows
                    isLoading={isLoading}
                    orders={orders}
                    enums={enums}
                    hasMoreOrders={hasMoreOrders}
                    checkedOrders={checkedOrders}
                />
            </InfiniteScroll>
        </Table>
    </TableContainer>
);

export { OrderListView };
