import { TableRow, TableCell, Chip } from '@material-ui/core';
import i18next from 'i18next';
import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { appPaths } from 'src/AppRouterService';
import { API_URLS } from 'src/common/ApiService';
import { useRequest } from 'src/common/useRequestHook';
import { RequestMethod } from 'src/utils/request';

interface Props {
    zipCodes: string[];
    country: string;
}

export const CartagePlanningOrdersWithoutGroupRow: React.FC<Props> = ({
    country,
    zipCodes
}) => {
    const [fetchOrdersCount, { response, inProgress }] = useRequest<{
        ordersCount: number;
    }>(RequestMethod.POST, `${API_URLS.ordersCount}`);

    useEffect(() => {
        fetchOrdersCount({
            nZipCodes: zipCodes,
            country
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [zipCodes, fetchOrdersCount]);

    if (!response?.ordersCount || inProgress) {
        return null;
    }

    return (
        <TableRow>
            <TableCell>
                <strong>
                    {i18next.t('cartagePlanning.notAssignedOrders')}
                </strong>
            </TableCell>
            <TableCell>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Chip label={response.ordersCount} color="secondary" />
                </div>
            </TableCell>
            <TableCell>
                <NavLink
                    to={`${
                        appPaths.cartagePlanningOrderWithoutGroup
                    }?country=${country}&zipCodes=${zipCodes.join(';')}`}
                >
                    {i18next.t('cartagePlanning.open')}
                </NavLink>
            </TableCell>
        </TableRow>
    );
};
