import { Link } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import i18next from 'i18next';
import React from 'react';
import { InvoiceType } from 'src/models/Order';
import { getInvoiceLink } from 'src/order/OrderService';

interface Props {
    type?: InvoiceType;
    invoiceId?: string;
    numberFormatted?: string;
    withoutInvoice?: boolean;
    billNumber?: string;
}

export const Invoice: React.FC<Props> = ({
    type,
    invoiceId,
    numberFormatted,
    withoutInvoice,
    billNumber
}) => {
    if (billNumber) {
        return <Alert severity="success">č.b. {billNumber}</Alert>;
    }

    if (type === InvoiceType.Proforma) {
        return (
            <Alert severity="warning">
                <Link href={getInvoiceLink(invoiceId)} target="_blank">
                    {numberFormatted}
                </Link>
            </Alert>
        );
    }

    if (type === InvoiceType.Regular) {
        return (
            <Alert severity="success">
                <Link href={getInvoiceLink(invoiceId)} target="_blank">
                    {numberFormatted}
                </Link>
            </Alert>
        );
    }

    if (withoutInvoice) {
        return (
            <Alert severity="info">
                {i18next.t('cartageShippingPlanning.withoutInvoice')}
            </Alert>
        );
    }

    return (
        <Alert severity="error">
            {i18next.t('cartageShippingPlanning.noInvoice')}
        </Alert>
    );
};
