import React from 'react';
import { Component, FormEvent } from 'react';
import { connect } from 'react-redux';
import { shopType as shopTypeEnum } from '../../common/enums/enums';
import { showError } from '../../common/errors/ErrorsActions';
import { ImportOrdersResult, ImportResult } from '../../models/ImportResult';
import { Shop } from '../../models/Shop';
import { resetOrders } from '../../orderList/OrderListActions';
import { loadShopSuccess } from '../../shopList/ShopListActions';
import { apiHost } from '../../utils/request';
import { OrdersImportResultDialogView } from './OrdersImportResultDialogView';
import { ShoptetOrdersImportView } from './ShoptetOrdersImportView';
import { WebnodeOrdersImportView } from './WebnodeOrdersImportView';

interface Props {
    shopId: string;
    shopType: string;
    onShowError(error: any): void;
    onResetOrders(): void;
    onLoadShopSuccess(shop: Shop): void;
}

interface State {
    isSubmitting: boolean;
    showOrdersImportResultDialog: boolean;
    ordersImportResult: ImportOrdersResult;
}

class OrdersImportContainerBase extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isSubmitting: false,
            showOrdersImportResultDialog: false,
            ordersImportResult: {
                alreadyImported: [],
                imported: [],
                importErrors: []
            }
        };
    }
    onOrdersImportSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const formData = new FormData(event.currentTarget);

        this.setState({ isSubmitting: true });

        try {
            const response = await fetch(`${apiHost}/order-import`, {
                method: 'POST',
                body: formData,
                credentials: 'include'
            });

            if (response.status !== 200) {
                const error = await response.json();
                return this.props.onShowError(error);
            }
            const importResult = await response.json();
            this.onOrdersImportSuccess(importResult);
        } finally {
            this.setState({ isSubmitting: false });
        }
    };

    onOrdersImportSuccess({ shop, ...result }: ImportResult) {
        this.setState({
            showOrdersImportResultDialog: true,
            ordersImportResult: result
        });
        this.props.onResetOrders();
        this.props.onLoadShopSuccess(shop);
    }

    onOrdersImportResultDialogClose = () => {
        this.setState({
            showOrdersImportResultDialog: false
        });
    };

    renderOrdersImportView() {
        const { shopId, shopType } = this.props;
        if (shopType === shopTypeEnum.WEBNODE) {
            return (
                <WebnodeOrdersImportView
                    shopId={shopId}
                    onSubmit={this.onOrdersImportSubmit}
                />
            );
        }

        if (shopType === shopTypeEnum.SHOPTET) {
            return (
                <ShoptetOrdersImportView
                    shopId={shopId}
                    isSubmitting={this.state.isSubmitting}
                    onSubmit={this.onOrdersImportSubmit}
                />
            );
        }

        return 'None';
    }

    render() {
        const { ordersImportResult, showOrdersImportResultDialog } = this.state;

        return (
            <>
                {this.renderOrdersImportView()}
                <OrdersImportResultDialogView
                    open={showOrdersImportResultDialog}
                    importResult={ordersImportResult}
                    onClose={this.onOrdersImportResultDialogClose}
                />
            </>
        );
    }
}

const mapDispatchToProps = {
    onShowError: showError,
    onResetOrders: resetOrders,
    onLoadShopSuccess: loadShopSuccess
};

const OrdersImportContainer = connect(
    null,
    mapDispatchToProps
)(OrdersImportContainerBase);

export { OrdersImportContainer };
