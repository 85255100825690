import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core';
import i18next from 'i18next';
import React, { useContext } from 'react';
import { ConfirmationModalContext } from './ConfirmationDialogContext';

export const ConfirmationDialog: React.FC = () => {
    const { question, resolveConfirm } = useContext(ConfirmationModalContext);

    const handleCancel = () => {
        resolveConfirm(false);
    };

    const handleConfirm = () => {
        resolveConfirm(true);
    };

    return (
        <Dialog open={!!question} onClose={handleCancel}>
            <DialogTitle>{i18next.t('confirmationModal.title')}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <span dangerouslySetInnerHTML={{ __html: question }} />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="secondary">
                    {i18next.t('confirmationModal.cancel')}
                </Button>
                <Button onClick={handleConfirm} color="primary" autoFocus>
                    {i18next.t('confirmationModal.confirm')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
