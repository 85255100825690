import { SHARED_DATA_KEY } from './SharedDataActions';
import { Product } from 'src/models/Product';

const MANUFACTURER_CODE_LENGTH = 2;

export function getProductCodeWithoutManufacturerPrefix(
    productCode: string
): string {
    return productCode.slice(MANUFACTURER_CODE_LENGTH);
}

export function productUpdateReducer(
    key: SHARED_DATA_KEY,
    product: Product,
    propName: string
): Product {
    if (key !== SHARED_DATA_KEY.product) {
        return product;
    }

    if (propName === 'manufacturer') {
        return updateProductCodeManufacturerPrefix(product);
    }

    return product;
}

function updateProductCodeManufacturerPrefix(product: Product): Product {
    return {
        ...product,
        code:
            product.manufacturer.code +
            getProductCodeWithoutManufacturerPrefix(product.code)
    };
}
