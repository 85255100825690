import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';

const instance = i18n.use(XHR).init({
    fallbackLng: 'sk',

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    debug: true
});

export default instance;
