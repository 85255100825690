import React, { ChangeEvent } from 'react';
import {
    InputAdornment,
    FormControl,
    InputLabel,
    Input
} from '@material-ui/core';
import { CurrencySymbol } from 'src/utils/price';

interface Props {
    name: string;
    value: number;
    label: string;
    currency: CurrencySymbol;
    disabled?: boolean;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const AmountInput: React.FC<Props> = ({
    name,
    value,
    label,
    currency,
    disabled,
    onChange
}) => (
    <FormControl fullWidth>
        <InputLabel htmlFor={name}>{label}</InputLabel>
        <Input
            id={name}
            type="number"
            value={value}
            name={name}
            disabled={disabled}
            startAdornment={
                <InputAdornment position="start">{currency}</InputAdornment>
            }
            onChange={onChange}
        />
    </FormControl>
);
