import React from 'react';
import { TableCell, TableRow, Typography } from '@material-ui/core';
import i18next from 'i18next';
import { TableLoader } from 'src/components/TableLoader';
import { OrderToShip } from './ShippingPlanningService';
import { OrderToShipItem } from './OrderToShipItem';

interface Props {
    isLoading: boolean;
    hasMore: boolean;
    hideDeliveryType?: boolean;
    showExpectedShippingDate?: boolean;
    orders?: OrderToShip[];
}

const tableColumnsCount = 8;

export const OrdersToShipList: React.FC<Props> = ({
    isLoading,
    hasMore,
    hideDeliveryType = false,
    showExpectedShippingDate = false,
    orders = []
}) => {
    if (!isLoading && orders.length === 0) {
        return (
            <TableRow>
                <TableCell colSpan={tableColumnsCount} align="center">
                    <Typography variant="caption" gutterBottom>
                        {i18next.t('shippingPlanning.noOrderMatchesCriteria')}
                    </Typography>
                </TableCell>
            </TableRow>
        );
    }

    const rows = orders.map(order => (
        <OrderToShipItem
            key={order._id}
            order={order}
            showExpectedShippingDate={showExpectedShippingDate}
            hideDeliveryType={hideDeliveryType}
        />
    ));

    if (isLoading || hasMore) {
        rows.push(<TableLoader key="loader" colSpan={tableColumnsCount} />);
    }

    return <>{rows}</>;
};
