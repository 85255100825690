import React, { useEffect } from 'react';
import {
    Checkbox,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    Paper
} from '@material-ui/core';
import i18next from 'i18next';
import { API_URLS } from '../common/ApiService';
import { match } from 'react-router-dom';
import { useRequest } from 'src/common/useRequestHook';
import { RequestMethod } from 'src/utils/request';
import {
    OrderToShip,
    Shipping
} from 'src/shippingPlanning/ShippingPlanningService';
import { OrdersToShipList } from 'src/shippingPlanning/OrdersToShipList';
import { CartageGroup } from 'src/cartageGroups/CartageGroupTypes';
import { useOrderToShipCheckAll } from 'src/shippingPlanning/useOrdersToShipCheckAll';
import { useSharedData } from 'src/common/sharedData/useSharedData';
import { CartagePlanningGroupSharedData } from './CartagePlanningGroupService';
import { SHARED_DATA_KEY } from 'src/common/sharedData/SharedDataActions';
interface Props {
    match: match<{
        cartageGroupId: string;
    }>;
}

export const CartagePlanningGroupView: React.FC<Props> = ({ match }) => {
    const [
        fetchCartageGroup,
        { response: cartageGroup, hasSucceed: cartageGroupFetchingHasSucceed }
    ] = useRequest<CartageGroup>(
        RequestMethod.GET,
        API_URLS.cartageGroups + '/' + match.params.cartageGroupId
    );
    const [
        fetchCartageGroupOrders,
        { response: orders, hasSucceed: cartageGroupOrdersFetchingHasSucceed }
    ] = useRequest<OrderToShip[]>(RequestMethod.POST, API_URLS.ordersToShip);

    const [, setSharedData] = useSharedData<CartagePlanningGroupSharedData>(
        SHARED_DATA_KEY.cartagePlanningGroup
    );

    const {
        isCheckAllChecked,
        isCheckAllIndeterminate,
        handleCheckChange
    } = useOrderToShipCheckAll(orders);

    useEffect(() => {
        fetchCartageGroup();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!cartageGroup) {
            return;
        }
        setSharedData({
            groupName: cartageGroup.name,
            fetchCartageGroupOrders
        });

        fetchCartageGroupOrders({
            shipping: Shipping.CARTAGE,
            country: cartageGroup?.country,
            zipCodes: cartageGroup?.zipCodes
        });
    }, [cartageGroup, fetchCartageGroupOrders, setSharedData]);

    return (
        <TableContainer component={Paper}>
            <Table className="table">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Checkbox
                                checked={isCheckAllChecked}
                                indeterminate={isCheckAllIndeterminate}
                                onChange={handleCheckChange}
                            />
                        </TableCell>
                        <TableCell>
                            {i18next.t('shippingPlanning.orderNumber')}
                        </TableCell>
                        <TableCell>
                            {i18next.t('shippingPlanning.expectedShippingDate')}
                        </TableCell>
                        <TableCell>
                            {i18next.t('shippingPlanning.address')}
                        </TableCell>
                        <TableCell>
                            {i18next.t('shippingPlanning.items')}
                        </TableCell>
                        <TableCell>
                            {i18next.t('shippingPlanning.note')}
                        </TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <OrdersToShipList
                    isLoading={
                        !(
                            cartageGroupFetchingHasSucceed &&
                            cartageGroupOrdersFetchingHasSucceed
                        )
                    }
                    orders={orders}
                    hasMore={false}
                    hideDeliveryType
                    showExpectedShippingDate
                />
            </Table>
        </TableContainer>
    );
};
