import { Tooltip, IconButton } from '@material-ui/core';
import i18next from 'i18next';
import React from 'react';
import { tooltipEnterDelay } from 'src/config';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import { API_URLS } from 'src/common/ApiService';
import { useRequest } from 'src/common/useRequestHook';
import { RequestMethod } from 'src/utils/request';
import { Order } from 'src/models/Order';
import { useDispatch } from 'react-redux';
import { loadOrderSuccess } from '../OrderActions';
import { showNotification } from 'src/common/notifications/NotificationsActions';
import { useConfirmationDialog } from 'src/common/confirmationDialog/useConfirmationDialog';
import { NotificationVariant } from 'src/models/Notification';
import { appPaths } from 'src/AppRouterService';
import { generatePath } from 'react-router-dom';

interface Props {
    disabled: boolean;
    isShown: boolean;
    className: string;
    orderId?: string;
}

export const CloneOrderButton: React.FC<Props> = ({
    disabled,
    isShown,
    className,
    orderId
}) => {
    const dispatch = useDispatch();
    const confirm = useConfirmationDialog();
    const [cloneOrder, { inProgress }] = useRequest<Order>(
        RequestMethod.POST,
        API_URLS.cloneOrder
    );

    if (!isShown) {
        return null;
    }

    const handleCloneOrder = async () => {
        if (!(await confirm(i18next.t('order.cloneOrderConfirmation')))) {
            return;
        }
        const updatedOrder = await cloneOrder({ orderId });

        const lastOrderClone =
            updatedOrder.clones[updatedOrder.clones.length - 1];
        dispatch(loadOrderSuccess(updatedOrder));
        dispatch(
            showNotification({
                id: `clone_order_${Date.now()}`,
                variant: NotificationVariant.Success,
                text: i18next.t('order.cloneOrderSuccess'),
                link: generatePath(appPaths.order, {
                    orderId: lastOrderClone
                })
            })
        );
    };

    return (
        <Tooltip
            title={i18next.t('order.cloneOrder')}
            enterDelay={tooltipEnterDelay}
        >
            <div className={className}>
                <IconButton
                    color="inherit"
                    onClick={handleCloneOrder}
                    disabled={disabled || inProgress}
                >
                    <CallSplitIcon />
                </IconButton>
            </div>
        </Tooltip>
    );
};
