import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    TextField
} from '@material-ui/core';
import { StyleRules, withStyles, WithStyles } from '@material-ui/core/styles';
import i18next from 'i18next';
import React from 'react';
import { User } from '../models/User';

const decorate = withStyles(theme => {
    const style: StyleRules = {
        container: {
            display: 'flex',
            flexWrap: 'wrap'
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: 200
        }
    };
    return style;
});

interface Props extends WithStyles {
    open: boolean;
    users: User[];
    username: string | undefined;
    password: string | undefined;
    onUsernameChange: (value: string) => void;
    onPasswordChange: (value: string) => void;
    onCancel: () => void;
    onSubmit: () => void;
}

const ChangePasswordDialog = decorate(
    ({
        open,
        onCancel,
        onSubmit,
        onUsernameChange,
        onPasswordChange,
        users,
        classes,
        username,
        password
    }: Props) => {
        return (
            <Dialog open={open} onClose={onCancel}>
                <DialogTitle>{i18next.t('Password change')}</DialogTitle>
                <DialogContent>
                    <form className={classes.container} autoComplete="off">
                        <TextField
                            name="username"
                            className={classes.textField}
                            label={i18next.t('Login name')}
                            type="text"
                            margin="normal"
                            onChange={evt => {
                                onUsernameChange(evt.target.value);
                            }}
                            value={username}
                            autoFocus
                            select
                        >
                            {users.map(({ username }) => (
                                <MenuItem key={username} value={username}>
                                    {username}
                                </MenuItem>
                            ))}
                        </TextField>

                        <TextField
                            name="password"
                            className={classes.textField}
                            label={i18next.t('Password')}
                            type="text"
                            margin="normal"
                            onChange={evt => {
                                onPasswordChange(evt.target.value);
                            }}
                            value={password}
                        />
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={onCancel}
                        color="secondary"
                        variant="contained"
                    >
                        {i18next.t('Cancel')}
                    </Button>
                    <Button
                        onClick={onSubmit}
                        color="primary"
                        variant="contained"
                    >
                        {i18next.t('Save')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
);

export { ChangePasswordDialog };
