import * as actions from './SharedDataActions';
import { setValue } from 'src/utils/object';
import { handleActions } from 'redux-actions';
import { SharedDataState } from './SharedDataState';
import { removeItem, setSharedDataValue } from './SharedDataService';

const defaultState: SharedDataState = {
    [actions.SHARED_DATA_KEY.shippingPlanning]: {
        query: { search: '', shipping: 'COURIER' },
        selectedOrdersIds: []
    },
    [actions.SHARED_DATA_KEY.cartageGroupList]: {
        query: { country: 'SK' }
    },
    [actions.SHARED_DATA_KEY.cartage]: {}
};

export const SharedDataReducer = handleActions<SharedDataState, any>(
    {
        [actions.updateSharedData.toString()]: (
            state: SharedDataState,
            action: any
        ) => ({
            ...state,
            [action.payload.key]: setSharedDataValue(
                action.payload.key,
                state[action.payload.key],
                action.payload.propName,
                action.payload.propValue
            )
        }),
        [actions.addSharedData.toString()]: (
            state: SharedDataState,
            action: any
        ) => ({
            ...state,
            [action.payload.key]: action.payload.data
        }),
        [actions.removeSharedDataItem.toString()]: (
            state: SharedDataState,
            action: any
        ) => ({
            ...state,
            [action.payload.key]: removeItem(
                state[action.payload.key],
                action.payload.propName,
                action.payload.item
            )
        }),
        [actions.addSharedDataItem.toString()]: (
            state: SharedDataState,
            action: any
        ) => ({
            ...state,
            [action.payload.key]: setValue(
                state[action.payload.key],
                action.payload.propName,
                [
                    ...state[action.payload.key][action.payload.propName],
                    action.payload.item
                ]
            )
        })
    },
    defaultState
);
