import React from 'react';
import { ProductItem } from 'src/models/Order';
import { CartageProductToShip } from './CartageProductToShip';
import { CircularProgress, Typography } from '@material-ui/core';
import i18next from 'i18next';
import { formatWeight } from 'src/utils/formatters';
import { getOrderTotalWeight } from 'src/shippingPlanning/ShippingPlanningService';

interface Props {
    inProgress: boolean;
    oversizedProductCodes: string[] | string;
    items: ProductItem[];
    showOrderItemStatusIcon: boolean;
}

export const CartageProductsToShip: React.FC<Props> = ({
    inProgress,
    oversizedProductCodes,
    items,
    showOrderItemStatusIcon
}) => {
    if (inProgress) {
        return <CircularProgress />;
    }

    return (
        <>
            {items.map(item => {
                if (!item.subItems?.length) {
                    return (
                        <CartageProductToShip
                            key={item._id}
                            item={item}
                            showOrderItemStatusIcon={showOrderItemStatusIcon}
                            showItemWeight={items.length > 1}
                            isOversized={oversizedProductCodes.includes(
                                item.productCode
                            )}
                        />
                    );
                }

                return (
                    <>
                        {item.subItems.map(subItem => (
                            <CartageProductToShip
                                key={subItem._id}
                                item={subItem}
                                showOrderItemStatusIcon={
                                    showOrderItemStatusIcon
                                }
                                showItemWeight={items.length > 1}
                                isOversized={oversizedProductCodes.includes(
                                    subItem.productCode
                                )}
                            />
                        ))}
                    </>
                );
            })}
            <Typography variant="body2">
                {i18next.t('Total weight')}{' '}
                {formatWeight(getOrderTotalWeight(items))}
            </Typography>
        </>
    );
};
