import { useEffect } from 'react';
import { Product } from 'src/models/Product';
import { useDispatch } from 'react-redux';
import {
    addSharedData,
    SHARED_DATA_KEY
} from 'src/common/sharedData/SharedDataActions';
import { API_URLS } from 'src/common/ApiService';
import { useRequest } from 'src/common/useRequestHook';
import { RequestMethod } from 'src/utils/request';

export function useFetchProductToStore(productId: string) {
    const dispatch = useDispatch();
    const [
        requestProduct,
        { response: fetchedProduct, inProgress }
    ] = useRequest<Product>(
        RequestMethod.GET,
        `${API_URLS.products}/${productId}`
    );

    useEffect(() => {
        dispatch(addSharedData(SHARED_DATA_KEY.product, fetchedProduct));
    }, [dispatch, fetchedProduct]);

    return {
        requestProduct,
        inProgress
    };
}
