import { Action, handleActions } from 'redux-actions';
import { userRole } from '../common/enums/enums';
import { User } from '../models/User';
import * as actions from './UserListActions';
import { UserListState } from './UserListState';

const DEFAULT_USER_ROLE = userRole.USER;

const DEFAULT_NEW_USER: User = {
    username: '',
    password: '',
    role: DEFAULT_USER_ROLE
};

const defaultState: UserListState = {
    userList: [],
    isLoading: false,
    showNewUserDialog: false,
    newUser: { ...DEFAULT_NEW_USER }
};

interface ShowNewUserDialogPayload {
    show: boolean;
}

interface ReceiveUsersPayload {
    users: User[];
}

interface UpdateNewUserPayload {
    propName: string;
    propValue: string;
}

const UserListReducer = handleActions<UserListState, any>(
    {
        [actions.loadingUsers.toString()]: state => ({
            ...state,
            isLoading: true
        }),

        [actions.loadUsersSuccess.toString()]: (
            state,
            action: Action<ReceiveUsersPayload>
        ) => ({
            ...state,
            userList: action.payload ? action.payload.users : [],
            isLoading: false
        }),

        [actions.showNewUserDialog.toString()]: (
            state,
            action: Action<ShowNewUserDialogPayload>
        ) => ({
            ...state,
            showNewUserDialog: action.payload.show
        }),

        [actions.updateNewUser.toString()]: (
            state,
            action: Action<UpdateNewUserPayload>
        ) => ({
            ...state,
            newUser: {
                ...state.newUser,
                [action.payload.propName]: action.payload.propValue
            }
        }),

        [actions.resetNewUser.toString()]: state => ({
            ...state,
            newUser: { ...DEFAULT_NEW_USER }
        })
    },
    defaultState
);

export { UserListReducer };
