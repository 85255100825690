import React, { ChangeEvent, useCallback } from 'react';
import { Manufacturer } from 'src/models/Product';
import {
    FormControl,
    InputLabel,
    Input,
    InputAdornment
} from '@material-ui/core';
import i18next from 'i18next';
import { useDispatch } from 'react-redux';
import {
    updateSharedData,
    SHARED_DATA_KEY
} from 'src/common/sharedData/SharedDataActions';
import { getProductCodeWithoutManufacturerPrefix } from 'src/common/sharedData/SharedProductService';

interface Props {
    name: string;
    code: string;
    manufacturer: Manufacturer;
}

export const ProductCodeInput: React.FC<Props> = ({
    name,
    code,
    manufacturer
}) => {
    const dispatch = useDispatch();
    const onChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            dispatch(
                updateSharedData(
                    SHARED_DATA_KEY.product,
                    event.currentTarget.name,
                    manufacturer.code + event.currentTarget.value
                )
            );
        },
        [dispatch, manufacturer]
    );

    return (
        <FormControl fullWidth>
            <InputLabel htmlFor={name}>{i18next.t('product.code')}</InputLabel>
            <Input
                id={name}
                type="text"
                value={getProductCodeWithoutManufacturerPrefix(code)}
                name={name}
                startAdornment={
                    <InputAdornment position="start">
                        {manufacturer.code}
                    </InputAdornment>
                }
                onChange={onChange}
            />
        </FormControl>
    );
};
