import { createAction } from 'redux-actions';

export const showError = createAction('SHOW_ERROR', (error: any) => ({
    error
}));
export const hideError = createAction('HIDE_ERROR');

export interface ShowErrorPayload {
    error: any;
}
