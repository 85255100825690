import React from 'react';
import MUIRichTextEditor from 'mui-rte';
import { formatValue, isObject, isRichNote } from './HistoryService';
import { useEnums } from 'src/common/enums/useEnumsHook';
import { HistoryChangeObject } from './HistoryChangeObject';
import { getShopNoteRawState } from 'src/order/OrderService';

interface Props {
    change: any;
    selector: string;
    translationScope: string;
}

export const HistoryChangeValue: React.FC<Props> = ({
    change,
    selector,
    translationScope
}) => {
    const enums = useEnums();

    if (change.item) {
        return (
            <HistoryChangeObject
                item={change.item[selector]}
                path={change.path}
                translationScope={translationScope}
            />
        );
    }

    const value = change[selector];

    if (isRichNote(value)) {
        return (
            <MUIRichTextEditor
                defaultValue={getShopNoteRawState(value)}
                readOnly
                toolbar={false}
            />
        );
    }

    if (isObject(value)) {
        return (
            <HistoryChangeObject
                item={value}
                path={change.path}
                translationScope={translationScope}
            />
        );
    }

    return formatValue(value, change.path, enums);
};
