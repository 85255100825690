import { isArray } from 'util';

export function setValue<T>(object: T, propName: string, propValue: any): T {
    const path = propName.split('.');
    return set(object, path, propValue) as T;
}

function set(object: any, path: string[], value: any): any {
    if (path.length === 1) {
        return {
            ...object,
            [path[0]]: value
        };
    }
    const prop = path.shift();
    if (isArray(object)) {
        return [
            ...(object as any[]).slice(0, +prop),
            set(object[prop], path, value),
            ...(object as any[]).slice(+prop + 1)
        ];
    }
    return {
        ...object,
        [prop]: set(object[prop], path, value)
    };
}
