import { billingType, country, orderStatus } from 'src/common/enums/enums';
import { InvoiceType, Order } from 'src/models/Order';
import { isOrderCanceled } from '../OrderService';

const isNotSKOrderWithRegularInvoice = (order: Order): boolean => {
    return (
        isOrderWithRegularInvoice(order) && order.shop.country !== country.SK
    );
};

export const isExportInvoiceDisabled = (
    order: Order,
    isExportingInvoice: boolean
): boolean => {
    return (
        isExportingInvoice ||
        isOrderCanceled(order) ||
        isNotSKOrderWithRegularInvoice(order)
    );
};

export const isDeleteInvoiceDisabled = (
    isExportingInvoice: boolean,
    isDeletingInvoice: boolean,
    order: Order
): boolean => {
    return (
        isExportingInvoice ||
        isDeletingInvoice ||
        !order.invoice?.id ||
        isNotSKOrderWithRegularInvoice(order)
    );
};

export function isOrderWithRegularInvoice({ invoice }: Order): boolean {
    return invoice?.type === InvoiceType.Regular;
}

export function isUnpaidGoPayOrder(order: Order): boolean {
    return !order.isPaid && order.billing.type === billingType.GOPAY;
}

const ORDER_STATUSES_WITHOUT_CLONE_OPTION = [
    orderStatus.DONE,
    orderStatus.CANCELED,
    orderStatus.READY_TO_SHIP
];
export function isCloneOrderShown(order: Order): boolean {
    if (order.isClaim || order.isClone) {
        return false;
    }

    return !ORDER_STATUSES_WITHOUT_CLONE_OPTION.includes(order.status);
}
