import { Alert } from '@material-ui/lab';
import moment from 'moment';
import React from 'react';
import { formatUtcDate } from 'src/utils/formatters';

interface Props {
    date?: string;
}

const WARNING_PERIOD = 5;
const ALERT_PERIOD = 2;

export const ExpectedShippingDate: React.FC<Props> = ({ date }) => {
    if (!date) {
        return <>-</>;
    }

    const formattedDate = formatUtcDate(date);
    const now = moment();
    const businessDiff = now.businessDiff(moment(date));
    if (businessDiff <= ALERT_PERIOD) {
        return <Alert severity="error">{formattedDate}</Alert>;
    }

    if (businessDiff <= WARNING_PERIOD) {
        return <Alert severity="warning">{formattedDate}</Alert>;
    }

    return <>{formattedDate}</>;
};
