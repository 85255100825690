import { useSelector } from 'react-redux';
import { SHARED_DATA_KEY } from 'src/common/sharedData/SharedDataActions';
import { selectSharedData } from 'src/common/sharedData/SharedDataSelectors';
import { AppState } from 'src/models/AppState';
import { CartageGroupListSharedData } from './CartageGroupTypes';

export const useCartageGroupSharedData = (): CartageGroupListSharedData =>
    useSelector((state: AppState) =>
        selectSharedData<CartageGroupListSharedData>(
            state,
            SHARED_DATA_KEY.cartageGroupList
        )
    );
