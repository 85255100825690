import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField
} from '@material-ui/core';
import { StyleRules, withStyles, WithStyles } from '@material-ui/core/styles';
import i18next from 'i18next';
import React from 'react';
import { Enum } from '../models/Enum';
import { User } from '../models/User';
import { renderEnumOptions } from '../utils/enums';

const decorate = withStyles(theme => {
    const style: StyleRules = {
        container: {
            display: 'flex',
            flexWrap: 'wrap'
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: 200
        }
    };
    return style;
});

interface Props extends WithStyles {
    open: boolean;
    newUser: User;
    userRoleEnum: Enum;
    onCancel: () => void;
    onSubmit: () => void;
    onNewUserChange: (name: string, value: string) => void;
}

const NewUserDialog = decorate(
    ({
        open,
        newUser,
        userRoleEnum,
        onCancel,
        onSubmit,
        onNewUserChange,
        classes
    }: Props) => {
        const onChange = event => {
            onNewUserChange(event.target.name, event.target.value);
        };

        return (
            <Dialog open={open} onClose={onCancel}>
                <DialogTitle>{i18next.t('Add new user')}</DialogTitle>
                <DialogContent>
                    <form className={classes.container} autoComplete="off">
                        <TextField
                            name="username"
                            className={classes.textField}
                            label={i18next.t('Login name')}
                            type="text"
                            margin="normal"
                            onChange={onChange}
                            value={newUser.username}
                            autoFocus
                        />
                        <TextField
                            name="password"
                            className={classes.textField}
                            label={i18next.t('Password')}
                            type="text"
                            margin="normal"
                            onChange={onChange}
                            value={newUser.password}
                        />
                        <TextField
                            name="role"
                            className={classes.textField}
                            label={i18next.t('Role')}
                            select
                            margin="normal"
                            onChange={onChange}
                            value={newUser.role}
                        >
                            {renderEnumOptions(userRoleEnum)}
                        </TextField>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={onCancel}
                        color="secondary"
                        variant="contained"
                    >
                        {i18next.t('Cancel')}
                    </Button>
                    <Button
                        onClick={onSubmit}
                        color="primary"
                        variant="contained"
                    >
                        {i18next.t('Add')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
);

export { NewUserDialog };
