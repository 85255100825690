import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid
} from '@material-ui/core';
import { StyleRules, withStyles, WithStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import i18next from 'i18next';
import React from 'react';
import { Availability } from '../models/Availability';

const decorate = withStyles(theme => {
    const style: StyleRules = {
        container: {
            display: 'flex',
            flexWrap: 'wrap'
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1)
        }
    };
    return style;
});

interface Props extends WithStyles {
    open: boolean;
    availability: Availability;
    onCancel: () => void;
    onSubmit: () => void;
    onAvailabilityChange: (name: string, value: string) => void;
}

const AvailabilityDialog = decorate(
    ({
        open,
        availability,
        onCancel,
        onSubmit,
        onAvailabilityChange,
        classes
    }: Props) => {
        const onChange = event => {
            onAvailabilityChange(event.target.name, event.target.value);
        };

        return (
            <Dialog open={open} onClose={onCancel}>
                <DialogTitle>
                    {availability._id
                        ? i18next.t('Edit availability')
                        : i18next.t('Add new availability')}
                </DialogTitle>
                <DialogContent>
                    <form className={classes.container} autoComplete="off">
                        <TextField
                            name="name"
                            className={classes.textField}
                            label={i18next.t('Name')}
                            type="text"
                            margin="normal"
                            onChange={onChange}
                            value={availability.name}
                            fullWidth
                            autoFocus
                        />

                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <TextField
                                    name="inStockTime"
                                    type="number"
                                    className={classes.textField}
                                    label={i18next.t('Storage time')}
                                    margin="normal"
                                    onChange={onChange}
                                    value={availability.inStockTime}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    name="outOfStockTime"
                                    type="number"
                                    className={classes.textField}
                                    label={i18next.t('Delivery time')}
                                    margin="normal"
                                    onChange={onChange}
                                    value={availability.outOfStockTime}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={onCancel}
                        color="secondary"
                        variant="contained"
                    >
                        {i18next.t('Cancel')}
                    </Button>
                    <Button
                        onClick={onSubmit}
                        color="primary"
                        variant="contained"
                    >
                        {i18next.t('Save')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
);

export { AvailabilityDialog };
