import { Button } from '@material-ui/core';
import i18next from 'i18next';
import { Moment } from 'moment';
import moment from 'moment';
import React from 'react';
import { Component, FormEvent } from 'react';
import { shopType } from '../../common/enums/enums';
import { JDatePicker } from '../JDatePicker';

const JARVIS_BORN_DATE = '2018-10-12';

interface Props {
    isSubmitting: boolean;
    shopId: string;
    onSubmit: (event: FormEvent<HTMLFormElement>) => void;
}

interface State {
    dateFrom: Moment;
    disabled: boolean;
}

export class ShoptetOrdersImportView extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            dateFrom: moment(),
            disabled: false
        };
    }

    onDateFromChange = (dateFrom: Moment) => {
        this.setState({
            dateFrom,
            disabled: false
        });
    };

    onError = error => {
        if (!error) {
            return;
        }

        this.setState({ disabled: true });
    };

    render() {
        const { onSubmit, shopId } = this.props;
        const { dateFrom, disabled } = this.state;
        return (
            <div>
                <form onSubmit={onSubmit}>
                    <input type="hidden" name="shopId" value={shopId} />
                    <input
                        type="hidden"
                        name="shopType"
                        value={shopType.SHOPTET}
                    />
                    <input
                        type="hidden"
                        name="dateFrom"
                        value={dateFrom.format('YYYY-MM-DD')}
                    />
                    <JDatePicker
                        minDate={JARVIS_BORN_DATE}
                        disableFuture
                        label={i18next.t('Orders from')}
                        value={dateFrom}
                        onChange={this.onDateFromChange}
                        onError={this.onError}
                    />
                    <Button
                        type="submit"
                        disabled={disabled || this.props.isSubmitting}
                    >
                        {i18next.t('Import orders')}
                    </Button>
                </form>
            </div>
        );
    }
}
