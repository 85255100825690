import React, { useCallback } from 'react';
import { Manufacturer } from 'src/models/Product';
import manufacturers from 'src/common/manufacturers';
import { Select, MenuItem } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import {
    updateSharedData,
    SHARED_DATA_KEY
} from 'src/common/sharedData/SharedDataActions';

interface Props {
    name: string;
    value: Manufacturer;
}

export const ManufacturerSelect: React.FC<Props> = ({ name, value }) => {
    const dispatch = useDispatch();

    const onChange = useCallback(
        (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
            dispatch(
                updateSharedData(
                    SHARED_DATA_KEY.product,
                    name,
                    manufacturers.find(
                        ({ code }) => code === event.target.value
                    )
                )
            );
        },
        [dispatch, name]
    );

    return (
        <Select
            id={name}
            name={name}
            value={value.code}
            onChange={onChange}
            fullWidth
        >
            {manufacturers.map(({ code, name }) => (
                <MenuItem key={code} value={code}>
                    {name}
                </MenuItem>
            ))}
        </Select>
    );
};
