import React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { orderStatus } from '../../common/enums/enums';
import { AppState } from '../../models/AppState';
import { Order } from '../../models/Order';
import {
    deleteInvoice,
    exportInvoice,
    loadOrder,
    saveOrder
} from '../OrderActions';
import { OrderAppBarView } from './OrderAppBarView';

interface Props {
    order: Order;
    isExportingInvoice: boolean;
    isDeletingInvoice: boolean;
    isLoading: boolean;
    isEditing: boolean;
    isSaving: boolean;
    onSaveOrder: (order: Order) => void;
    onExportInvoice: (orderId: string, createRegular?: boolean) => void;
    onDeleteInvoice: (orderId: string) => void;
    onLoadOrder: (orderId: string) => void;
}

class OrderAppBarContainerBase extends Component<Props> {
    render() {
        const {
            isLoading,
            isSaving,
            isEditing,
            isExportingInvoice,
            isDeletingInvoice,
            order
        } = this.props;
        return (
            <OrderAppBarView
                order={order}
                isExportingInvoice={isExportingInvoice}
                isDeletingInvoice={isDeletingInvoice}
                isLoading={isLoading}
                isSaving={isSaving}
                isEditing={isEditing}
                onRestoreClick={this.restoreOrder}
                onExportInvoiceClick={this.exportInvoice}
                onDeleteInvoiceClick={this.deleteInvoice}
                onCreateRegularInvoiceClick={this.createRegularInvoice}
                onReadyToShipClick={this.setReadyToShipOrder}
                onRefreshOrderClick={this.refreshOrder}
            />
        );
    }

    setReadyToShipOrder = () => {
        const { order } = this.props;
        this.props.onSaveOrder({
            ...order,
            status: orderStatus.READY_TO_SHIP
        });
    };

    restoreOrder = () => {
        const { order } = this.props;
        this.props.onSaveOrder({
            ...order,
            status: null
        });
    };

    exportInvoice = () => {
        const { order } = this.props;
        this.props.onExportInvoice(order._id);
    };

    deleteInvoice = () => {
        const { order } = this.props;
        this.props.onDeleteInvoice(order._id);
    };

    createRegularInvoice = () => {
        const { order } = this.props;
        this.props.onExportInvoice(order._id, true);
    };

    refreshOrder = () => {
        const { order } = this.props;
        this.props.onLoadOrder(order._id);
    };
}

const mapStateToProps = (state: AppState) => ({
    isExportingInvoice: state.order.isExportingInvoice,
    isDeletingInvoice: state.order.isDeletingInvoice,
    isLoading: state.order.isLoading,
    isSaving: state.order.isSaving,
    isEditing: state.order.isEditing,
    order: state.order.data
});

const mapDispatchToProps = {
    onSaveOrder: saveOrder,
    onExportInvoice: exportInvoice,
    onDeleteInvoice: deleteInvoice,
    onLoadOrder: loadOrder
};

const OrderAppBarContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderAppBarContainerBase);

export { OrderAppBarContainer };
