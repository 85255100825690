import { Snackbar } from '@material-ui/core';
import React from 'react';
import { Notification } from '../../models/Notification';
import { NotificationView } from './NotificationView';

interface Props {
    notifications: Notification[];
    onHideNotification: (id: string) => void;
}

interface State {
    notifications: Notification[];
}

export class NotificationsView extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            notifications: []
        };
    }

    componentDidUpdate() {
        const { notifications } = this.props;
        // handle rendered notification in state due to animation on hiding
        // has to have rendered last one to keep nice animation
        if (
            notifications.length !== 0 &&
            notifications !== this.state.notifications
        ) {
            this.setState({
                notifications
            });
        }
    }

    render() {
        const { notifications, onHideNotification } = this.props;
        const notification = this.state.notifications[0];
        return (
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                open={notifications.length > 0}
                className="no-print"
            >
                {notification && (
                    <NotificationView
                        notification={notification}
                        onClose={() => onHideNotification(notification.id)}
                    />
                )}
            </Snackbar>
        );
    }
}
