declare global {
    interface Window {
        env: {
            ENV: string;
        };
    }
}

const TEST_ENV = 'testing';
const PRODUCTION_ENV = 'production';

export function isTestingEnv(): boolean {
    return window.env.ENV === TEST_ENV;
}

export function isProductionEnv(): boolean {
    return window.env.ENV === PRODUCTION_ENV;
}
