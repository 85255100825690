import { combineReducers } from 'redux';
import { EnumsReducer } from './common/enums/EnumsReducer';
import { ErrorsReducer } from './common/errors/ErrorsReducer';
import { NotificationsReducer } from './common/notifications/NotificationsReducer';
import { SessionReducer } from './common/session/SessionReducer';
import { LayoutReducer } from './components/Layout/LayoutReducer';
import { LoginReducer } from './login/LoginReducer';
import { OrderReducer } from './order/OrderReducer';
import { OrderListReducer } from './orderList/OrderListReducer';
import { ShopListReducer } from './shopList/ShopListReducer';
import { UserListReducer } from './userList/UserListReducer';
import { ProductListReducer } from './productList/ProductListReducer';
import { AppState } from './models/AppState';
import { SharedDataReducer } from './common/sharedData/SharedDataReducer';

const rootReducer = combineReducers<AppState>({
    order: OrderReducer,
    orderList: OrderListReducer,
    enums: EnumsReducer,
    errors: ErrorsReducer,
    userList: UserListReducer,
    login: LoginReducer,
    session: SessionReducer,
    layout: LayoutReducer,
    shopList: ShopListReducer,
    notifications: NotificationsReducer,
    productList: ProductListReducer,
    sharedData: SharedDataReducer
});

export default rootReducer;
