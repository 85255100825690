import { createAction } from 'redux-actions';

export const resetUser = createAction('RESET_USER');
export const updateUser = createAction(
    'UPDATE_USER',
    (propName: string, propValue: string) => ({
        propName,
        propValue
    })
);
