import React, { ChangeEvent, useCallback } from 'react';
import { ProductPart } from 'src/models/Product';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    Grid,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    ExpansionPanel,
    Tooltip,
    IconButton
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import i18next from 'i18next';
import {
    getProductVolumeAndWeight,
    roundToTwoDecimals
} from './ProductService';
import { tooltipEnterDelay } from 'src/config';
import { useDispatch } from 'react-redux';
import {
    addSharedDataItem,
    SHARED_DATA_KEY
} from 'src/common/sharedData/SharedDataActions';
import { ProductPartItem } from './ProductPartItem';

interface Props {
    parts: ProductPart[];
    onChange(event: ChangeEvent<HTMLInputElement>): void;
}

export const ProductPartList: React.FC<Props> = ({ parts, onChange }) => {
    const dispatch = useDispatch();
    const { volume, weight } = getProductVolumeAndWeight(parts);

    const onAddProductPart = useCallback(() => {
        dispatch(addSharedDataItem(SHARED_DATA_KEY.product, 'parts', {}));
    }, [dispatch]);

    return (
        <ExpansionPanel defaultExpanded>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Grid container justify="space-between">
                    <Grid item xs={2}>
                        {i18next.t('product.parts')}
                    </Grid>
                    <Grid
                        item
                        xs={10}
                        container
                        spacing={3}
                        justify="flex-end"
                        alignItems="center"
                    >
                        {`${i18next.t(
                            'product.totalWeight'
                        )}: ${roundToTwoDecimals(weight)} kg, ${i18next.t(
                            'product.totalVolume'
                        )}: ${roundToTwoDecimals(volume)} cm3`}
                    </Grid>
                </Grid>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Grid container>
                    {parts.map((part, index) => (
                        <ProductPartItem
                            key={part._id || index}
                            index={index}
                            part={part}
                            onChange={onChange}
                        />
                    ))}
                    <Grid container item justify="flex-end" xs={12}>
                        <Tooltip
                            title={i18next.t('product.addPartBtn')}
                            enterDelay={tooltipEnterDelay}
                        >
                            <div>
                                <IconButton
                                    color="default"
                                    onClick={onAddProductPart}
                                >
                                    <AddIcon />
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Grid>
                </Grid>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
};
