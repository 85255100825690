import { Model, HistoryChanges } from 'src/common/Model';
import { CourierCompany } from 'src/scanner/printCourierLabels/PrintCourierLabelsService';

interface BaseProduct {
    _id?: string;
    name: string;
    supplier: string;
    variant?: string;
    productCode: string;
    weight: number;
    volume?: number;
    boxCount?: number;
}

export interface Product extends BaseProduct {
    unitPrice?: number;
    unitPriceWithTax?: number;
    flags?: string[];
}
export interface ProductItem extends Product {
    quantity: number;
    status: string;
    subItems?: BaseProductItem[];
}

export interface BaseProductItem extends BaseProduct {
    quantity: number;
    status: string;
}

export interface Item {
    type?: string;
    unitPrice?: number;
    unitPriceWithTax?: number;
}

export interface Customer {
    name?: string;
    city?: string;
    street?: string;
    zip?: string;
    email?: string;
    phone?: string;
    country?: string;
    state?: string;
    company?: string;
    companyId?: string;
    vatId?: string;
}

export interface Invoice {
    id: string;
    token: string;
    clientId: string;
    type: InvoiceType;
    numberFormatted: string;
}

export enum InvoiceType {
    Regular = 'regular',
    Proforma = 'proforma'
}

export interface Shop {
    id: string;
    name: string;
    country: string;
    baseUrl: string;
}

export interface Order extends HistoryChanges, Model {
    orderId: string;
    orderNumber: string;
    createdDate: string;
    shop: Shop;
    appliedDiscount?: string;
    discountType?: string;
    discount?: number;
    userNote?: string;
    shopNote?: string;
    status?: string;
    callStatus?: string;
    customer: {
        billing: Customer;
        delivery: Customer;
    };
    items: ProductItem[];
    billing: Item;
    delivery: Item;
    totalPriceWithTax?: number;
    invoice: Invoice;
    isPaid: boolean;
    shipping?: string;
    shippingNote?: string;
    flags: OrderFlag[] | undefined | null;
    courierShippingInfo?: CourierShippingInfo;
    cartageId?: string;
    cartageName?: string;
    claims?: string[];
    isClaim?: boolean;
    clones?: string[];
    isClone?: boolean;
    withoutInvoice?: boolean;
    billNumber?: string;
    expectedShippingDate?: string;
    isIgnoredFromStats?: boolean;
}

export enum OrderFlag {
    PriceChanged = 'PRICE_CHANGED',
    DoubleCheck = 'DOUBLE_CHECK',
    ProductInfoMissing = 'PRODUCT_INFO_MISSING',
    B2B = 'B2B'
}

export interface OrderStatus {
    _id: string;
    status: number;
}

export interface CourierPackage {
    id: string;
    number: string;
}

export interface CourierShippingInfo {
    courier: CourierCompany;
    packagesCount: number;
    packages: CourierPackage[];
}
