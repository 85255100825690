import { createAction } from 'redux-actions';

export enum SHARED_DATA_KEY {
    product = 'product',
    shippingPlanning = 'shipping-planning',
    cartageGroupList = 'cartageGroupList',
    cartagePlanningGroup = 'cartagePlanningGroup',
    cartage = 'cartage',
    cartageList = 'cartageList'
}

export const updateSharedData = createAction(
    'UPDATE_SHARED_DATA',
    (key: SHARED_DATA_KEY, propName: string, propValue: any) => ({
        key,
        propName,
        propValue
    })
);

export const addSharedData = createAction(
    'ADD_SHARED_DATA',
    (key: SHARED_DATA_KEY, data: any) => ({
        key,
        data
    })
);

export const addSharedDataItem = createAction(
    'ADD_SHARED_DATA_ITEM',
    (key: SHARED_DATA_KEY, propName: string, item: any) => ({
        key,
        propName,
        item
    })
);

export const removeSharedDataItem = createAction(
    'REMOVE_SHARED_DATA_ITEM',
    (key: SHARED_DATA_KEY, propName: string, item: any) => ({
        key,
        propName,
        item
    })
);
