import { MenuItem } from '@material-ui/core';
import React from 'react';
import { ReactFragment } from 'react';
import { Enum } from '../models/Enum';

export function renderEnumOptions(
    enumData: Enum,
    disabledEnumCodes?: string[]
): ReactFragment {
    return enumData.data.map(enumItem => (
        <MenuItem
            key={enumItem.code}
            value={enumItem.code}
            disabled={disabledEnumCodes?.includes(enumItem.code)}
        >
            {enumItem.label}
        </MenuItem>
    ));
}

export function resolveEnumValue(enumData: Enum, enumCode: string): string {
    const enumItem = enumData.data.find(enumItem => enumItem.code === enumCode);
    return enumItem ? enumItem.label : `ERROR - Unknown code: ${enumCode}`;
}
