import React, { useCallback, useEffect } from 'react';
import SaveIcon from '@material-ui/icons/Save';
import i18next from 'i18next';
import { selectSharedProduct } from 'src/common/sharedData/SharedDataSelectors';
import { Tooltip, IconButton, makeStyles } from '@material-ui/core';
import { tooltipEnterDelay } from 'src/config';
import { useRequest } from 'src/common/useRequestHook';
import { useSelector, useDispatch } from 'react-redux';
import { showNotification } from 'src/common/notifications/NotificationsActions';
import { NotificationVariant } from 'src/models/Notification';
import {
    addSharedData,
    SHARED_DATA_KEY
} from 'src/common/sharedData/SharedDataActions';
import {
    getProductSaveMethod,
    getProductSaveUrl,
    isCreatingNewProduct,
    getEmptyProduct
} from './ProductService';
import { match } from 'react-router-dom';
import { Product } from 'src/models/Product';
import { RefreshProductIconButton } from './RefreshProductIconButton';

const useStyles = makeStyles({
    container: {
        width: '100%',
        textAlign: 'right'
    }
});

interface Props {
    match: match<{
        productId: string;
    }>;
}

export const ProductAppBarView: React.FC<Props> = ({ match }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const product = useSelector(selectSharedProduct);
    const [
        saveProduct,
        { inProgress, hasSucceed, response: savedProduct }
    ] = useRequest<Product>(
        getProductSaveMethod(product?._id),
        getProductSaveUrl(product?._id)
    );

    useEffect(() => {
        if (!hasSucceed) {
            return;
        }

        dispatch(
            showNotification({
                id: `product_save_success_${Date.now()}`,
                variant: NotificationVariant.Success,
                text: i18next.t('product.saveSuccessNotification')
            })
        );
    }, [dispatch, hasSucceed]);

    useEffect(() => {
        if (!hasSucceed) {
            return;
        }

        const sharedProduct = isCreatingNewProduct(match.params.productId)
            ? getEmptyProduct()
            : savedProduct;

        dispatch(addSharedData(SHARED_DATA_KEY.product, sharedProduct));
    }, [dispatch, hasSucceed, match.params.productId, savedProduct]);

    const onSaveClick = useCallback(() => {
        saveProduct(product);
    }, [saveProduct, product]);

    return (
        <div className={classes.container}>
            <RefreshProductIconButton productId={match.params.productId} />
            <Tooltip
                title={i18next.t('product.saveProductBtn')}
                enterDelay={tooltipEnterDelay}
            >
                <IconButton
                    color="inherit"
                    disabled={inProgress}
                    onClick={onSaveClick}
                >
                    <SaveIcon />
                </IconButton>
            </Tooltip>
        </div>
    );
};
