export const QUERY_LIMIT = 40;

export function getNextSkip(currentSkip: number): number {
    return currentSkip + QUERY_LIMIT;
}

export function hasMoreItems(fetchedItemsCount: number, skip: number): boolean {
    return (
        fetchedItemsCount === skip || fetchedItemsCount - QUERY_LIMIT === skip
    );
}
