import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button,
    TableContainer,
    Paper
} from '@material-ui/core';
import i18next from 'i18next';
import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { appPaths } from 'src/AppRouterService';
import { API_URLS } from 'src/common/ApiService';
import { useRequest } from 'src/common/useRequestHook';
import { TableLoader } from 'src/components/TableLoader';
import { RequestMethod } from 'src/utils/request';
import { CartageGroup } from './CartageGroupTypes';
import { useCartageGroupSharedData } from './useCartageGroupSharedData';

export const CartageGroupList: React.FC = () => {
    const { query } = useCartageGroupSharedData();
    const [
        fetchCartageGroups,
        { response: cartageGroups = [], inProgress }
    ] = useRequest<CartageGroup[]>(
        RequestMethod.GET,
        `${API_URLS.cartageGroups}?country=${query.country}`
    );

    const [deleteCartageGroup, { inProgress: isDeleting }] = useRequest(
        RequestMethod.DELETE
    );

    useEffect(() => {
        fetchCartageGroups();
    }, [fetchCartageGroups]);

    return (
        <TableContainer component={Paper}>
            <Table className="table">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            {i18next.t('cartageGroupList.name')}
                        </TableCell>
                        <TableCell />
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {inProgress && <TableLoader key="loader" colSpan={3} />}
                    {!cartageGroups?.length && (
                        <TableRow>
                            <TableCell align="center" colSpan={3}>
                                {i18next.t('cartageGroupList.noGroupsDefined')}
                            </TableCell>
                        </TableRow>
                    )}
                    {cartageGroups.map(({ _id, name }) => (
                        <TableRow key={_id}>
                            <TableCell>{name}</TableCell>
                            <TableCell>
                                <Button
                                    color="secondary"
                                    disabled={isDeleting}
                                    onClick={async () => {
                                        await deleteCartageGroup(
                                            undefined,
                                            `${API_URLS.cartageGroups}/${_id}`
                                        );
                                        fetchCartageGroups();
                                    }}
                                >
                                    {i18next.t('cartageGroupList.delete')}
                                </Button>
                            </TableCell>
                            <TableCell>
                                <NavLink
                                    to={`${appPaths.cartageGroups}/${_id}`}
                                >
                                    {i18next.t('cartageGroupList.edit')}
                                </NavLink>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
