import i18next from 'i18next';
import { Notification, NotificationVariant } from '../../models/Notification';

export function removeNotification(notifications: Notification[], id: string) {
    const notificationIndex = notifications.findIndex(
        notification => notification.id === id
    );
    if (notificationIndex > -1) {
        const newNotifications = [...notifications];
        newNotifications.splice(notificationIndex, 1);
        return newNotifications;
    }

    return notifications;
}

export function addAndRemoveOldNotification(
    notifications: Notification[],
    notification: Notification
) {
    return [
        notification,
        ...removeNotification(notifications, notification.id)
    ];
}

export function convertErrorToNotification(error: any): Notification {
    return {
        id: error.message,
        text: i18next.t(
            `apiError.${error.message}`,
            error.options.messageOptions
        ),
        variant: NotificationVariant.Error
    };
}
