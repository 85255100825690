import React from 'react';
import { TableCell, TableRow, Typography } from '@material-ui/core';
import i18next from 'i18next';
import { TableLoader } from 'src/components/TableLoader';
import { Cartage } from 'src/models/Cartage';
import { CartageItem } from './CartageItem';

interface Props {
    isLoading: boolean;
    hasMore: boolean;
    items: Cartage[];
}

const tableColumnsCount = 4;

export const OrdersToShipItems: React.FC<Props> = ({
    isLoading,
    hasMore,
    items
}) => {
    if (!isLoading && items.length === 0) {
        return (
            <TableRow>
                <TableCell colSpan={tableColumnsCount} align="center">
                    <Typography variant="caption" gutterBottom>
                        {i18next.t('cartageList.noCartageMatchesCriteria')}
                    </Typography>
                </TableCell>
            </TableRow>
        );
    }

    const rows = items.map(cartage => (
        <CartageItem key={cartage._id} cartage={cartage} />
    ));

    if (isLoading || hasMore) {
        rows.push(<TableLoader key="loader" colSpan={tableColumnsCount} />);
    }

    return <>{rows}</>;
};
