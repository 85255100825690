import { useEffect } from 'react';
import { API_URLS } from 'src/common/ApiService';
import { useRequest } from 'src/common/useRequestHook';
import { RequestMethod } from 'src/utils/request';
import { create } from 'zustand';

let isFetching = false;

interface ProductInfoState {
    oversizedProductCodes: string[] | undefined;
    setOversizedProductCodes: (codes: string[]) => void;
}

export const useProductInfoStore = create<ProductInfoState>(set => ({
    oversizedProductCodes: undefined,
    setOversizedProductCodes: (oversizedProductCodes: string[]) =>
        set({ oversizedProductCodes })
}));

interface Hook {
    inProgress: boolean;
    oversizedProductCodes: string[] | undefined;
}

export function useOversizedProductCodes(): Hook {
    const {
        oversizedProductCodes,
        setOversizedProductCodes
    } = useProductInfoStore();
    const [fetchProductsIds] = useRequest<string[]>(
        RequestMethod.GET,
        API_URLS.oversizedProductsInfo
    );

    useEffect(() => {
        if (isFetching) {
            return;
        }

        isFetching = true;
        fetchProductsIds().then(codes => {
            setOversizedProductCodes(codes);
        });
    }, [fetchProductsIds, setOversizedProductCodes]);

    return {
        inProgress: !oversizedProductCodes,
        oversizedProductCodes: oversizedProductCodes
    };
}
