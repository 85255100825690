import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EnumsState } from '../common/enums/EnumsState';
import { AppState } from '../models/AppState';
import { Order } from '../models/Order';
import { loadOrders, updateQuery } from './OrderListActions';
import { OrderListQuery } from './OrderListState';
import { OrderListView } from './OrderListView';
import { getNextSkip, hasMoreItems } from '../common/PaginationService';

interface Props {
    isLoading: boolean;
    orders: Order[];
    query: OrderListQuery;
    enums: EnumsState;
    checkedOrders: string[];
    onLoadOrders: (query?: OrderListQuery) => void;
    onUpdateQuery: (query: OrderListQuery) => void;
    onCheckOrder: (id: string, isChecked: boolean) => void;
}

export class OrderListContainerBase extends Component<Props> {
    constructor(props: Props) {
        super(props);

        if (props.orders.length === 0) {
            this.loadOrders();
        }
    }

    render() {
        const { isLoading, orders, query, enums, checkedOrders } = this.props;
        return (
            <OrderListView
                isLoading={isLoading}
                orders={orders}
                enums={enums}
                hasMoreOrders={hasMoreItems(orders.length, query.skip)}
                onLoadMoreOrders={this.onLoadMoreOrders}
                checkedOrders={checkedOrders}
            />
        );
    }

    loadOrders() {
        this.props.onLoadOrders(this.props.query);
    }

    onLoadMoreOrders = async () => {
        await this.props.onUpdateQuery({
            skip: getNextSkip(this.props.query.skip)
        });
        this.loadOrders();
    };
}

const mapStateToProps = (state: AppState) => ({
    isLoading: state.orderList.isLoading,
    orders: state.orderList.data,
    query: state.orderList.query,
    enums: state.enums,
    checkedOrders: state.orderList.checkedOrders
});

const mapDispatchToProps = {
    onLoadOrders: loadOrders,
    onUpdateQuery: updateQuery
};

const OrderListContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderListContainerBase);

export { OrderListContainer };
