import React from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import { formatPath } from './HistoryService';
import { HistoryChangeValue } from './HistoryChangeValue';
import { useHistoryStyles } from './HistoryStyles';

interface Props {
    change: any;
    translationScope: string;
}

export const HistoryChange: React.FC<Props> = ({
    change,
    translationScope
}) => {
    const classes = useHistoryStyles();
    return (
        <TableRow className={classes.changeRow}>
            <TableCell className={classes.colField}>
                {formatPath(change.path, translationScope)}
            </TableCell>
            <TableCell className={classes.colValue}>
                <HistoryChangeValue
                    change={change}
                    selector="lhs"
                    translationScope={translationScope}
                />
            </TableCell>
            <TableCell className={classes.colValue}>
                <HistoryChangeValue
                    change={change}
                    selector="rhs"
                    translationScope={translationScope}
                />
            </TableCell>
        </TableRow>
    );
};
