import { StyleRules, withStyles, WithStyles } from '@material-ui/core/styles';
import React, { ReactNode } from 'react';
import { menuWidth } from '../../config';
import { AppBarLayoutView } from '../AppBarLayoutView';
import { MenuView } from '../MenuView';
import { InitialLoadingView } from './InitialLoadingView';

interface Props extends WithStyles {
    isLoading: boolean;
    isMobileMenuOpen: boolean;
    onLogout: () => void;
    onNavToggle: () => void;
    children: ReactNode;
}

const decorate = withStyles(theme => {
    const styles: StyleRules = {
        root: {
            flexGrow: 1,
            zIndex: 1,
            overflow: 'hidden',
            position: 'relative',
            display: 'flex',
            width: '100%'
        },
        toolbar: {
            padding: '0 8px',
            ...theme.mixins.toolbar
        },
        content: {
            [theme.breakpoints.up('lg')]: {
                marginLeft: menuWidth
            },
            width: '100%',
            backgroundColor: theme.palette.background.default,
            padding: theme.spacing(3)
        }
    };

    return styles;
});

const LayoutView = decorate(
    ({
        isLoading,
        isMobileMenuOpen,
        classes,
        children,
        onLogout,
        onNavToggle
    }: Props) => {
        if (isLoading) {
            return <InitialLoadingView />;
        }

        return (
            <div className={classes.root}>
                <AppBarLayoutView
                    onLogoutBtnClick={onLogout}
                    onNavIconClick={onNavToggle}
                />
                <MenuView
                    isMobileMenuOpen={isMobileMenuOpen}
                    onDrawerClose={onNavToggle}
                />
                <main className={classes.content}>
                    <div className={'no-print ' + classes.toolbar} />
                    {children}
                </main>
            </div>
        );
    }
);

export { LayoutView };
