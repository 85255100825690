import {
    FormControl,
    FormLabel,
    Grid,
    MenuItem,
    TextField,
    Typography
} from '@material-ui/core';
import i18next from 'i18next';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { generatePath, useHistory } from 'react-router-dom';
import { appPaths } from 'src/AppRouterService';
import { API_URLS } from 'src/common/ApiService';
import { useRequest } from 'src/common/useRequestHook';
import { JDatePicker } from 'src/components/JDatePicker';
import { RequestMethod } from 'src/utils/request';
import {
    ALL_COUNTRIES,
    CartageOverview,
    filterCartages,
    FilterQuery,
    getQueryString
} from './CartagesOverviewService';
import { country as countryEnum } from 'src/common/enums/enums';

export const CartagesOverview: React.FC = () => {
    const [query, setQuery] = useState<FilterQuery>({
        to: moment(),
        from: moment().add('days', -6)
    });

    const [country, setCountry] = useState(ALL_COUNTRIES);
    const [filteredCartages, setFilteredCartages] = useState<CartageOverview[]>(
        []
    );

    const history = useHistory();

    const [
        fetchCartagesOverview,
        { response: cartages = [], inProgress }
    ] = useRequest<CartageOverview[]>(RequestMethod.GET);

    useEffect(() => {
        fetchCartagesOverview(
            undefined,
            `${API_URLS.cartagesOverview}?${getQueryString(query)}`
        );
    }, [query, fetchCartagesOverview]);

    useEffect(() => {
        setFilteredCartages(filterCartages(cartages, country));
    }, [cartages, country]);

    const openOrdersData = {
        labels: filteredCartages.map(({ name }) => name),
        datasets: [
            {
                label: i18next.t('cartagesOverview.itemsCount'),
                data: filteredCartages.map(
                    ({ itemsCount, totalBoxCount }) =>
                        itemsCount || totalBoxCount
                ),
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1
            },
            {
                label: i18next.t('cartagesOverview.ordersCount'),
                data: filteredCartages.map(({ ordersCount }) => ordersCount),
                backgroundColor: 'rgba(75, 92, 192, 0.2)',
                borderColor: 'rgba(75, 92, 192, 1)',
                borderWidth: 1
            }
        ]
    };

    const handleOnChartClick = ([chartElement]) => {
        if (!chartElement) {
            return null;
        }

        history.push(
            generatePath(appPaths.cartage, {
                cartageId: filteredCartages[chartElement._index]._id
            })
        );
    };

    const handleFilterQueryDateFromUpdate = (from: Moment) => {
        setQuery({ ...query, from });
    };

    const handleFilterQueryDateToUpdate = (to: Moment) => {
        setQuery({ ...query, to });
    };

    const handleCountryChange = evt => {
        setCountry(evt.target.value);
    };

    const totalSum = filteredCartages.reduce(
        ({ orders, boxes }, { ordersCount, totalBoxCount }) => ({
            orders: orders + ordersCount,
            boxes: boxes + totalBoxCount
        }),
        { orders: 0, boxes: 0 }
    );

    return (
        <>
            <h1 style={{ marginTop: '10px', marginBottom: '10px' }}>
                {i18next.t('cartagesOverview.title')}
            </h1>
            <div>
                <FormControl margin="normal">
                    <FormLabel component="legend">
                        {i18next.t('cartagesOverview.shippingDate')}
                    </FormLabel>
                    <Grid container>
                        <JDatePicker
                            clearable
                            disableFuture
                            disabled={inProgress}
                            label={i18next.t('From')}
                            value={query.from}
                            onChange={handleFilterQueryDateFromUpdate}
                            style={{ marginRight: 10 }}
                        />
                        <JDatePicker
                            clearable
                            disableFuture
                            disabled={inProgress}
                            label={i18next.t('To')}
                            value={query.to}
                            onChange={handleFilterQueryDateToUpdate}
                            style={{ marginRight: 10 }}
                        />
                    </Grid>
                </FormControl>
                <FormControl margin="normal">
                    <FormLabel component="legend">
                        {i18next.t('Country')}
                    </FormLabel>
                    <TextField
                        name="country"
                        select
                        margin="normal"
                        onChange={handleCountryChange}
                        value={country}
                    >
                        <MenuItem value={ALL_COUNTRIES}>Všetky</MenuItem>
                        <MenuItem value={countryEnum.SK}>Slovensko</MenuItem>
                        <MenuItem value={countryEnum.HU}>Maďarsko</MenuItem>
                    </TextField>
                </FormControl>
            </div>
            <div>
                <Typography variant="body2">
                    {i18next.t('cartagesOverview.ordersSum') +
                        ' ' +
                        totalSum.orders}
                </Typography>
                <Typography variant="body2">
                    {i18next.t('cartagesOverview.boxesSum') +
                        ' ' +
                        totalSum.boxes}
                </Typography>
                <Typography variant="body2">
                    {i18next.t('cartagesOverview.cartagesSum') +
                        ' ' +
                        filteredCartages.length}
                </Typography>
            </div>
            <div style={{ maxWidth: '70rem' }}>
                <Bar
                    data={openOrdersData}
                    options={{
                        scales: {
                            yAxes: [
                                {
                                    id: 'itemsCount',
                                    type: 'linear',
                                    ticks: {
                                        beginAtZero: true,
                                        precision: 0
                                    },
                                    position: 'left',
                                    gridLines: {
                                        display: false
                                    }
                                }
                            ]
                        }
                    }}
                    getElementAtEvent={handleOnChartClick}
                />
            </div>
        </>
    );
};
