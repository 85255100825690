import {
    Badge,
    IconButton,
    ListItemText,
    Menu,
    MenuItem,
    Tooltip
} from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { WithStyles } from '@material-ui/core/styles/withStyles';
import FilterListIcon from '@material-ui/icons/FilterList';
import ListIcon from '@material-ui/icons/List';
import i18next from 'i18next';
import { Moment } from 'moment';
import React from 'react';
import { ChangeEvent, MouseEvent, PureComponent } from 'react';
import { EnumsState } from '../common/enums/EnumsState';
import { tooltipEnterDelay } from '../config';
import { Order } from '../models/Order';
import { OrderListFilterDialog } from './OrderListFilterDialog';
import {
    getActiveFiltersCount,
    isChangeOrdersStatusToDoneDisabled
} from './OrderListService';
import { OrderListQuery } from './OrderListState';
import { SearchInput } from '../components/SerchInput';
import { OrderListRefreshButton } from './OrderListRefreshButton';

const styles = () =>
    createStyles({
        filterIconBadge: {
            top: '10px',
            right: '10px'
        }
    });

interface Props extends WithStyles<typeof styles> {
    enums: EnumsState;
    filterQuery: OrderListQuery;
    isFilterDialogOpen: boolean;
    query: OrderListQuery;
    checkedOrders: string[];
    orders: Order[];
    isLoading: boolean;
    isSimpleUser: boolean;
    onFilterDialogClose: () => void;
    onSearchChange: (value: string) => void;
    onFilterIconClick: (event: MouseEvent<HTMLInputElement>) => void;
    onFilterQueryUpdate: (event: ChangeEvent<HTMLInputElement>) => void;
    onFilterQueryPaymentStatusUpdate: (value?: boolean) => void;
    onFilterQueryDateFromUpdate: (date: Moment) => void;
    onFilterQueryDateToUpdate: (date: Moment) => void;
    onFilterQuerySet: () => void;
    onFilterQueryReset: () => void;
    onChangeOrdersStatusToDelivered: () => void;
    onRefreshOrders: () => void;
}

interface State {
    multiActionAnchorEl: HTMLElement;
}

class OrderListAppBarViewBase extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            multiActionAnchorEl: null
        };
    }

    renderFilterIcon() {
        const { classes, query, onFilterIconClick } = this.props;

        const filterIconBtn = (
            <Tooltip
                title={i18next.t('Orders filter')}
                enterDelay={tooltipEnterDelay}
            >
                <IconButton color="inherit" onClick={onFilterIconClick}>
                    <FilterListIcon />
                </IconButton>
            </Tooltip>
        );
        const activeFiltersCount = getActiveFiltersCount(query);

        if (activeFiltersCount > 0) {
            return (
                <Badge
                    classes={{ badge: classes.filterIconBadge }}
                    badgeContent={activeFiltersCount}
                    color="secondary"
                >
                    {filterIconBtn}
                </Badge>
            );
        }
        return filterIconBtn;
    }

    onMultiActionClick = evt => {
        this.setState({ multiActionAnchorEl: evt.currentTarget });
    };

    onMultiActionMenuClose = () => {
        this.setState({ multiActionAnchorEl: null });
    };

    onChangeOrdersStatusToDeliveredClick = () => {
        this.setState({ multiActionAnchorEl: null });
        this.props.onChangeOrdersStatusToDelivered();
    };

    renderMultiActionMenu() {
        const { multiActionAnchorEl } = this.state;
        const { orders, checkedOrders } = this.props;

        return (
            <Menu
                anchorEl={multiActionAnchorEl}
                open={Boolean(multiActionAnchorEl)}
                onClose={this.onMultiActionMenuClose}
            >
                <MenuItem
                    disabled={isChangeOrdersStatusToDoneDisabled(
                        orders,
                        checkedOrders
                    )}
                >
                    <ListItemText
                        primary={i18next.t('Change status')}
                        secondary={i18next.t('Ready to ship -> Done')}
                        onClick={this.onChangeOrdersStatusToDeliveredClick}
                    />
                </MenuItem>
            </Menu>
        );
    }

    renderMultiAction() {
        if (this.props.isSimpleUser) {
            return null;
        }

        return (
            <Tooltip
                title={i18next.t('Selected orders multi actions')}
                enterDelay={tooltipEnterDelay}
            >
                <IconButton color="inherit" onClick={this.onMultiActionClick}>
                    <ListIcon />
                </IconButton>
            </Tooltip>
        );
    }

    render() {
        const {
            enums,
            filterQuery,
            isFilterDialogOpen,
            query,
            onFilterDialogClose,
            onSearchChange,
            onFilterQueryUpdate,
            onFilterQueryDateFromUpdate,
            onFilterQueryDateToUpdate,
            onFilterQuerySet,
            onFilterQueryReset,
            onFilterQueryPaymentStatusUpdate
        } = this.props;

        return (
            <>
                <SearchInput
                    value={query.search}
                    placeholder={i18next.t('Order number, name, email')}
                    onChange={onSearchChange}
                />
                <OrderListRefreshButton
                    disabled={this.props.isLoading}
                    onRefreshOrders={this.props.onRefreshOrders}
                />
                {this.renderFilterIcon()}
                {this.renderMultiAction()}
                <OrderListFilterDialog
                    enums={enums}
                    open={isFilterDialogOpen}
                    filterQuery={filterQuery}
                    onClose={onFilterDialogClose}
                    onFilterQueryUpdate={onFilterQueryUpdate}
                    onFilterQueryDateFromUpdate={onFilterQueryDateFromUpdate}
                    onFilterQueryDateToUpdate={onFilterQueryDateToUpdate}
                    onFilterQuerySet={onFilterQuerySet}
                    onFilterQueryReset={onFilterQueryReset}
                    onFilterQueryPaymentStatusUpdate={
                        onFilterQueryPaymentStatusUpdate
                    }
                />
                {this.renderMultiActionMenu()}
            </>
        );
    }
}

const OrderListAppBarView = withStyles(styles)(OrderListAppBarViewBase);

export { OrderListAppBarView };
