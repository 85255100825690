export const appPaths = {
    login: '/login',
    orderList: '/order',
    order: '/order/:orderId',
    ordersExport: '/orders-stats-export',
    shippingPlanning: '/shipping-planning',
    shippingOrdersPrinting: '/shipping-orders-printing',
    shopList: '/shop',
    productList: '/product',
    addNewProduct: '/product/new',
    product: '/product/:productId',
    userList: '/user',
    userChangelog: '/order/user-changelog',
    overviewOpenOrders: '/overview/open-orders',
    overviewCartages: '/overview/cartages',
    overviewCourierShipping: '/overview/courier-shipping',
    overviewCartageExpeditionTime: '/overview/cartage-expedition-time',
    overviewExpeditionStats: '/overview/expedition-stats',
    cartageList: '/cartage',
    cartage: '/cartage/:cartageId',
    markAsDone: '/scanner/mark-as-done',
    printCourierLabels: '/scanner/print-courier-labels',
    cartageGroups: '/cartage-group',
    addCartageGroup: '/cartage-group/add',
    editCartageGroup: '/cartage-group/:id',
    cartagePlanning: '/cartage-planning',
    cartagePlanningGroup: '/cartage-planning/:cartageGroupId',
    cartagePlanningOrderWithoutGroup: '/cartage-planning/orders-without-group',
    productsAvailability: '/products-availability'
};
