import { Product } from '../models/Product';
import React from 'react';
import { TableCell, TableRow, Typography } from '@material-ui/core';
import i18next from 'i18next';
import { TableLoader } from 'src/components/TableLoader';
import { NavLink } from 'react-router-dom';

interface Props {
    isLoading: boolean;
    hasMore: boolean;
    products: Product[];
}

const tableColumnsCount = 5;

export const ProductListTableBody: React.FC<Props> = ({
    isLoading,
    hasMore,
    products
}) => {
    if (!isLoading && products.length === 0) {
        return (
            <TableRow>
                <TableCell colSpan={tableColumnsCount} align="center">
                    <Typography variant="caption" gutterBottom>
                        {i18next.t('productList.noProductsMatchCriteria')}
                    </Typography>
                </TableCell>
            </TableRow>
        );
    }

    const rows = products.map(product => (
        <TableRow key={product._id}>
            <TableCell>{product.code}</TableCell>
            <TableCell>{product.name.SK}</TableCell>
            <TableCell>{product.variant}</TableCell>
            <TableCell>{product.manufacturer.name}</TableCell>
            <TableCell>
                <NavLink to={`/product/${product._id}`}>
                    {i18next.t('Display')}
                </NavLink>
            </TableCell>
        </TableRow>
    ));

    if (isLoading || hasMore) {
        rows.push(<TableLoader key="loader" colSpan={tableColumnsCount} />);
    }

    return <>{rows}</>;
};
