import { CircularProgress, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import i18next from 'i18next';
import React, { useEffect } from 'react';
import { NavLink, generatePath } from 'react-router-dom';
import { appPaths } from 'src/AppRouterService';
import { API_URLS } from 'src/common/ApiService';
import { useRequest } from 'src/common/useRequestHook';
import { Order } from 'src/models/Order';
import { RequestMethod } from 'src/utils/request';
import { useDebounce } from 'use-debounce';

interface Props {
    order: Order;
}

interface OrderNumber {
    _id: string;
    orderNumber: string;
}

export const SameCustomerOpenOrdersLink: React.FC<Props> = ({ order }) => {
    const [fetch, { inProgress, response: orders = [] }] = useRequest<
        OrderNumber[]
    >(RequestMethod.GET, API_URLS.openOrdersForSameCustomer, {
        _id: order._id,
        email: order.customer.delivery.email ?? '-',
        phone: order.customer.delivery.phone ?? '-'
    });

    const [debouncedFetch] = useDebounce(() => fetch, 300);

    useEffect(() => {
        debouncedFetch();
    }, [debouncedFetch]);

    if (inProgress) {
        return <CircularProgress style={{ marginLeft: '10px' }} size={20} />;
    }

    if (orders.length === 0) {
        return null;
    }

    return (
        <div style={{ marginLeft: '10px' }}>
            <Alert severity="warning">
                <Typography variant="caption" style={{ marginRight: '10px' }}>
                    {i18next.t('order.openOrders')}
                </Typography>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {orders.map(({ _id, orderNumber }) => (
                        <NavLink
                            key={_id}
                            to={generatePath(appPaths.order, {
                                orderId: _id
                            })}
                            exact
                            style={{ marginRight: '10px' }}
                        >
                            {orderNumber}
                        </NavLink>
                    ))}
                </div>
            </Alert>
        </div>
    );
};
