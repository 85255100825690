import { OrderInfo } from 'src/models/Cartage';
import { OrderToShip } from 'src/shippingPlanning/ShippingPlanningService';

const getOrdersInfoIndexById = (ordersInfo: OrderInfo[], id: string): number =>
    ordersInfo.findIndex(({ orderId }) => orderId === id);

const getOrdersSequence = (ordersInfo: OrderInfo[], id: string): number => {
    const index = ordersInfo.findIndex(({ orderId }) => orderId === id);

    return ordersInfo[index]?.sequence ?? index;
};
export function sortOrders(
    orders: OrderToShip[],
    ordersInfo: OrderInfo[]
): OrderToShip[] {
    if (!orders || !ordersInfo) {
        return [];
    }

    return orders.sort(
        (orderA, orderB) =>
            getOrdersSequence(ordersInfo, orderA._id) -
            getOrdersSequence(ordersInfo, orderB._id)
    );
}

export function reorderOrdersIds(
    ordersInfo: OrderInfo[] = [],
    orderIdSourceIndex: number,
    orderIdDestinationIndex: number
): OrderInfo[] {
    const result = [...ordersInfo];
    const [removed] = result.splice(orderIdSourceIndex, 1);
    result.splice(orderIdDestinationIndex, 0, removed);

    return result;
}

export function getOrdersInfoById(
    ordersInfo: OrderInfo[],
    id: string
): OrderInfo {
    return ordersInfo.find(({ orderId }) => orderId === id);
}

export function updateOrdersInfo(
    ordersInfo: OrderInfo[],
    updatedOrderInfo: OrderInfo
): OrderInfo[] {
    const updatedOrdersInfo = [...ordersInfo];
    const updatedOrderInfoIndex = getOrdersInfoIndexById(
        ordersInfo,
        updatedOrderInfo.orderId
    );
    updatedOrdersInfo[updatedOrderInfoIndex] = updatedOrderInfo;

    return updatedOrdersInfo;
}

export function getSequenceNumber(
    sequence: number | undefined,
    index: number,
    isIndexUsedAsDefault: boolean
): number | undefined {
    if (sequence) {
        return sequence;
    }

    if (!isIndexUsedAsDefault) {
        return undefined;
    }

    return index + 1;
}
