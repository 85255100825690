import moment from 'moment';

export function formatDate(dateTimeString: string): string {
    return moment(dateTimeString).format('DD.MM.YYYY');
}

export function formatUtcDate(dateTimeString: string): string {
    return moment.utc(dateTimeString).format('DD.MM.YYYY');
}

export function formatDateTime(dateTimeString: string): string {
    return moment(dateTimeString).format('DD.MM.YYYY HH:mm');
}

export function formatWeight(number = 0): string {
    return `${Math.round(number * 100) / 100} kg`;
}
