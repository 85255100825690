import React from 'react';
import { TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import i18next from 'i18next';
import { TableLoader } from 'src/components/TableLoader';
import { OrderToShip } from 'src/shippingPlanning/ShippingPlanningService';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
    getOrdersInfoById,
    getSequenceNumber
} from './SortableOrdersToShipListService';
import { CartageOrderToShipItem } from './CartageOrderToShipItem';
import { OrderInfo } from 'src/models/Cartage';

interface Props {
    isLoading: boolean;
    orders: OrderToShip[];
    ordersInfo?: OrderInfo[];
    disabled?: boolean;
    isDragDisabled?: boolean;
    showOrderItemStatusIcon: boolean;
    showOnlyNotPaidOrdersWithInvoice: boolean;
    onOrdersSort(
        orderIdSourceIndex: number,
        orderIdDestinationIndex: number
    ): void;
    onOrderInfoChange(orderInfo: OrderInfo): void;
}

const tableColumnsCount = 10;

const getItemStyle = (isDragging, draggableStyle) => ({
    ...draggableStyle,

    ...(isDragging && {
        background: 'rgb(235,235,235)'
    })
});

export const SortableOrdersToShipList: React.FC<Props> = ({
    isLoading,
    orders = [],
    ordersInfo = [],
    disabled,
    isDragDisabled,
    showOrderItemStatusIcon,
    onOrdersSort,
    onOrderInfoChange,
    showOnlyNotPaidOrdersWithInvoice
}) => {
    const onDragEnd = result => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        onOrdersSort(result.source.index, result.destination.index);
    };

    if (!isLoading && orders.length === 0) {
        return (
            <TableRow className="no-print">
                <TableCell colSpan={tableColumnsCount} align="center">
                    <Typography variant="caption" gutterBottom>
                        {i18next.t('shippingPlanning.noOrderMatchesCriteria')}
                    </Typography>
                </TableCell>
            </TableRow>
        );
    }

    if (isLoading) {
        return <TableLoader key="loader" colSpan={tableColumnsCount} />;
    }

    const shownOrders = orders.filter(order => {
        if (!showOnlyNotPaidOrdersWithInvoice) {
            return true;
        }

        return !order.isPaid && order.invoice?.id;
    });
    const showNoUnpaidOrders =
        showOnlyNotPaidOrdersWithInvoice && shownOrders.length === 0;

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {provided => (
                    <TableBody
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                        {showNoUnpaidOrders && (
                            <TableRow>
                                <TableCell
                                    colSpan={tableColumnsCount}
                                    align="center"
                                >
                                    <Typography variant="caption" gutterBottom>
                                        {i18next.t(
                                            'shippingPlanning.allOrdersFromCartageArePaid'
                                        )}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                        {shownOrders.map((order, index) => (
                            <Draggable
                                key={order._id}
                                draggableId={order._id}
                                index={index}
                                isDragDisabled={isDragDisabled}
                            >
                                {(provided, snapshot) => {
                                    const orderInfo = getOrdersInfoById(
                                        ordersInfo,
                                        order._id
                                    );
                                    return (
                                        <CartageOrderToShipItem
                                            order={order}
                                            orderInfo={orderInfo}
                                            onOrderInfoChange={
                                                onOrderInfoChange
                                            }
                                            innerRef={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                            disabled={disabled}
                                            isSequenceNumberDisabled={
                                                !isDragDisabled
                                            }
                                            sequenceNumber={getSequenceNumber(
                                                orderInfo?.sequence,
                                                index,
                                                !isDragDisabled
                                            )}
                                            showOrderItemStatusIcon={
                                                showOrderItemStatusIcon
                                            }
                                        />
                                    );
                                }}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </TableBody>
                )}
            </Droppable>
        </DragDropContext>
    );
};
