import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userRole } from 'src/common/enums/enums';
import { AppState } from 'src/models/AppState';
import { loadUsers } from 'src/userList/UserListActions';

export type Driver = {
    username: string;
    address?: string;
};

export function useDriverUsers(): Driver[] {
    const dispatch = useDispatch();
    const users = useSelector((state: AppState) => state.userList.userList);
    const drivers = useMemo(
        () =>
            users
                .filter(({ role }) => role === userRole.DRIVER)
                .map(({ username, address }) => ({ address, username })),
        [users]
    );

    useEffect(() => {
        if (users.length > 0) {
            return;
        }
        dispatch(loadUsers());
    }, [dispatch, users]);

    return drivers;
}
