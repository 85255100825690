import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    addSharedData,
    SHARED_DATA_KEY
} from 'src/common/sharedData/SharedDataActions';
import { ProductDetails } from './ProductDetails';
import { getEmptyProduct } from './ProductService';

export const AddProductView: React.FC = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(addSharedData(SHARED_DATA_KEY.product, getEmptyProduct()));
    }, [dispatch]);
    return <ProductDetails />;
};
