import { Action, handleActions } from 'redux-actions';
import { loadFromStorage, saveToStorage } from '../common/localStorage';
import { replaceItemInArray, toggleArrayItem } from '../common/UtilsService';
import { Order, OrderStatus } from '../models/Order';
import { loadOrderSuccess } from '../order/OrderActions';
import * as actions from './OrderListActions';
import { updateOrdersStatus } from './OrderListService';
import { OrderListQuery, OrderListState } from './OrderListState';
import { QUERY_LIMIT } from 'src/common/PaginationService';

const queryLocalStorageKey = 'orderList-query';

const defaultQuery = {
    search: '',
    limit: QUERY_LIMIT,
    skip: 0,
    status: [],
    billingType: [],
    shipping: [],
    country: [],
    dateFrom: null,
    dateTo: null,
    paymentStatus: undefined,
    flags: []
};

const getSearchQueryFromUrl = () => {
    const params = new URLSearchParams(document.location.search);
    const search = params.get('search');
    if (!search) {
        return {};
    }

    return { search };
};

const defaultState: OrderListState = {
    data: [],
    isLoading: false,
    query: {
        ...defaultQuery,
        ...loadFromStorage(queryLocalStorageKey),
        ...getSearchQueryFromUrl(),
        skip: 0
    },
    checkedOrders: []
};

interface LoadOrdersSuccessPayload {
    orders: Order[];
}
interface LoadOrderSuccessPayload {
    order: Order;
}
interface UpdateUserPayload {
    query: OrderListQuery;
}

interface CheckOrderPayload {
    id: string;
    isChecked: boolean;
}

interface UpdateOrdersStatusSuccessPayload {
    ordersStatus: OrderStatus[];
}

const OrderListReducer = handleActions<OrderListState, any>(
    {
        [actions.resetOrders.toString()]: state => ({
            ...state,
            data: [],
            query: { ...state.query, skip: 0 }
        }),

        [actions.loadingOrders.toString()]: state => ({
            ...state,
            isLoading: true
        }),

        [actions.loadOrdersSuccess.toString()]: (
            state,
            action: Action<LoadOrdersSuccessPayload>
        ) => ({
            ...state,
            data: [...state.data, ...action.payload.orders],
            isLoading: false
        }),

        [actions.loadOrdersFailure.toString()]: state => ({
            ...state,
            isLoading: false
        }),

        [actions.updateQuery.toString()]: (
            state,
            action: Action<UpdateUserPayload>
        ) => {
            const query = { ...state.query, ...action.payload.query };

            saveToStorage(queryLocalStorageKey, query);
            return {
                ...state,
                query
            };
        },

        [loadOrderSuccess.toString()]: (
            state,
            action: Action<LoadOrderSuccessPayload>
        ) => ({
            ...state,
            data: replaceItemInArray(state.data, action.payload.order)
        }),
        [actions.checkOrder.toString()]: (
            state,
            action: Action<CheckOrderPayload>
        ) => {
            const { id, isChecked } = action.payload;
            const checkedOrders = toggleArrayItem(
                state.checkedOrders,
                id,
                isChecked
            );
            return {
                ...state,
                checkedOrders
            };
        },
        [actions.updateOrdersStatusSuccess.toString()]: (
            state,
            action: Action<UpdateOrdersStatusSuccessPayload>
        ) => ({
            ...state,
            data: updateOrdersStatus(state.data, action.payload.ordersStatus)
        })
    },
    defaultState
);

export { OrderListReducer };
