import React, { ChangeEvent, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, makeStyles, IconButton } from '@material-ui/core';
import { ProductPart } from 'src/models/Product';
import { grey } from '@material-ui/core/colors';
import { PartValueInput } from './PartValueInput';
import i18next from 'i18next';
import { roundToTwoDecimals, getPartVolume } from './ProductService';
import {
    removeSharedDataItem,
    SHARED_DATA_KEY
} from 'src/common/sharedData/SharedDataActions';
import DeleteIcon from '@material-ui/icons/Delete';

interface Props {
    index: number;
    part: ProductPart;
    onChange(event: ChangeEvent<HTMLInputElement>): void;
}

const useStyles = makeStyles(theme => ({
    part: {
        '&:nth-of-type(even)': {
            backgroundColor: grey[200],
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3)
        }
    }
}));

export const ProductPartItem: React.FC<Props> = ({ index, part, onChange }) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const onRemoveProductPart = useCallback(() => {
        dispatch(removeSharedDataItem(SHARED_DATA_KEY.product, 'parts', part));
    }, [dispatch, part]);

    return (
        <Grid
            key={part._id || index}
            container
            item
            spacing={3}
            className={classes.part}
        >
            <Grid item>
                <PartValueInput
                    name={`parts.${index}.height`}
                    value={part.height}
                    label={i18next.t('product.partHeight')}
                    unit="cm"
                    onChange={onChange}
                />
            </Grid>
            <Grid item>
                <PartValueInput
                    name={`parts.${index}.width`}
                    value={part.width}
                    label={i18next.t('product.partWidth')}
                    unit="cm"
                    onChange={onChange}
                />
            </Grid>
            <Grid item>
                <PartValueInput
                    name={`parts.${index}.depth`}
                    value={part.depth}
                    label={i18next.t('product.partDepth')}
                    unit="cm"
                    onChange={onChange}
                />
            </Grid>
            <Grid item>
                <PartValueInput
                    name={`parts.${index}.weight`}
                    value={part.weight}
                    label={i18next.t('product.partWeight')}
                    unit="kg"
                    onChange={onChange}
                />
            </Grid>
            <Grid item>
                <PartValueInput
                    disabled
                    name={`parts.${index}.volume`}
                    value={roundToTwoDecimals(getPartVolume(part))}
                    label={i18next.t('product.partVolume')}
                    unit="cm3"
                    onChange={onChange}
                />
            </Grid>
            <Grid item>
                <IconButton type="button" onClick={onRemoveProductPart}>
                    <DeleteIcon />
                </IconButton>
            </Grid>
        </Grid>
    );
};
