import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback } from 'react';
import { SearchInput } from 'src/components/SerchInput';
import i18next from 'i18next';
import {
    selectShippingQuery,
    selectShippingSelectedOrders
} from './ShippingPlanningSelectors';
import {
    updateOrdersToShipQuery,
    updateSelectedOrdersIds
} from './ShippingPlanningActions';
import {
    Tooltip,
    IconButton,
    FormControlLabel,
    Radio,
    RadioGroup
} from '@material-ui/core';
import { tooltipEnterDelay } from 'src/config';
import PrintIcon from '@material-ui/icons/Print';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import { RequestMethod } from 'src/utils/request';
import { useRequest } from 'src/common/useRequestHook';
import { API_URLS } from 'src/common/ApiService';
import { NotificationVariant } from 'src/models/Notification';
import { showNotification } from 'src/common/notifications/NotificationsActions';
import { orderStatus } from 'src/common/enums/enums';
import { Shipping } from './ShippingPlanningService';

export const ShippingOrdersPrintingAppBarView: React.FC = () => {
    const dispatch = useDispatch();
    const query = useSelector(selectShippingQuery);
    const selectedOrdersIds = useSelector(selectShippingSelectedOrders);
    const [updateOrdersStatus, { inProgress }] = useRequest(
        RequestMethod.POST,
        API_URLS.ordersUpdateStatus
    );

    const onChange = useCallback(
        (value: string) => {
            dispatch(updateOrdersToShipQuery({ ...query, search: value }));
        },
        [dispatch, query]
    );

    const handlePrintAndChangeStateClick = () => {
        updateOrdersStatus({
            ids: selectedOrdersIds,
            status: orderStatus.READY_TO_SHIP
        })
            .then(() => {
                window.print();

                dispatch(
                    showNotification({
                        id: `set_orders_ready_to_ship_${Date.now()}`,
                        variant: NotificationVariant.Success,
                        text: i18next.t(
                            'shippingPlanning.setOrdersReadyToShipSuccess'
                        )
                    })
                );
                dispatch(updateSelectedOrdersIds([]));
                dispatch(updateOrdersToShipQuery({ ...query }));
            })
            .catch(err => {
                if (err.isNotificationError) {
                    return;
                }
                dispatch(
                    showNotification({
                        id: `set_orders_ready_to_ship_${Date.now()}`,
                        variant: NotificationVariant.Error,
                        text: i18next.t(
                            'shippingPlanning.setOrdersReadyToShipError'
                        )
                    })
                );
            });
    };

    const handleShippingChange = evt => {
        dispatch(
            updateOrdersToShipQuery({
                ...query,
                shipping: evt.currentTarget.value
            })
        );
    };

    return (
        <>
            <SearchInput
                value={query.search}
                placeholder={i18next.t('Order number, name, email')}
                onChange={onChange}
            />
            <RadioGroup
                row
                style={{ flexWrap: 'nowrap', padding: '0 20px' }}
                value={query.shipping}
                onChange={handleShippingChange}
            >
                <FormControlLabel
                    value={Shipping.PERSONAL_PICKUP}
                    control={<Radio />}
                    label={i18next.t('shippingPlanning.radioPersonalPickup')}
                />
                <FormControlLabel
                    value={Shipping.COURIER_Z}
                    control={<Radio />}
                    label={i18next.t('shippingPlanning.radioCourierZemianska')}
                />
                <FormControlLabel
                    value={Shipping.COURIER_H}
                    control={<Radio />}
                    label={i18next.t('shippingPlanning.radioCourierHamricky')}
                />
                {/* <FormControlLabel
                    value={Shipping.CARTAGE}
                    control={<Radio />}
                    label={i18next.t('shippingPlanning.radioCartage')}
                /> */}
            </RadioGroup>
            <Tooltip
                title={i18next.t('shippingPlanning.printAndChangeState')}
                enterDelay={tooltipEnterDelay}
            >
                <IconButton
                    color="inherit"
                    onClick={handlePrintAndChangeStateClick}
                    disabled={!selectedOrdersIds.length || inProgress}
                >
                    <PrintIcon />
                    <LocalShippingIcon
                        style={{
                            width: '0.7em',
                            marginTop: '10px'
                        }}
                    />
                </IconButton>
            </Tooltip>
        </>
    );
};
