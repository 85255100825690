import { createAction } from 'redux-actions';
import { Enum, EnumItem, Enums } from '../../models/Enum';
import { get } from '../../utils/request';
import { Dispatch } from 'redux';

export function loadEnums(): (dispatch: Dispatch<any>) => Promise<void> {
    return async (dispatch: Dispatch<any>) => {
        dispatch(requestEnums());
        dispatch(receiveEnums(await fetchEnums()));
    };
}

export const requestEnums = createAction('REQUEST_ENUMS');
export const receiveEnums = createAction('RECEIVE_ENUMS', (enums: Enums) => ({
    enums
}));

async function fetchEnums(): Promise<Enums> {
    return {
        orderStatus: await fetchEnum('orderStatus'),
        orderCallStatus: await fetchEnum('orderCallStatus'),
        deliveryType: await fetchEnum('deliveryType'),
        billingType: await fetchEnum('billingType'),
        userRole: await fetchEnum('userRole'),
        productStatus: await fetchEnum('productStatus'),
        shopType: await fetchEnum('shopType'),
        country: await fetchEnum('country'),
        discountType: await fetchEnum('discountType'),
        productGeneralStatus: await fetchEnum('productGeneralStatus')
    };
}

async function fetchEnum(enumName: string): Promise<Enum> {
    return {
        data: await get<EnumItem[]>(`/enum/${enumName}/`)
    };
}
