import { Badge } from '@material-ui/core';
import { FC } from 'react';
import React from 'react';

interface Props {
    isEditing: boolean;
}

export const SaveBadge: FC<Props> = ({ isEditing, children }) => {
    if (isEditing) {
        return (
            <Badge overlap="circle" color="secondary" variant="dot">
                {children}
            </Badge>
        );
    }

    return <>{children}</>;
};
