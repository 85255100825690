import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectSharedProduct } from 'src/common/sharedData/SharedDataSelectors';
import { Grid, Tabs, Tab } from '@material-ui/core';
import i18next from 'i18next';
import { ProductDetails } from './ProductDetails';
import { HistoryView } from 'src/components/history';

const PRODUCT_DETAILS_TAB = 0;
const PRODUCT_HISTORY_TAB = 1;

export const ProductTabs: React.FC = () => {
    const product = useSelector(selectSharedProduct);
    const [tab, setTab] = useState(PRODUCT_DETAILS_TAB);

    const onTabChange = useCallback(
        (event: any, value: number) => {
            setTab(value);
        },
        [setTab]
    );

    if (!product) {
        return null;
    }

    return (
        <>
            <Grid container justify="space-between">
                <Grid item>
                    <h1 style={{ marginTop: '10px', marginBottom: '10px' }}>
                        {i18next.t('productView.title')}
                    </h1>
                </Grid>
                <Grid item style={{ alignSelf: 'center' }}>
                    <a
                        href={product.link}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        {i18next.t('productView.link')}
                    </a>
                </Grid>
            </Grid>
            <Tabs
                value={tab}
                onChange={onTabChange}
                indicatorColor="primary"
                textColor="primary"
            >
                <Tab label={i18next.t('productView.productDetails')} />
                <Tab label={i18next.t('productView.history')} />
            </Tabs>
            {tab === PRODUCT_DETAILS_TAB && <ProductDetails />}
            {tab === PRODUCT_HISTORY_TAB && (
                <HistoryView
                    history={product.history}
                    translationScope="product"
                />
            )}
        </>
    );
};
