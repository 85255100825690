import React, { ChangeEvent } from 'react';
import {
    FormControl,
    InputLabel,
    InputAdornment,
    Input
} from '@material-ui/core';

interface Props {
    name: string;
    label: string;
    value: number;
    unit: string;
    disabled?: boolean;
    onChange(event: ChangeEvent<HTMLInputElement>): void;
}

export const PartValueInput: React.FC<Props> = ({
    name,
    label,
    value,
    unit,
    disabled,
    onChange
}) => (
    <FormControl fullWidth>
        <InputLabel htmlFor={name}>{label}</InputLabel>
        <Input
            id={name}
            type="number"
            value={value}
            name={name}
            disabled={disabled}
            endAdornment={
                <InputAdornment position="end">{unit}</InputAdornment>
            }
            onChange={onChange}
        />
    </FormControl>
);
