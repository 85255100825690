import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from '@material-ui/core';
import i18next from 'i18next';
import React from 'react';
import { formatDateTime } from '../../utils/formatters';
import { hasHistory } from './HistoryService';
import { useHistoryStyles } from './HistoryStyles';
import { HistoryChange } from './HistoryChange';

interface Props {
    history: any;
    translationScope: string;
}

export const HistoryView: React.FC<Props> = ({ history, translationScope }) => {
    const classes = useHistoryStyles();

    if (!hasHistory(history)) {
        return (
            <Typography variant="body1" align="center">
                {i18next.t('history.noEntries')}
            </Typography>
        );
    }

    return (
        <>
            <Table className="table">
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.colField}>
                            {i18next.t('history.field')}
                        </TableCell>
                        <TableCell className={classes.colValue}>
                            {i18next.t('history.oldValue')}
                        </TableCell>
                        <TableCell className={classes.colValue}>
                            {i18next.t('history.newValue')}
                        </TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
            </Table>
            {history.map(({ what, who, when }) => (
                <Table key={when}>
                    <TableBody>
                        <TableRow className={classes.authorDateRow}>
                            <TableCell
                                colSpan={3}
                                className={classes.authorDateCell}
                            >
                                <Typography variant="caption">
                                    {`${who} ${i18next.t(
                                        'history.madeChanges'
                                    )} - ${formatDateTime(when)}`}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        {what.map(change => (
                            <HistoryChange
                                key={change.path.join()}
                                change={change}
                                translationScope={translationScope}
                            />
                        ))}
                    </TableBody>
                </Table>
            ))}
        </>
    );
};
