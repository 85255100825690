import { ProductPart, Product } from 'src/models/Product';
import { RequestMethod } from 'src/utils/request';
import { API_URLS } from 'src/common/ApiService';
import manufacturers from 'src/common/manufacturers';

const emptyProduct = {
    code: '',
    name: {
        SK: '',
        PL: '',
        HU: ''
    },
    manufacturer: manufacturers[0],
    variant: '',
    purchasePrice: {
        EUR: '',
        PL: ''
    },
    discountPrice: {
        HU: '',
        EUR: ''
    },
    sellingPrice: {
        HU: '',
        EUR: ''
    },
    link: '',
    deliveryDate: new Date().toISOString(),
    publishDate: new Date().toISOString(),
    atypicalSize: false,
    atypicalDelivery: false,
    notes: '',
    parts: [
        {
            height: '',
            weight: '',
            width: '',
            depth: ''
        }
    ],
    history: undefined
};

const NEW_PRODUCT_PAGE_ID = 'new';

export function getEmptyProduct(): Product {
    return JSON.parse(JSON.stringify(emptyProduct));
}

export function getProductVolumeAndWeight(
    parts: ProductPart[]
): { volume: number; weight: number } {
    return parts.reduce(
        (acc, part) => ({
            volume: acc.volume + (getPartVolume(part) || 0),
            weight: acc.weight + (Number(part.weight) || 0)
        }),
        { weight: 0, volume: 0 }
    );
}

export function getPartVolume({ height, width, depth }: ProductPart): number {
    return height * width * depth;
}

export function roundToTwoDecimals(value: number): number {
    return Math.round(value * 100) / 100;
}

export function getProductSaveMethod(id?: string): RequestMethod {
    if (id) {
        return RequestMethod.PATCH;
    }

    return RequestMethod.POST;
}

export function getProductSaveUrl(id?: string): string {
    if (id) {
        return `${API_URLS.products}/${id}`;
    }

    return API_URLS.products;
}

export function isCreatingNewProduct(productId: string): boolean {
    return NEW_PRODUCT_PAGE_ID === productId;
}
