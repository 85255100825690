import React, { useCallback } from 'react';
import { TableRow, TableCell, Checkbox } from '@material-ui/core';
import { OrderNumber } from './OrderNumber';
import { formatDateTime } from 'src/utils/formatters';
import i18next from 'i18next';
import { NavLink } from 'react-router-dom';
import { checkOrder } from './OrderListActions';
import { useDispatch } from 'react-redux';

interface Props {
    id: string;
    checked: boolean;
    orderNumber: string;
    deliveryType: string;
    shopName: string;
    dateFrom: string;
    status: string;
    billingName: string;
}

export const OrderRow: React.FC<Props> = ({
    id,
    checked,
    orderNumber,
    deliveryType,
    shopName,
    dateFrom,
    status,
    billingName
}) => {
    const dispatch = useDispatch();

    const onChangeCallback = useCallback(
        (evt: React.ChangeEvent<HTMLInputElement>) => {
            dispatch(checkOrder(id, evt.currentTarget.checked));
        },
        [dispatch, id]
    );

    return (
        <TableRow>
            <TableCell>
                <Checkbox
                    name={id}
                    onChange={onChangeCallback}
                    checked={checked}
                />
            </TableCell>
            <TableCell className="numberOrder">
                <OrderNumber
                    orderNumber={orderNumber}
                    deliveryType={deliveryType}
                />
            </TableCell>
            <TableCell>{shopName}</TableCell>
            <TableCell className="dateFrom">
                {formatDateTime(dateFrom)}
            </TableCell>
            <TableCell className="status">{status}</TableCell>
            <TableCell className="partnerName">{billingName}</TableCell>
            <TableCell>
                <NavLink to={`/order/${id}`}>{i18next.t('Display')}</NavLink>
            </TableCell>
        </TableRow>
    );
};
