import { Tooltip, Select, MenuItem } from '@material-ui/core';
import i18next from 'i18next';
import React from 'react';
import { tooltipEnterDelay } from 'src/config';
import { Order } from 'src/models/Order';
import { Shipping } from 'src/shippingPlanning/ShippingPlanningService';
import { API_URLS } from 'src/common/ApiService';
import { useRequest } from 'src/common/useRequestHook';
import { RequestMethod } from 'src/utils/request';
import { useDispatch } from 'react-redux';
import { showNotification } from 'src/common/notifications/NotificationsActions';
import { NotificationVariant } from 'src/models/Notification';
import { loadOrder } from '../OrderActions';
import { orderStatus } from 'src/common/enums/enums';

interface Props {
    order: Order;
    buttonClassName: string;
}

export const ShippingChangeButtons: React.FC<Props> = ({
    order,
    buttonClassName
}) => {
    const dispatch = useDispatch();
    const [updateOrderShipping, { inProgress }] = useRequest(
        RequestMethod.POST,
        API_URLS.ordersUpdateShipping
    );

    const updateOrderShippingAction = event => {
        const shipping = event.target.value;
        updateOrderShipping({
            ids: [order._id],
            shipping
        })
            .then(() => {
                dispatch(
                    showNotification({
                        id: `update_order_shipping_${Date.now()}`,
                        variant: NotificationVariant.Success,
                        text: i18next.t('order.changeOrderShippingSuccess')
                    })
                );
                dispatch(loadOrder(order._id));
            })
            .catch(() => {
                dispatch(
                    showNotification({
                        id: `update_order_shipping_${Date.now()}`,
                        variant: NotificationVariant.Error,
                        text: i18next.t('order.changeOrderShippingError')
                    })
                );
            });
    };

    if (!order.shipping || order.status !== orderStatus.PROCESSED) {
        return null;
    }

    return (
        <Tooltip
            title={i18next.t('order.changeShipping')}
            enterDelay={tooltipEnterDelay}
        >
            <div className={buttonClassName}>
                <Select
                    disabled={inProgress || !!order.cartageId}
                    value={order.shipping}
                    onChange={updateOrderShippingAction}
                    style={{ color: 'white' }}
                >
                    <MenuItem value={Shipping.CARTAGE}>
                        {i18next.t('order.shippingCartage')}
                    </MenuItem>
                    <MenuItem value={Shipping.COURIER_Z}>
                        {i18next.t('order.shippingCourierZemianska')}
                    </MenuItem>
                    <MenuItem value={Shipping.COURIER_H}>
                        {i18next.t('order.shippingCourierHamricky')}
                    </MenuItem>
                    <MenuItem value={Shipping.PERSONAL_PICKUP}>
                        {i18next.t('order.shippingPersonalPickup')}
                    </MenuItem>
                </Select>
            </div>
        </Tooltip>
    );
};
