import React, { useEffect } from 'react';
import {
    Checkbox,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    Paper
} from '@material-ui/core';
import i18next from 'i18next';
import { API_URLS } from '../common/ApiService';
import { useRequest } from 'src/common/useRequestHook';
import { RequestMethod } from 'src/utils/request';
import {
    OrderToShip,
    Shipping
} from 'src/shippingPlanning/ShippingPlanningService';
import { OrdersToShipList } from 'src/shippingPlanning/OrdersToShipList';
import { useOrderToShipCheckAll } from 'src/shippingPlanning/useOrdersToShipCheckAll';
import { useQuery } from 'src/common/useQuery';

export const CartagePlanningOrdersWithoutGroupView: React.FC = () => {
    const query = useQuery();
    const nZipCodes = query.get('zipCodes').split(';');

    const [
        fetchCartageGroupOrders,
        { response: orders, hasSucceed: cartageGroupOrdersFetchingHasSucceed }
    ] = useRequest<OrderToShip[]>(RequestMethod.POST, API_URLS.ordersToShip);

    const {
        isCheckAllChecked,
        isCheckAllIndeterminate,
        handleCheckChange
    } = useOrderToShipCheckAll(orders);

    useEffect(() => {
        fetchCartageGroupOrders({
            shipping: Shipping.CARTAGE,
            country: query.get('country'),
            nZipCodes
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <TableContainer component={Paper}>
            <Table className="table">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Checkbox
                                checked={isCheckAllChecked}
                                indeterminate={isCheckAllIndeterminate}
                                onChange={handleCheckChange}
                            />
                        </TableCell>
                        <TableCell>
                            {i18next.t('shippingPlanning.orderNumber')}
                        </TableCell>
                        <TableCell>
                            {i18next.t('shippingPlanning.expectedShippingDate')}
                        </TableCell>
                        <TableCell>
                            {i18next.t('shippingPlanning.address')}
                        </TableCell>
                        <TableCell>
                            {i18next.t('shippingPlanning.items')}
                        </TableCell>
                        <TableCell>
                            {i18next.t('shippingPlanning.note')}
                        </TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <OrdersToShipList
                    isLoading={!cartageGroupOrdersFetchingHasSucceed}
                    orders={orders}
                    hasMore={false}
                    hideDeliveryType
                    showExpectedShippingDate
                />
            </Table>
        </TableContainer>
    );
};
