import React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { EnumsState } from '../common/enums/EnumsState';
import { AppState } from '../models/AppState';
import { Shop } from '../models/Shop';
import {
    loadShops,
    removeShop,
    saveShop,
    setShop,
    showShopDialog,
    updateShop
} from './ShopListActions';
import { defaultShop } from './ShopListService';
import { ShopListView } from './ShopListView';

interface Props {
    isLoading: boolean;
    shops: Shop[];
    enums: EnumsState;
    showShopDialog: boolean;
    shop: Shop;
    onLoadShops: () => void;
    onShowShopDialog: (value: boolean) => void;
    onShopSave: (shop: Shop) => Promise<void>;
    onSetShop: (shop: Shop) => void;
    onRemoveShop: (id: string) => Promise<void>;
    onShopChange: (propName: string, propValue: string) => void;
}

export class ShopListContainerBase extends Component<Props> {
    constructor(props: Props) {
        super(props);

        if (props.shops.length === 0) {
            props.onLoadShops();
        }
    }

    render() {
        const {
            isLoading,
            shops,
            enums,
            showShopDialog,
            shop,
            onShopChange
        } = this.props;
        return (
            <ShopListView
                isLoading={isLoading}
                shops={shops}
                enums={enums}
                showShopDialog={showShopDialog}
                shop={shop}
                onAddNewShopClick={this.onAddNewShopClick}
                onShopCancel={this.onShopCancel}
                onShopSubmit={this.onShopSubmit}
                onShopChange={onShopChange}
                onRemoveShopClick={this.onRemoveShopClick}
                onEditShopClick={this.onEditShopClick}
            />
        );
    }

    onAddNewShopClick = () => {
        this.props.onShowShopDialog(true);
        this.props.onSetShop(defaultShop);
    };

    onShopCancel = () => {
        this.props.onShowShopDialog(false);
    };

    onShopSubmit = async () => {
        await this.props.onShopSave(this.props.shop);
        this.props.onLoadShops();
        this.props.onShowShopDialog(false);
    };

    onRemoveShopClick = (id: string) => {
        this.props.onRemoveShop(id);
    };

    onEditShopClick = (shop: Shop) => {
        this.props.onShowShopDialog(true);
        this.props.onSetShop(shop);
    };
}

const mapStateToProps = (state: AppState) => ({
    isLoading: state.shopList.isLoading,
    shops: state.shopList.shops,
    enums: state.enums,
    showShopDialog: state.shopList.showShopDialog,
    shop: state.shopList.shop
});

const mapDispatchToProps = {
    onLoadShops: loadShops,
    onShowShopDialog: showShopDialog,
    onShopChange: updateShop,
    onShopSave: saveShop,
    onSetShop: setShop,
    onRemoveShop: removeShop
};

const ShopListContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ShopListContainerBase);

export { ShopListContainer };
