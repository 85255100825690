import { Button } from '@material-ui/core';
import i18next from 'i18next';
import React, { useState } from 'react';
import { FC } from 'react';
import { User } from 'src/models/User';
import { ChangePasswordDialog } from './ChangePasswordDialog';
import { useRequest } from 'src/common/useRequestHook';
import { RequestMethod } from 'src/utils/request';

interface Props {
    users: User[];
    className: string;
}

export const ChangeUserPasswordButton: FC<Props> = ({ users, className }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [username, setUsername] = useState(users[0].username);
    const [password, setPassword] = useState('');

    const [changePassword] = useRequest(
        RequestMethod.POST,
        '/admin/reset-password'
    );

    const submit = async () => {
        await changePassword({ username, password });
        setIsOpen(false);
        setPassword('');
    };

    return (
        <>
            <ChangePasswordDialog
                open={isOpen}
                users={users}
                username={username}
                password={password}
                onUsernameChange={setUsername}
                onPasswordChange={setPassword}
                onCancel={() => setIsOpen(false)}
                onSubmit={submit}
            />
            <Button
                variant="contained"
                color="default"
                className={className}
                onClick={() => setIsOpen(true)}
            >
                {i18next.t('Change user password')}
            </Button>
        </>
    );
};
