export const menuWidth = 240;

export const tooltipEnterDelay = 300;

export const SEARCH_DELAY = 300;

export const MOMENT_BUSINESS_DAYS_CONFIG = {
    holidays: [
        '01-01',
        '06-01',
        '07-04',
        '10-04',
        '01-05',
        '08-05',
        '05-07',
        '29-08',
        '01-09',
        '15-09',
        '01-11',
        '17-11',
        '24-12',
        '25-12',
        '26-12'
    ],
    holidayFormat: 'DD-MM'
};
