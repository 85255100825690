import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { SearchInput } from 'src/components/SerchInput';
import i18next from 'i18next';
import {
    selectShippingQuery,
    selectShippingSelectedOrders
} from './ShippingPlanningSelectors';
import {
    updateOrdersToShipQuery,
    updateSelectedOrdersIds
} from './ShippingPlanningActions';
import { Tooltip, Button } from '@material-ui/core';
import { tooltipEnterDelay } from 'src/config';
import AddIcon from '@material-ui/icons/PlaylistAdd';

import { RequestMethod } from 'src/utils/request';
import { useRequest } from 'src/common/useRequestHook';
import { API_URLS } from 'src/common/ApiService';
import { NotificationVariant } from 'src/models/Notification';
import { showNotification } from 'src/common/notifications/NotificationsActions';
import {
    getUpdateShippingErrorText,
    getUpdateShippingSuccessText,
    Shipping
} from './ShippingPlanningService';

export const ShippingPlanningAppBarView: React.FC = () => {
    const dispatch = useDispatch();
    const query = useSelector(selectShippingQuery);
    const selectedOrdersIds = useSelector(selectShippingSelectedOrders);
    const [updateOrdersShipping, { inProgress }] = useRequest(
        RequestMethod.POST,
        API_URLS.ordersUpdateShipping
    );

    const onChange = (value: string) => {
        dispatch(updateOrdersToShipQuery({ ...query, search: value }));
    };

    const updateOrdersShippingAction = (shipping: Shipping) => {
        updateOrdersShipping({
            ids: selectedOrdersIds,
            shipping
        })
            .then(() => {
                dispatch(
                    showNotification({
                        id: `update_orders_shipping_${Date.now()}`,
                        variant: NotificationVariant.Success,
                        text: getUpdateShippingSuccessText(shipping)
                    })
                );
                dispatch(updateSelectedOrdersIds([]));
                dispatch(updateOrdersToShipQuery({ ...query }));
            })
            .catch(() => {
                dispatch(
                    showNotification({
                        id: `update_orders_shipping_${Date.now()}`,
                        variant: NotificationVariant.Error,
                        text: getUpdateShippingErrorText(shipping)
                    })
                );
            });
    };

    return (
        <>
            <SearchInput
                value={query.search}
                placeholder={i18next.t('Order number, name, email')}
                onChange={onChange}
            />
            <Tooltip
                title={i18next.t(
                    'shippingPlanning.sendViaCourierZemianskaTooltip'
                )}
                enterDelay={tooltipEnterDelay}
            >
                <div>
                    <Button
                        color="inherit"
                        onClick={() =>
                            updateOrdersShippingAction(Shipping.COURIER_Z)
                        }
                        disabled={!selectedOrdersIds.length || inProgress}
                    >
                        <AddIcon />
                        {i18next.t('shippingPlanning.sendViaCourierZemianska')}
                    </Button>
                </div>
            </Tooltip>
            <Tooltip
                title={i18next.t(
                    'shippingPlanning.sendViaCourierHamrickyTooltip'
                )}
                enterDelay={tooltipEnterDelay}
            >
                <div>
                    <Button
                        color="inherit"
                        onClick={() =>
                            updateOrdersShippingAction(Shipping.COURIER_H)
                        }
                        disabled={!selectedOrdersIds.length || inProgress}
                    >
                        <AddIcon />
                        {i18next.t('shippingPlanning.sendViaCourierHamricky')}
                    </Button>
                </div>
            </Tooltip>
            <Tooltip
                title={i18next.t('shippingPlanning.sendViaCarTooltip')}
                enterDelay={tooltipEnterDelay}
            >
                <div>
                    <Button
                        color="inherit"
                        onClick={() =>
                            updateOrdersShippingAction(Shipping.CARTAGE)
                        }
                        disabled={!selectedOrdersIds.length || inProgress}
                    >
                        <AddIcon />
                        {i18next.t('shippingPlanning.sendViaCar')}
                    </Button>
                </div>
            </Tooltip>
        </>
    );
};
