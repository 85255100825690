import React, { ChangeEvent } from 'react';
import { ProductPrice, ProductPurchasePrice } from 'src/models/Product';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    Grid,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails
} from '@material-ui/core';
import { CurrencySymbol } from 'src/utils/price';
import i18next from 'i18next';
import { AmountInput } from 'src/components/AmountInput';

interface Props {
    purchasePrice: ProductPurchasePrice;
    discountPrice: ProductPrice;
    sellingPrice: ProductPrice;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const ProductPrices: React.FC<Props> = ({
    purchasePrice,
    discountPrice,
    sellingPrice,
    onChange
}) => (
    <ExpansionPanel defaultExpanded>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            {i18next.t('product.prices')}
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
            <Grid container spacing={3}>
                <Grid container item xs={12} md={4}>
                    <Grid item xs={12}>
                        <AmountInput
                            name="purchasePrice.EUR"
                            label={i18next.t('product.purchasePriceEUR')}
                            value={purchasePrice.EUR}
                            currency={CurrencySymbol.Eur}
                            onChange={onChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AmountInput
                            name="purchasePrice.PL"
                            label={i18next.t('product.purchasePricePL')}
                            value={purchasePrice.PL}
                            currency={CurrencySymbol.Pln}
                            onChange={onChange}
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={12} md={4}>
                    <Grid item xs={12}>
                        <AmountInput
                            name="discountPrice.EUR"
                            label={i18next.t('product.discountPriceEUR')}
                            value={discountPrice.EUR}
                            currency={CurrencySymbol.Eur}
                            onChange={onChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AmountInput
                            name="discountPrice.HU"
                            label={i18next.t('product.discountPriceHU')}
                            value={discountPrice.HU}
                            currency={CurrencySymbol.Huf}
                            onChange={onChange}
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={12} md={4}>
                    <Grid item xs={12}>
                        <AmountInput
                            name="sellingPrice.EUR"
                            label={i18next.t('product.sellingPriceEUR')}
                            value={sellingPrice.EUR}
                            currency={CurrencySymbol.Eur}
                            onChange={onChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AmountInput
                            name="sellingPrice.HU"
                            label={i18next.t('product.sellingPriceHU')}
                            value={sellingPrice.HU}
                            currency={CurrencySymbol.Huf}
                            onChange={onChange}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </ExpansionPanelDetails>
    </ExpansionPanel>
);
