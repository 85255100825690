import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback } from 'react';
import i18next from 'i18next';
import { Tooltip, IconButton } from '@material-ui/core';
import { tooltipEnterDelay } from 'src/config';
import SaveIcon from '@material-ui/icons/Save';
import RemoveIcon from '@material-ui/icons/Remove';
import PrintIcon from '@material-ui/icons/Print';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import SmsIcon from '@material-ui/icons/Sms';
import CopyIcon from '@material-ui/icons/FileCopy';
import CheckIcon from '@material-ui/icons/Check';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';

import { RequestMethod } from 'src/utils/request';
import { useRequest } from 'src/common/useRequestHook';
import { API_URLS } from 'src/common/ApiService';
import { NotificationVariant } from 'src/models/Notification';
import { showNotification } from 'src/common/notifications/NotificationsActions';
import { useSharedData } from 'src/common/sharedData/useSharedData';
import { SHARED_DATA_KEY } from 'src/common/sharedData/SharedDataActions';
import { Cartage, CartageStatus } from 'src/models/Cartage';
import { selectShippingSelectedOrders } from 'src/shippingPlanning/ShippingPlanningSelectors';
import { updateSelectedOrdersIds } from 'src/shippingPlanning/ShippingPlanningActions';
import { useConfirmationDialog } from 'src/common/confirmationDialog/useConfirmationDialog';
import { AdvancedUserRole } from 'src/components/AdvancedUserRole';
import { CartageSharedData } from './CartageService';
import { useOversizedProductCodes } from 'src/products-info/useOversizedProductCodes';

export const CartageAppBarView: React.FC = () => {
    const confirm = useConfirmationDialog();
    const dispatch = useDispatch();
    const {
        inProgress: isFetchingOversizedProductInfo
    } = useOversizedProductCodes();
    const [
        { cartage, isEditing, fetchCartageOrders },
        setCartageSharedData
    ] = useSharedData<CartageSharedData>(SHARED_DATA_KEY.cartage);
    const setCartage = useCallback(
        (cartage: Cartage): void => {
            setCartageSharedData({
                cartage,
                isEditing: false,
                fetchCartageOrders
            });
        },
        [fetchCartageOrders, setCartageSharedData]
    );

    const selectedOrdersIds = useSelector(selectShippingSelectedOrders);

    const [updateCartage, { inProgress: isSaveInProgress }] = useRequest<
        Cartage
    >(RequestMethod.PATCH, API_URLS.cartages + '/' + cartage?._id);

    const [
        setAsReadyToShip,
        { inProgress: isSettingAsReadyToShip }
    ] = useRequest<Cartage>(
        RequestMethod.POST,
        API_URLS.cartages + '/' + cartage?._id + '/set-ready-to-ship'
    );

    const [
        setItemsAsReadyToShip,
        { inProgress: isSettingItemsAsReadyToShip }
    ] = useRequest<Cartage>(
        RequestMethod.POST,
        API_URLS.setOrderItemsAsReadyToShip
    );

    const [setAsDone, { inProgress: isSettingAsDone }] = useRequest<Cartage>(
        RequestMethod.POST,
        API_URLS.cartages + '/' + cartage?._id + '/set-done'
    );

    const [
        removeOrdersFromCartage,
        { inProgress: isRemovingInProgress }
    ] = useRequest<Cartage>(
        RequestMethod.POST,
        API_URLS.cartages + '/' + cartage?._id + '/remove-orders'
    );

    const [sendSms, { inProgress: isSendingSms }] = useRequest<Cartage>(
        RequestMethod.POST,
        API_URLS.cartages + '/' + cartage?._id + '/send-sms'
    );

    const [
        createRegularInvoices,
        { inProgress: isCreatingRegularInvoices }
    ] = useRequest<Cartage>(
        RequestMethod.POST,
        API_URLS.createRegularInvoices,
        undefined,
        { showErrorDialog: false }
    );

    const handleSaveCartage = () => {
        updateCartage(cartage)
            .then(updatedCartage => {
                setCartage(updatedCartage);
                dispatch(
                    showNotification({
                        id: `save_cartage_${Date.now()}`,
                        variant: NotificationVariant.Success,
                        text: i18next.t(
                            'cartageShippingPlanning.saveCartageSuccess'
                        )
                    })
                );
            })
            .catch(err => {
                if (err.isNotificationError) {
                    return;
                }
                dispatch(
                    showNotification({
                        id: `save_cartage_${Date.now()}`,
                        variant: NotificationVariant.Error,
                        text: i18next.t(
                            'cartageShippingPlanning.saveCartageError'
                        )
                    })
                );
            });
    };

    const handleRemoveOrdersFromCartage = async () => {
        const isConfirmed = await confirm(
            i18next.t('cartageShippingPlanning.removeOrdersConfirmation')
        );
        if (!isConfirmed) {
            return;
        }

        removeOrdersFromCartage({
            _id: cartage._id,
            version: cartage.version,
            ordersIdsToRemove: selectedOrdersIds
        })
            .then(updatedCartage => {
                fetchCartageOrders();
                setCartage(updatedCartage);
                dispatch(
                    showNotification({
                        id: `save_cartage_${Date.now()}`,
                        variant: NotificationVariant.Success,
                        text: i18next.t(
                            'cartageShippingPlanning.removeOrdersSuccess'
                        )
                    })
                );
                dispatch(updateSelectedOrdersIds([]));
            })
            .catch(err => {
                if (err.isNotificationError) {
                    return;
                }
                dispatch(
                    showNotification({
                        id: `save_cartage_${Date.now()}`,
                        variant: NotificationVariant.Error,
                        text: i18next.t(
                            'cartageShippingPlanning.removeOrdersError'
                        )
                    })
                );
            });
    };

    const handleSetItemsAsReadyToShip = async () => {
        setItemsAsReadyToShip({
            ordersIds: selectedOrdersIds
        })
            .then(() => {
                dispatch(
                    showNotification({
                        id: `set_items_${Date.now()}`,
                        variant: NotificationVariant.Success,
                        text: i18next.t(
                            'cartageShippingPlanning.setItemsAsReadyToShipSuccess'
                        )
                    })
                );
                dispatch(updateSelectedOrdersIds([]));
            })
            .catch(err => {
                if (err.isNotificationError) {
                    return;
                }
                dispatch(
                    showNotification({
                        id: `set_items_${Date.now()}`,
                        variant: NotificationVariant.Error,
                        text: i18next.t(
                            'cartageShippingPlanning.setItemsAsReadyToShipError'
                        )
                    })
                );
            })
            .finally(() => {
                fetchCartageOrders();
            });
    };

    const handleSetCartageAsReadyToShip = async () => {
        const isConfirmed = await confirm(
            i18next.t('cartageShippingPlanning.readyToShipConfirmation')
        );
        if (!isConfirmed) {
            return;
        }

        setAsReadyToShip({
            _id: cartage._id,
            version: cartage.version
        })
            .then(updatedCartage => {
                setCartage(updatedCartage);
                dispatch(
                    showNotification({
                        id: `ready_to_ship_cartage_${Date.now()}`,
                        variant: NotificationVariant.Success,
                        text: i18next.t(
                            'cartageShippingPlanning.setCartageReadyToShipSuccess'
                        )
                    })
                );
            })
            .catch(err => {
                if (err.isNotificationError) {
                    return;
                }
                dispatch(
                    showNotification({
                        id: `ready_to_ship_cartage_${Date.now()}`,
                        variant: NotificationVariant.Error,
                        text: i18next.t(
                            'cartageShippingPlanning.setCartageReadyToShipError'
                        )
                    })
                );
            });
    };

    const handleSetCartageAsDone = async () => {
        const isConfirmed = await confirm(
            i18next.t('cartageShippingPlanning.setAsDoneConfirmation')
        );
        if (!isConfirmed) {
            return;
        }

        setAsDone({
            _id: cartage._id,
            version: cartage.version
        })
            .then(updatedCartage => {
                setCartage(updatedCartage);
                dispatch(
                    showNotification({
                        id: `set-cartage-as-done${Date.now()}`,
                        variant: NotificationVariant.Success,
                        text: i18next.t(
                            'cartageShippingPlanning.setCartageDoneSuccess'
                        )
                    })
                );
            })
            .catch(err => {
                if (err.isNotificationError) {
                    return;
                }
                dispatch(
                    showNotification({
                        id: `set-cartage-as-done${Date.now()}`,
                        variant: NotificationVariant.Error,
                        text: i18next.t(
                            'cartageShippingPlanning.setCartageDoneError'
                        )
                    })
                );
            });
    };

    const handleSendSms = async () => {
        const isConfirmed = await confirm(
            i18next.t('cartageShippingPlanning.sendSmsConfirmation')
        );
        if (!isConfirmed) {
            return;
        }

        sendSms({
            _id: cartage._id,
            version: cartage.version,
            orderIds: selectedOrdersIds
        })
            .then(updatedCartage => {
                setCartage(updatedCartage);
                dispatch(
                    showNotification({
                        id: `send_sms_${Date.now()}`,
                        variant: NotificationVariant.Info,
                        text: i18next.t(
                            'cartageShippingPlanning.smsSentSuccess'
                        )
                    })
                );
                dispatch(updateSelectedOrdersIds([]));
            })
            .catch(err => {
                if (err.isNotificationError) {
                    return;
                }
                dispatch(
                    showNotification({
                        id: `send_sms_${Date.now()}`,
                        variant: NotificationVariant.Error,
                        text: i18next.t('cartageShippingPlanning.smsSentError')
                    })
                );
            });
    };

    const handleCreateRegularInvoices = async () => {
        const isConfirmed = await confirm(
            i18next.t(
                'cartageShippingPlanning.createRegularInvoicesConfirmation'
            )
        );
        if (!isConfirmed) {
            return;
        }

        createRegularInvoices({
            orderIds: selectedOrdersIds
        })
            .then(() => {
                dispatch(
                    showNotification({
                        id: `create_regular_invoices_${Date.now()}`,
                        variant: NotificationVariant.Success,
                        text: i18next.t(
                            'cartageShippingPlanning.createRegularInvoicesSuccess'
                        )
                    })
                );
                dispatch(updateSelectedOrdersIds([]));
            })
            .catch(err => {
                if (err.isNotificationError) {
                    return;
                }
                dispatch(
                    showNotification({
                        id: `create_regular_invoices_${Date.now()}`,
                        variant: NotificationVariant.Error,
                        text: i18next.t(
                            'cartageShippingPlanning.createRegularInvoicesError'
                        )
                    })
                );
            })
            .finally(() => {
                fetchCartageOrders();
            });
    };

    const handleCopyCartageName = () => {
        // does not works due to missing https
        // navigator.clipboard.writeText(cartage.name.replace(/\s/g, ''));
        // text area method
        const textArea = document.createElement('textarea');
        textArea.value = cartage.name.replace(/\s/g, '');
        // make the textarea out of viewport
        textArea.style.position = 'fixed';
        textArea.style.left = '-999999px';
        textArea.style.top = '-999999px';
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        window.document.execCommand('copy');
        textArea.remove();
    };

    const isCartageDone = cartage?.status === CartageStatus.DONE;
    const isCartageReadyToShip =
        cartage?.status === CartageStatus.READY_TO_SHIP;

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                overflowY: 'auto'
            }}
        >
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <h2>{cartage?.name}</h2>
                <Tooltip
                    title={i18next.t('cartageShippingPlanning.copyCartageName')}
                    enterDelay={tooltipEnterDelay}
                >
                    <IconButton color="inherit" onClick={handleCopyCartageName}>
                        <CopyIcon />
                    </IconButton>
                </Tooltip>
            </div>
            <AdvancedUserRole>
                <div style={{ display: 'flex' }}>
                    <Tooltip
                        title={i18next.t(
                            'cartageShippingPlanning.removeOrdersFromCartage'
                        )}
                        enterDelay={tooltipEnterDelay}
                    >
                        <IconButton
                            color="inherit"
                            onClick={handleRemoveOrdersFromCartage}
                            disabled={
                                isEditing ||
                                selectedOrdersIds.length === 0 ||
                                isRemovingInProgress ||
                                isCartageDone
                            }
                        >
                            <RemoveIcon />
                        </IconButton>
                    </Tooltip>
                    {cartage?.status === CartageStatus.INITIAL && (
                        <Tooltip
                            title={i18next.t(
                                'cartageShippingPlanning.readyToShip'
                            )}
                            enterDelay={tooltipEnterDelay}
                        >
                            <IconButton
                                color="inherit"
                                onClick={handleSetCartageAsReadyToShip}
                                disabled={isEditing || isSettingAsReadyToShip}
                            >
                                <LocalShippingIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    {isCartageReadyToShip && (
                        <Tooltip
                            title={i18next.t(
                                'cartageShippingPlanning.setAsDone'
                            )}
                            enterDelay={tooltipEnterDelay}
                        >
                            <IconButton
                                color="inherit"
                                onClick={handleSetCartageAsDone}
                                disabled={isEditing || isSettingAsDone}
                            >
                                <DoneAllIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    {isCartageReadyToShip && (
                        <Tooltip
                            title={i18next.t('cartageShippingPlanning.sendSms')}
                            enterDelay={tooltipEnterDelay}
                        >
                            <IconButton
                                color="inherit"
                                onClick={handleSendSms}
                                disabled={
                                    isEditing ||
                                    selectedOrdersIds.length === 0 ||
                                    isSendingSms
                                }
                            >
                                <SmsIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    {isCartageReadyToShip && (
                        <Tooltip
                            title={i18next.t(
                                'cartageShippingPlanning.createRegularInvoices'
                            )}
                            enterDelay={tooltipEnterDelay}
                        >
                            <IconButton
                                color="inherit"
                                disabled={
                                    isEditing ||
                                    selectedOrdersIds.length === 0 ||
                                    isCreatingRegularInvoices
                                }
                                onClick={handleCreateRegularInvoices}
                            >
                                <CheckIcon />
                                <EuroSymbolIcon
                                    style={{
                                        position: 'absolute',
                                        top: '6px',
                                        left: '6px',
                                        fontSize: '12px'
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                    )}
                    {isCartageReadyToShip && (
                        <Tooltip
                            title={i18next.t(
                                'cartageShippingPlanning.setItemsAsReadyToShip'
                            )}
                            enterDelay={tooltipEnterDelay}
                        >
                            <IconButton
                                color="inherit"
                                disabled={
                                    isEditing ||
                                    selectedOrdersIds.length === 0 ||
                                    isSettingItemsAsReadyToShip
                                }
                                onClick={handleSetItemsAsReadyToShip}
                            >
                                <LocalShippingIcon />
                                <CheckIcon
                                    style={{
                                        position: 'absolute',
                                        top: '6px',
                                        left: '6px',
                                        fontSize: '12px'
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                    )}
                    <Tooltip
                        title={i18next.t(
                            'cartageShippingPlanning.printOrdersAndDrivingSheet'
                        )}
                        enterDelay={tooltipEnterDelay}
                    >
                        <IconButton
                            color="inherit"
                            disabled={
                                isEditing || isFetchingOversizedProductInfo
                            }
                            onClick={() => window.print()}
                        >
                            <PrintIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        title={i18next.t(
                            'cartageShippingPlanning.saveCartageTooltip'
                        )}
                        enterDelay={tooltipEnterDelay}
                    >
                        <IconButton
                            color="inherit"
                            onClick={handleSaveCartage}
                            disabled={isSaveInProgress || isCartageDone}
                        >
                            <SaveIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            </AdvancedUserRole>
        </div>
    );
};
