import React, { useEffect } from 'react';
import i18next from 'i18next';
import { LinearProgress, Typography } from '@material-ui/core';
import { onOrderScan } from 'src/common/scannerService';
import { useDispatch } from 'react-redux';
import { updateOrdersStatus } from 'src/orderList/OrderListActions';
import { orderStatus } from 'src/common/enums/enums';
import { ScannedOrders } from '../ScannedOrders';
import { get } from 'src/utils/request';
import { API_URLS } from 'src/common/ApiService';
import {
    useScannedOrdersReducer,
    ScannedOrderState
} from '../useScannedOrdersReducer';

export const MarkAsDoneView: React.FC = () => {
    const dispatchRedux = useDispatch();
    const [state, dispatch] = useScannedOrdersReducer();

    useEffect(() => {
        const removeListener = onOrderScan(async orderNumber => {
            if (state.orders[orderNumber]) {
                return;
            }
            dispatch({ state: ScannedOrderState.SCANNED, orderNumber });

            try {
                const { id } = await get<{ id: string }>(
                    `${API_URLS.getOrderId}${orderNumber}`
                );

                await dispatchRedux(updateOrdersStatus([id], orderStatus.DONE));
                dispatch({ state: ScannedOrderState.SUCCEED, orderNumber });
            } catch {
                dispatch({ state: ScannedOrderState.FAILED, orderNumber });
            }
        });
        return removeListener;
    }, [dispatchRedux, dispatch, state.orders]);

    return (
        <>
            <h1>{i18next.t('markAsDone.title')}</h1>
            <Typography align="center">
                {i18next.t('markAsDone.description')}
            </Typography>
            <LinearProgress />
            <h2>{i18next.t('markAsDone.scannedOrdersTitle')}</h2>

            <ScannedOrders
                orders={Object.values(state.orders).sort(
                    (order1, order2) => order2.date - order1.date
                )}
            />
        </>
    );
};
