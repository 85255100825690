import React from 'react';
import { ChangeEvent, Component, FormEvent } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { login } from '../common/session/SessionActions';
import { isAuthenticated } from '../common/session/SessionService';
import { SessionState } from '../common/session/SessionState';
import { AppState } from '../models/AppState';
import { User } from '../models/User';
import { updateUser } from './LoginActions';
import { LoginView } from './LoginView';

interface Props extends RouteComponentProps<any> {
    user: User;
    session: SessionState;
    onLogin: (user: User) => void;
    onUpdateUser: (propName: string, propValue: string) => void;
}

export class LoginContainerBase extends Component<Props> {
    render() {
        const { user, session } = this.props;
        const { from } = (this.props.location.state as any) || {
            from: { pathname: '/' }
        };

        if (isAuthenticated(session)) {
            return <Redirect to={from} />;
        }

        return (
            <LoginView
                onLoginSubmit={this.onLoginSubmit}
                onUserChange={this.onUserChange}
                user={user}
            />
        );
    }

    onLoginSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.props.onLogin(this.props.user);
    };

    onUserChange = (event: ChangeEvent<HTMLInputElement>) => {
        this.props.onUpdateUser(
            event.currentTarget.name,
            event.currentTarget.value
        );
    };
}

const mapStateToProps = (state: AppState) => ({
    user: state.login.user,
    session: state.session
});

const mapDispatchToProps = {
    onLogin: login,
    onUpdateUser: updateUser
};

const LoginContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginContainerBase);

export { LoginContainer };
