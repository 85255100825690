import { makeStyles } from '@material-ui/core';

export const useHistoryStyles = makeStyles(theme => ({
    colField: {
        width: '30%'
    },
    colValue: {
        width: '35%'
    },
    authorDateRow: {
        height: 'auto'
    },
    authorDateCell: {
        border: 0,
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1)
    },
    changeRow: {
        '&:not(:last-child) td': {
            border: 0
        }
    }
}));
