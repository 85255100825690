import i18next from 'i18next';
import React, { useEffect } from 'react';
import { Message } from 'src/models/Cartage';
import { Alert } from '@material-ui/lab';
import { useRequest } from 'src/common/useRequestHook';
import { DeliveryConfirmation } from 'src/models/DeliveryConfirmation';
import { RequestMethod } from 'src/utils/request';
import { API_URLS } from 'src/common/ApiService';
import { CircularProgress } from '@material-ui/core';
import { getAlertTextAndSeverity } from './SmsMessageService';

interface Props {
    message?: Message;
}

export const SmsMessage: React.FC<Props> = ({ message }) => {
    const [
        fetchSmsDeliveryConfirmation,
        {
            response: deliveryConfirmation,
            hasFailed: hasDeliveryConfirmationFetchingFailed,
            inProgress: isFetchingDeliveryConfirmation
        }
    ] = useRequest<DeliveryConfirmation>(
        RequestMethod.GET,
        API_URLS.smsDeliveryConfirmation + '/' + message?.smsId,
        undefined,
        { showErrorDialog: false, apiPort: '1338' }
    );

    useEffect(() => {
        if (!message?.smsId) {
            return;
        }

        fetchSmsDeliveryConfirmation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message?.smsId]);

    if (!message) {
        return (
            <Alert severity="warning">
                {i18next.t('cartageShippingPlanning.noSmsSend')}
            </Alert>
        );
    }

    if (message.error) {
        return (
            <Alert severity="error">
                {i18next.t(`cartageShippingPlanning.error.${message.error}`)}
            </Alert>
        );
    }

    if (
        isFetchingDeliveryConfirmation ||
        (!deliveryConfirmation && !hasDeliveryConfirmationFetchingFailed)
    ) {
        return <CircularProgress size={20} />;
    }

    const { severity, text } = getAlertTextAndSeverity(
        deliveryConfirmation?.status,
        hasDeliveryConfirmationFetchingFailed
    );
    return <Alert severity={severity}>{text}</Alert>;
};
