import { Typography } from '@material-ui/core';
import React from 'react';
import { ProductItem } from 'src/models/Order';
import { formatWeight } from 'src/utils/formatters';

interface Props {
    item: ProductItem;
    showWeight: boolean;
}

const getSupplierWithProductCode = ({
    supplier,
    productCode
}: ProductItem): string => {
    if (!supplier) {
        return productCode;
    }

    return `${supplier} - ${productCode}`;
};

export const OrderToShipProduct: React.FC<Props> = ({ item, showWeight }) => {
    return (
        <>
            {item.quantity > 1 && (
                <Typography
                    color="secondary"
                    display="inline"
                >{`${item.quantity} x `}</Typography>
            )}

            <Typography display="inline">
                {getSupplierWithProductCode(item)}
            </Typography>
            {showWeight && (
                <Typography display="inline" variant="body2">{`, ${formatWeight(
                    item.weight
                )}`}</Typography>
            )}
            <Typography display="block" variant="caption">
                {item.name}
            </Typography>
            <Typography display="block" variant="caption" color="textSecondary">
                {item.variant}
            </Typography>
        </>
    );
};
