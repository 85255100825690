import {
    CircularProgress,
    Link,
    Tab,
    Tabs,
    Typography
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import i18next from 'i18next';
import { Component } from 'react';
import React from 'react';
import { EnumsState } from '../common/enums/EnumsState';
import { Enum } from '../models/Enum';
import { Order, ProductItem } from '../models/Order';
import { CurrencySymbol } from '../utils/price';
import { OrderDetailsView } from './OrderDetailsView';
import { userRole as userRoleValues } from 'src/common/enums/enums';
import {
    getShopOrderDetailLink,
    getNoInvoiceText,
    getInvoiceLink,
    getOrderTitle
} from './OrderService';
import { HistoryView } from 'src/components/history';
import { CourierPackagesLink } from './CourierPackagesLink';
import { generatePath, NavLink, Prompt } from 'react-router-dom';
import { appPaths } from 'src/AppRouterService';
import { useOversizedProductCodes } from 'src/products-info/useOversizedProductCodes';
import { containsOrderOversizedItem } from 'src/cartage/CartageService';
import { OrderPrintView } from './OrderPrintView';
import { OversizedItemIndicator } from './OversizedItemIndicator';
import { SameCustomerOpenOrdersLink } from './SameCustomerOpenOrdersLink';
import { AdvancedUserRole } from 'src/components/AdvancedUserRole';

const ORDER_DETAILS_TAB_INDEX = 0;
const ORDER_HISTORY_TAB_INDEX = 1;

interface Props {
    enums: EnumsState;
    validProductStatusEnum: Enum;
    order?: Order;
    isLoading: boolean;
    isEditing: boolean;
    currency: CurrencySymbol;
    userRole: string;
    onAddItem: () => void;
    onAddSubItem: (item: ProductItem) => void;
    onRemoveSubItem: (item: ProductItem, subItem: ProductItem) => void;
    onRemoveItem: (productItem: ProductItem) => void;
    onOrderChange: (propertyName: string, value: any) => void;
}

interface Status {
    tabIndex: number;
}

interface BaseProps extends Props {
    oversizedProductCodes: string[] | undefined;
}
class OrderViewBase extends Component<BaseProps, Status> {
    constructor(props: BaseProps) {
        super(props);

        this.state = {
            tabIndex: 0
        };
    }

    static renderLoader() {
        return (
            <Grid container justify="center">
                <Grid item xs={1}>
                    <CircularProgress size={50} />
                </Grid>
            </Grid>
        );
    }

    onTabChange = (_evt, tabIndex) => {
        this.setState({ tabIndex });
    };

    renderOrderDetails() {
        const {
            order,
            enums,
            validProductStatusEnum,
            currency,
            oversizedProductCodes,
            isEditing,
            userRole,
            onAddItem,
            onRemoveItem,
            onOrderChange,
            onAddSubItem,
            onRemoveSubItem
        } = this.props;

        return (
            <OrderDetailsView
                enums={enums}
                validProductStatusEnum={validProductStatusEnum}
                isEditing={isEditing}
                order={order}
                currency={currency}
                oversizedProductCodes={oversizedProductCodes}
                isSimpleUser={userRole === userRoleValues.SIMPLE_USER}
                onAddItem={onAddItem}
                onRemoveItem={onRemoveItem}
                onOrderChange={onOrderChange}
                onAddSubItem={onAddSubItem}
                onRemoveSubItem={onRemoveSubItem}
            />
        );
    }

    renderContent() {
        const { tabIndex } = this.state;

        if (tabIndex === ORDER_DETAILS_TAB_INDEX) {
            return this.renderOrderDetails();
        }

        if (tabIndex === ORDER_HISTORY_TAB_INDEX) {
            return (
                <HistoryView
                    history={this.props.order.history}
                    translationScope="order"
                />
            );
        }
    }

    renderShopOrderDetailLink() {
        const shopOrderDetailLink = getShopOrderDetailLink(this.props.order);

        if (!shopOrderDetailLink) {
            return null;
        }

        return (
            <Grid item style={{ alignSelf: 'center' }}>
                <a
                    href={shopOrderDetailLink}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    {i18next.t('Display shop detail')}
                </a>
            </Grid>
        );
    }

    render() {
        const { order, isLoading, oversizedProductCodes } = this.props;
        if (isLoading || !order) {
            return OrderViewBase.renderLoader();
        }

        const title = getOrderTitle(order.isClaim, order.isClone);

        const isOversized = containsOrderOversizedItem(
            order.items,
            oversizedProductCodes
        );
        return (
            <>
                <div className="no-print">
                    <Prompt
                        when={this.props.isEditing}
                        message={i18next.t('prompt.notSaved')}
                    />
                    <Grid container justify="space-between">
                        <Grid item>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <h1
                                    style={{
                                        marginTop: '10px',
                                        marginBottom: '10px'
                                    }}
                                >
                                    {title} {order.orderNumber}{' '}
                                    <OversizedItemIndicator
                                        isShown={isOversized}
                                    />
                                </h1>
                                {order && (
                                    <SameCustomerOpenOrdersLink order={order} />
                                )}
                            </div>
                        </Grid>
                        <AdvancedUserRole>
                            {this.renderShopOrderDetailLink()}
                        </AdvancedUserRole>
                    </Grid>
                    <Grid container>
                        <Grid item style={{ flexGrow: 1 }}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'baseline'
                                }}
                            >
                                <Typography gutterBottom color="textSecondary">
                                    {i18next.t('order.invoiceFormattedNumber')}{' '}
                                    {getNoInvoiceText(
                                        order.invoice?.numberFormatted
                                    )}
                                </Typography>
                                {order.invoice && (
                                    <Link
                                        style={{ marginLeft: '10px' }}
                                        href={getInvoiceLink(order.invoice.id)}
                                        target="_blank"
                                    >
                                        {order.invoice.numberFormatted}
                                    </Link>
                                )}
                            </div>
                        </Grid>
                        {(order.claims || order.clones) && (
                            <Grid item>
                                {order.claims?.map((orderClaimId, index) => (
                                    <NavLink
                                        key={orderClaimId}
                                        to={generatePath(appPaths.order, {
                                            orderId: orderClaimId
                                        })}
                                        exact
                                        style={{ marginRight: '10px' }}
                                    >
                                        {`${i18next.t(
                                            'order.showClaim'
                                        )} ${index + 1}`}
                                    </NavLink>
                                ))}
                                {order.clones?.map((orderCloneId, index) => (
                                    <NavLink
                                        key={orderCloneId}
                                        to={generatePath(appPaths.order, {
                                            orderId: orderCloneId
                                        })}
                                        exact
                                        style={{ marginRight: '10px' }}
                                    >
                                        {`${i18next.t(
                                            'order.showClone'
                                        )} ${index + 1}`}
                                    </NavLink>
                                ))}
                            </Grid>
                        )}
                        {!!order.courierShippingInfo && (
                            <Grid item>
                                <CourierPackagesLink
                                    shippingInfo={order.courierShippingInfo}
                                />
                            </Grid>
                        )}
                        {!!order.cartageId && (
                            <Grid item>
                                <NavLink
                                    to={generatePath(appPaths.cartage, {
                                        cartageId: order.cartageId
                                    })}
                                    exact
                                >
                                    {i18next.t('order.showCartage')}
                                </NavLink>
                            </Grid>
                        )}
                    </Grid>
                    <Tabs
                        value={this.state.tabIndex}
                        onChange={this.onTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                    >
                        <Tab label={i18next.t('Order details')} />
                        <Tab label={i18next.t('History')} />
                    </Tabs>
                    {this.renderContent()}
                </div>
                <OrderPrintView
                    order={order}
                    oversizedProductCodes={oversizedProductCodes}
                />
            </>
        );
    }
}

const OrderView: React.FC<Props> = props => {
    const { oversizedProductCodes } = useOversizedProductCodes();

    return (
        <OrderViewBase
            {...props}
            oversizedProductCodes={oversizedProductCodes}
        />
    );
};

export { OrderView };
