export enum API_URLS {
    products = '/product',
    orders = '/order',
    getOrderId = '/order/get-id/',
    ordersToShip = '/order/shipping',
    ordersUpdateStatus = '/order/update-status',
    ordersUpdateShipping = '/order/update-shipping',
    ordersCount = '/order/count',
    createOrderClaim = '/order/create-claim',
    cloneOrder = '/order/clone',
    resetOrder = '/order/reset',
    cartages = '/cartage',
    cartagesOverview = '/cartage/overview',
    printCourierLabels = '/courier-print-labels',
    expeditionStats = '/order/export/ready-to-ship-stats?month=',
    openOrdersStats = '/order/status-overview',
    courierShippingOverview = '/order/courier-shipping-overview',
    cartageExpeditionTimeOverview = '/order/cartages-expedition-time-overview',
    setOrderItemsAsReadyToShip = '/order/set-order-items-ready-to-ship',
    readyToShipOrdersStats = '/order/status-ready-to-ship-overview',
    openOrdersStockProductsStats = '/order/export/open-orders-stock-products',
    ordersStockProductsStats = '/order/export/orders-stock-products',
    cartageGroups = '/cartage-group',
    smsDeliveryConfirmation = '/bulk-gate/delivery-confirmation',
    createRegularInvoices = '/create-regular-invoices',
    oversizedProductsInfo = '/product-info/oversized-products',
    refreshProductsInfo = '/product-info/reimport',
    productAvailabilities = '/availability',
    setOrdersAsPaid = '/order/set-orders-as-paid',
    openOrdersForSameCustomer = '/order/open-orders-for-same-customer'
}
