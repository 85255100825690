import { Moment } from 'moment';
import { CartageStatus } from 'src/models/Cartage';

export interface CartageOverview {
    _id: string;
    name: string;
    shippingDate: string;
    ordersCount: number;
    itemsCount?: number;
    totalBoxCount?: number;
}

export interface FilterQuery {
    from: Moment;
    to: Moment;
}

const formatQueryDate = (date: Moment) =>
    date ? date.format('YYYY-MM-DD') : undefined;

export const ALL_COUNTRIES = 'ALL';

export const getCountryQuery = (value: string): string | undefined => {
    if (ALL_COUNTRIES === value) {
        return undefined;
    }

    return value;
};

export const getQueryString = (query: FilterQuery): string => {
    return `from=${formatQueryDate(query.from)}&to=${formatQueryDate(
        query.to
    )}&status=${CartageStatus.DONE}`;
};

export const filterCartages = (
    cartages: CartageOverview[],
    country: string
): CartageOverview[] => {
    if (country === ALL_COUNTRIES) {
        return cartages;
    }

    return cartages.filter(({ name }) => name.includes(` ${country} `));
};
