import React from 'react';
import { CartageGroup } from 'src/cartageGroups/CartageGroupTypes';
import { API_URLS } from 'src/common/ApiService';
import { useRequest } from 'src/common/useRequestHook';
import { RequestMethod } from 'src/utils/request';
import { CartageGroupView } from './CartageGroupView';

const DefaultCartageGroup: CartageGroup = {
    name: '',
    country: 'SK',
    zipCodes: []
};

export const AddCartageGroupContainer: React.FC = () => {
    const [addCartageGroup, { inProgress }] = useRequest(
        RequestMethod.POST,
        API_URLS.cartageGroups
    );

    return (
        <CartageGroupView
            isSaving={inProgress}
            onSaveClick={addCartageGroup}
            initialCartageGroup={DefaultCartageGroup}
        />
    );
};
