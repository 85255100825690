import {
    createStyles,
    IconButton,
    SnackbarContent,
    WithStyles,
    withStyles,
    Button
} from '@material-ui/core';
import amber from '@material-ui/core/colors/amber';
import green from '@material-ui/core/colors/green';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import classNames from 'classnames';
import React from 'react';
import { Notification, NotificationVariant } from '../../models/Notification';
import { NavLink } from 'react-router-dom';
import i18next from 'i18next';

interface Props {
    notification: Notification;
    onClose: () => void;
}

const variantIcon = {
    [NotificationVariant.Success]: CheckCircleIcon,
    [NotificationVariant.Warning]: WarningIcon,
    [NotificationVariant.Error]: ErrorIcon,
    [NotificationVariant.Info]: InfoIcon
};

const styles = theme =>
    createStyles({
        snackbarContent: {
            margin: theme.spacing(1),
            flexWrap: 'nowrap'
        },
        success: {
            backgroundColor: green[600]
        },
        error: {
            backgroundColor: theme.palette.error.dark
        },
        info: {
            backgroundColor: theme.palette.primary.dark
        },
        warning: {
            backgroundColor: amber[700]
        },
        icon: {
            fontSize: 20
        },
        iconVariant: {
            opacity: 0.9,
            marginRight: theme.spacing(1)
        },
        message: {
            display: 'flex',
            alignItems: 'center'
        }
    });

type AllProps = Props & WithStyles<typeof styles>;

const notificationHideTimeout = 3000;

class NotificationViewBase extends React.PureComponent<AllProps> {
    hideTimeout: number | undefined = undefined;

    componentDidMount() {
        this.createHideTimeout();
    }

    componentWillUnmount() {
        clearTimeout(this.hideTimeout);
    }

    componentDidUpdate(prevProps: AllProps) {
        if (prevProps.notification.id !== this.props.notification.id) {
            this.createHideTimeout();
        }
    }

    createHideTimeout() {
        if (this.props.notification.preventAutoHide) {
            return;
        }

        this.hideTimeout = window.setTimeout(
            this.props.onClose,
            notificationHideTimeout
        );
    }

    renderMessage() {
        const { notification, classes } = this.props;
        const Icon = variantIcon[notification.variant];

        return (
            <span className={classes.message}>
                <Icon
                    className={classNames(classes.icon, classes.iconVariant)}
                />
                <div>{notification.text}</div>
            </span>
        );
    }

    renderAction(): React.ReactElement<any> {
        const { classes, notification, onClose } = this.props;
        if (notification.link) {
            return (
                <Button
                    onClick={onClose}
                    component={NavLink}
                    to={notification.link}
                >
                    <span style={{ color: 'white' }}>
                        {i18next.t('notifications.display')}
                    </span>
                </Button>
            );
        }

        return (
            <IconButton color="inherit" onClick={onClose}>
                <CloseIcon className={classes.icon} />
            </IconButton>
        );
    }

    render() {
        const { notification, classes } = this.props;
        return (
            <SnackbarContent
                className={classNames(
                    classes.snackbarContent,
                    classes[notification.variant]
                )}
                message={this.renderMessage()}
                action={this.renderAction()}
            />
        );
    }
}

const NotificationView = withStyles(styles)(NotificationViewBase);

export { NotificationView };
