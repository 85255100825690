import i18next from 'i18next';
import { OrderToShip } from 'src/shippingPlanning/ShippingPlanningService';
import { discountType } from '../common/enums/enums';
import { Order } from '../models/Order';
import { CurrencySymbol } from '../utils/price';
import lightGreen from '@material-ui/core/colors/lightGreen';
import orange from '@material-ui/core/colors/orange';
import red from '@material-ui/core/colors/red';

export function getFormattedDiscountValue(
    order: Order | OrderToShip,
    currency: CurrencySymbol
): string {
    if (!order.discount) {
        return i18next.t('None');
    }

    if (order.discountType === discountType.PERCENTAGE) {
        return `${order.discount} %`;
    }

    return `${currency} ${order.discount}`;
}

const COUNT_COLOR_MAP: Record<number, string> = {
    1: 'transparent',
    2: lightGreen[200],
    3: orange[200]
};
export const getCountBackgroundColor = (count: number): string => {
    return COUNT_COLOR_MAP[count] ?? red[200];
};
