import {
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from '@material-ui/core';
import i18next from 'i18next';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { appPaths } from 'src/AppRouterService';
import { CartageGroup } from 'src/cartageGroups/CartageGroupTypes';

interface Props {
    isSaving: boolean;
    initialCartageGroup: CartageGroup;
    onSaveClick(cartageGroup: CartageGroup): Promise<unknown>;
}

export const CartageGroupView: React.FC<Props> = ({
    isSaving,
    initialCartageGroup,
    onSaveClick
}) => {
    const history = useHistory();
    const [cartageGroup, setCartageGroup] = useState(initialCartageGroup);

    const handleChange = evt => {
        setCartageGroup({
            ...cartageGroup,
            [evt.target.name]: evt.target.value
        });
    };

    const handleZipCodesChange = evt => {
        const zipCodes = evt.target.value
            .split(',')
            .map(zipCode => zipCode?.replace(/\s/g, ''))
            .filter(
                (zipCode, index, arr) => !!zipCode || index + 1 === arr.length
            );
        setCartageGroup({
            ...cartageGroup,
            zipCodes
        });
    };

    const handleSaveClick = async () => {
        await onSaveClick(cartageGroup);

        history.push(appPaths.cartageGroups);
    };

    const handleBackClick = () => {
        history.push(appPaths.cartageGroups);
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={6}>
                <TextField
                    label={i18next.t('cartageGroup.name')}
                    value={cartageGroup.name}
                    name="name"
                    disabled={isSaving}
                    onChange={handleChange}
                    fullWidth
                />
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <InputLabel htmlFor="country">
                        {i18next.t('cartageGroup.country')}
                    </InputLabel>
                    <Select
                        id="country"
                        name="country"
                        value={cartageGroup.country}
                        onChange={handleChange}
                        fullWidth
                    >
                        <MenuItem key={'SK'} value={'SK'}>
                            {i18next.t('SK')}
                        </MenuItem>
                        <MenuItem key={'HU'} value={'HU'}>
                            {i18next.t('HU')}
                        </MenuItem>
                        <MenuItem key={'CZ'} value={'CZ'}>
                            {i18next.t('CZ')}
                        </MenuItem>
                        <MenuItem key={'RO'} value={'RO'}>
                            {i18next.t('RO')}
                        </MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label={i18next.t('cartageGroup.zipCodes')}
                    value={cartageGroup.zipCodes.join(', ')}
                    name={`zipCodes`}
                    disabled={isSaving}
                    onChange={handleZipCodesChange}
                    inputProps={{ pattern: '[0-9]' }}
                    fullWidth
                />
            </Grid>
            <Grid item container xs={12} justify="flex-end">
                <Button
                    style={{ marginRight: '10px' }}
                    variant="contained"
                    onClick={handleBackClick}
                >
                    {i18next.t('Back')}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={!cartageGroup.name || isSaving}
                    onClick={handleSaveClick}
                >
                    {i18next.t('Save')}
                </Button>
            </Grid>
        </Grid>
    );
};
