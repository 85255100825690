import React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../models/AppState';
import { hideError } from './ErrorsActions';
import { ErrorsView } from './ErrorsView';

interface Props {
    errors: any[];
    onHideError: () => void;
}

export class ErrorsContainerBase extends Component<Props> {
    render() {
        const { errors, onHideError } = this.props;
        return <ErrorsView errors={errors} onClose={onHideError} />;
    }
}

const mapStateToProps = (state: AppState) => ({
    errors: state.errors.errors
});

const mapDispatchToProps = {
    onHideError: hideError
};

const ErrorsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ErrorsContainerBase);

export { ErrorsContainer };
