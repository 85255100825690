import { CircularProgress } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CartageGroup } from 'src/cartageGroups/CartageGroupTypes';
import { API_URLS } from 'src/common/ApiService';
import { useRequest } from 'src/common/useRequestHook';
import { RequestMethod } from 'src/utils/request';
import { CartageGroupView } from './CartageGroupView';

export const EditCartageGroupContainer: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [updateCartageGroup, { inProgress }] = useRequest(
        RequestMethod.PATCH,
        `${API_URLS.cartageGroups}/${id}`
    );
    const [
        fetchCartageGroup,
        { inProgress: isFetching, response: cartageGroup }
    ] = useRequest<CartageGroup>(
        RequestMethod.GET,
        `${API_URLS.cartageGroups}/${id}`
    );

    useEffect(() => {
        fetchCartageGroup();
    }, [fetchCartageGroup]);

    if (isFetching || !cartageGroup) {
        return (
            <div style={{ textAlign: 'center' }}>
                <CircularProgress size={50} />
            </div>
        );
    }

    return (
        <CartageGroupView
            isSaving={inProgress}
            onSaveClick={updateCartageGroup}
            initialCartageGroup={cartageGroup}
        />
    );
};
