import { Action, handleActions } from 'redux-actions';
import { User } from '../../models/User';
import * as actions from './SessionActions';
import { SessionState } from './SessionState';

const defaultState: SessionState = {
    isLoading: true,
    user: null
};

interface LoadSessionSuccessPayload {
    user: User;
}

type PayloadType = LoadSessionSuccessPayload;

const SessionReducer = handleActions<SessionState, PayloadType>(
    {
        [actions.loadingSession.toString()]: state => ({
            ...state,
            isLoading: true
        }),

        [actions.loadSessionSuccess.toString()]: (
            state,
            action: Action<LoadSessionSuccessPayload>
        ) => ({
            ...state,
            user: action.payload.user,
            isLoading: false
        }),

        [actions.logoutSuccess.toString()]: state => ({
            ...state,
            user: null
        })
    },
    defaultState
);

export { SessionReducer };
