import { get } from '../utils/request';
import { Pagination } from './types';
import { useState } from 'react';
import { showError } from './errors/ErrorsActions';
import { useDispatch } from 'react-redux';
import { QUERY_LIMIT, getNextSkip, hasMoreItems } from './PaginationService';
import { ProductListQuery } from 'src/productList/ProductListState';

interface UseFetchedItems<T> {
    items: T[];
    isLoading: boolean;
    hasMore: boolean;
    fetchItems(queryParams?: any): void;
    fetchNextItems(): void;
}

type Query = ProductListQuery & Pagination;

interface ApiRequestState {
    isLoading: boolean;
    isLoaded: boolean;
}

function getItems<T>(currentItems: T[], newItems: T[], concat: boolean): T[] {
    if (concat) {
        return newItems;
    }

    return [...currentItems, ...newItems];
}

function getSkip(fetchedItemsCount: number, skip: number): number {
    if (fetchedItemsCount === 0) {
        return 0;
    }

    return getNextSkip(skip);
}

function isFetchingFirstPage(skip): boolean {
    return skip === 0;
}

export function useFetchedItems<T>(url: string): UseFetchedItems<T> {
    const [query, setQuery] = useState<Query>({
        skip: 0,
        limit: QUERY_LIMIT
    });
    const [items, setItems] = useState<T[]>([]);
    const [apiState, setApiState] = useState<ApiRequestState>({
        isLoading: false,
        isLoaded: false
    });
    const dispatch = useDispatch();

    //how to cancel running request
    async function fetchItemsCall(itemsQuery: Query): Promise<void> {
        if (apiState.isLoading || items.length < itemsQuery.skip) {
            return;
        }

        setApiState({
            isLoaded: false,
            isLoading: true
        });
        setQuery(itemsQuery);
        try {
            const fetchedItems = await get<T[]>(url, {
                ...itemsQuery,
                search: itemsQuery.search || undefined
            });
            setItems(
                getItems<T>(
                    items,
                    fetchedItems,
                    isFetchingFirstPage(itemsQuery.skip)
                )
            );
        } catch (err) {
            dispatch(showError(err));
        } finally {
            setApiState({
                isLoaded: true,
                isLoading: false
            });
        }
    }

    function fetchNextItems(): Promise<void> {
        return fetchItemsCall({
            ...query,
            skip: getSkip(items.length, query.skip)
        });
    }

    function fetchItems(queryParams = {}): Promise<void> {
        return fetchItemsCall({
            ...query,
            ...queryParams,
            skip: 0
        });
    }

    return {
        items,
        isLoading: apiState.isLoading,
        fetchItems,
        fetchNextItems,
        hasMore:
            !apiState.isLoaded ||
            apiState.isLoading ||
            hasMoreItems(items.length, query.skip)
    };
}
