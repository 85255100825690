import { EnumsState, EnumState } from '../../common/enums/EnumsState';
import { SessionState } from '../../common/session/SessionState';

export function isLoadingPrerequisites(
    enums: EnumsState,
    session: SessionState
): boolean {
    return (
        session.isLoading ||
        Object.values(enums).some((enumState: EnumState) => enumState.isLoading)
    );
}
