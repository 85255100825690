import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'src/models/AppState';
import { addSharedData, SHARED_DATA_KEY } from './SharedDataActions';
import { selectSharedData } from './SharedDataSelectors';

type UseSharedData<SharedData> = [
    SharedData | undefined,
    (data: SharedData) => void
];

export function useSharedData<SharedData>(
    key: SHARED_DATA_KEY
): UseSharedData<SharedData | undefined> {
    const dispatch = useDispatch();
    const addedData = useSelector((state: AppState) =>
        selectSharedData<SharedData>(state, key)
    );

    const addSharedDataAction = useCallback(
        (data: SharedData) => {
            dispatch(addSharedData(key, data));
        },
        [dispatch, key]
    );

    return [addedData, addSharedDataAction];
}
