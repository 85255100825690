import React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { EnumsState } from '../../common/enums/EnumsState';
import { logout } from '../../common/session/SessionActions';
import { SessionState } from '../../common/session/SessionState';
import { AppState } from '../../models/AppState';
import { toggleMobileMenu } from './LayoutActions';
import { isLoadingPrerequisites } from './LayoutService';
import { LayoutView } from './LayoutView';

interface Props {
    isMobileMenuOpen: boolean;
    session: SessionState;
    enums: EnumsState;
    onLogout: () => void;
    onToggleMobileMenu: () => void;
}

export class LayoutContainerBase extends Component<Props> {
    render() {
        const {
            enums,
            session,
            onLogout,
            onToggleMobileMenu,
            isMobileMenuOpen,
            children
        } = this.props;
        return (
            <LayoutView
                isLoading={isLoadingPrerequisites(enums, session)}
                isMobileMenuOpen={isMobileMenuOpen}
                onLogout={onLogout}
                onNavToggle={onToggleMobileMenu}
            >
                {children}
            </LayoutView>
        );
    }
}

const mapStateToProps = (state: AppState) => ({
    isMobileMenuOpen: state.layout.isMobileMenuOpen,
    session: state.session,
    enums: state.enums
});

const mapDispatchToProps = {
    onLogout: logout,
    onToggleMobileMenu: toggleMobileMenu
};

const LayoutContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(LayoutContainerBase);

export { LayoutContainer };
