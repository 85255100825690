import {
    FormControl,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    Select,
    TextField,
    Tooltip
} from '@material-ui/core';
import i18next from 'i18next';
import React, { ChangeEvent } from 'react';
import { BaseProductItem, ProductItem } from 'src/models/Order';
import { OversizedItemIndicator } from './OversizedItemIndicator';
import { renderEnumOptions } from 'src/utils/enums';
import { tooltipEnterDelay } from 'src/config';
import DeleteIcon from '@material-ui/icons/Delete';
import { Enum } from 'src/models/Enum';

interface Props {
    index: number;
    parentItem: ProductItem;
    disabled: boolean;
    className: string;
    subItems: BaseProductItem[] | undefined;
    oversizedProductCodes: string[] | undefined;
    validProductStatusEnum: Enum;
    onChange: (event: ChangeEvent<HTMLElement>) => void;
    onRemoveSubItem: (item: ProductItem, subItem: ProductItem) => void;
}
export const ProductSubItems: React.FC<Props> = ({
    index,
    parentItem,
    disabled,
    subItems,
    oversizedProductCodes,
    className,
    validProductStatusEnum,
    onChange,
    onRemoveSubItem
}) => {
    if (!subItems) {
        return null;
    }

    return (
        <>
            {subItems.map((item, subIndex) => {
                return (
                    <Grid key={subIndex} item className={className} xs={11}>
                        <Grid container spacing={3}>
                            <Grid item xs={4} sm={2} md={1}>
                                <FormControl fullWidth>
                                    <InputLabel
                                        htmlFor={`subItems${index}supplier${subIndex}`}
                                    >
                                        {i18next.t('Supplier')}
                                    </InputLabel>
                                    <Input
                                        id={`subItems${index}supplier${subIndex}`}
                                        value={item.supplier || ''}
                                        name={`items.${index}.subItems.${subIndex}.supplier`}
                                        disabled={disabled}
                                        onChange={onChange}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={8} sm={4} md={2}>
                                <FormControl fullWidth>
                                    <InputLabel
                                        htmlFor={`subItems${index}code${subIndex}`}
                                    >
                                        {i18next.t('Code')}
                                    </InputLabel>
                                    <Input
                                        id={`subItems${index}code${subIndex}`}
                                        value={item.productCode || ''}
                                        name={`items.${index}.subItems.${subIndex}.productCode`}
                                        disabled={disabled}
                                        onChange={onChange}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <OversizedItemIndicator
                                                    isShown={oversizedProductCodes?.includes(
                                                        item?.productCode
                                                    )}
                                                />
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={9}>
                                <TextField
                                    label={i18next.t('Name')}
                                    value={item.name || ''}
                                    name={`items.${index}.subItems.${subIndex}.name`}
                                    disabled={disabled}
                                    onChange={onChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label={i18next.t('Variant')}
                                    value={item.variant || ''}
                                    name={`items.${index}.subItems.${subIndex}.variant`}
                                    disabled={disabled}
                                    onChange={onChange}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={6} md={4}>
                                <FormControl fullWidth>
                                    <InputLabel
                                        htmlFor={`subItems${index}weight${subIndex}`}
                                    >
                                        {i18next.t('Weight')}
                                    </InputLabel>
                                    <Input
                                        id={`subItems${index}weight${subIndex}`}
                                        type="number"
                                        inputProps={{ min: 0 }}
                                        value={item.weight || ''}
                                        name={`items.${index}.subItems.${subIndex}.weight`}
                                        disabled={disabled}
                                        onChange={onChange}
                                        endAdornment={
                                            <InputAdornment position="start">
                                                kg
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <FormControl fullWidth>
                                    <InputLabel
                                        htmlFor={`subItems${index}volume${subIndex}`}
                                    >
                                        {i18next.t('Volume')}
                                    </InputLabel>
                                    <Input
                                        id={`subItems${index}volume${subIndex}`}
                                        type="number"
                                        inputProps={{ min: 0 }}
                                        value={item.volume || ''}
                                        name={`items.${index}.subItems.${subIndex}.volume`}
                                        disabled={disabled}
                                        onChange={onChange}
                                        endAdornment={
                                            <InputAdornment position="start">
                                                m3
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <TextField
                                    label={i18next.t('Box count')}
                                    type="number"
                                    inputProps={{ min: 0 }}
                                    value={item.boxCount || ''}
                                    name={`items.${index}.subItems.${subIndex}.boxCount`}
                                    disabled={disabled}
                                    fullWidth
                                    onChange={onChange}
                                />
                            </Grid>

                            <Grid item xs={6} md={2}>
                                <TextField
                                    label={i18next.t('Quantity')}
                                    type="number"
                                    inputProps={{ min: 0 }}
                                    value={item.quantity || ''}
                                    name={`items.${index}.subItems.${subIndex}.quantity`}
                                    disabled={disabled}
                                    fullWidth
                                    onChange={onChange}
                                />
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel
                                        htmlFor={`subItems${index}status${subIndex}`}
                                    >
                                        {i18next.t('Status')}
                                    </InputLabel>
                                    <Select
                                        id={`subItems${index}status${subIndex}`}
                                        value={item.status || ''}
                                        name={`items.${index}.subItems.${subIndex}.status`}
                                        disabled={disabled}
                                        // @ts-ignore select
                                        onChange={onChange}
                                    >
                                        {renderEnumOptions(
                                            validProductStatusEnum
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid
                                container
                                item
                                justify="flex-end"
                                xs={12}
                                md={6}
                            >
                                <Tooltip
                                    title={i18next.t('Remove sub item')}
                                    enterDelay={tooltipEnterDelay}
                                >
                                    <div>
                                        <IconButton
                                            color="default"
                                            onClick={() =>
                                                onRemoveSubItem(
                                                    parentItem,
                                                    item
                                                )
                                            }
                                            disabled={disabled}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </div>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                );
            })}
        </>
    );
};
