import { Typography } from '@material-ui/core';
import React from 'react';

interface Props {
    highlight?: boolean;
}

export const LongLabel: React.FC<Props> = ({ children, highlight }) => (
    <Typography
        style={{
            width: '160px',
            fontWeight: 700,
            display: 'inline-block',
            marginRight: '10px'
        }}
        variant="body1"
        gutterBottom
        color={highlight ? 'error' : undefined}
    >
        {children}
    </Typography>
);
