import React, { useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from '@material-ui/core';
import i18next from 'i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { useDebounce } from 'use-debounce';
import { ProductListTableBody } from './ProductListTableBody';
import { useFetchedItems } from '../common/useFetchedItemsHook';
import { Product } from '../models/Product';
import { API_URLS } from '../common/ApiService';
import { useSelector } from 'react-redux';
import { selectProductListQuery } from './ProductListSelectors';
import { SEARCH_DELAY } from '../config';

export const ProductListTable: React.FC = () => {
    const {
        items,
        isLoading,
        hasMore,
        fetchItems,
        fetchNextItems
    } = useFetchedItems<Product>(API_URLS.products);

    const [debouncedSearchValue] = useDebounce(
        useSelector(selectProductListQuery),
        SEARCH_DELAY
    );

    useEffect(() => {
        window.scrollTo({ top: 0 });
        fetchItems({ search: debouncedSearchValue });
    }, [debouncedSearchValue]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Table className="table">
            <TableHead>
                <TableRow>
                    <TableCell>{i18next.t('productList.code')}</TableCell>
                    <TableCell>{i18next.t('productList.name')}</TableCell>
                    <TableCell>{i18next.t('productList.variant')}</TableCell>
                    <TableCell>
                        {i18next.t('productList.manufacturer')}
                    </TableCell>
                    <TableCell />
                </TableRow>
            </TableHead>
            <InfiniteScroll
                element={TableBody}
                initialLoad={false}
                loadMore={fetchNextItems}
                hasMore={hasMore}
            >
                <ProductListTableBody
                    isLoading={isLoading}
                    hasMore={hasMore}
                    products={items}
                />
            </InfiniteScroll>
        </Table>
    );
};
