import React, { useCallback } from 'react';
import { getOrderTotalWeight, OrderToShip } from './ShippingPlanningService';
import { TableRow, TableCell, Checkbox, Typography } from '@material-ui/core';
import { OrderNumber } from 'src/orderList/OrderNumber';
import { resolveEnumValue } from 'src/utils/enums';
import { formatDateTime, formatWeight } from 'src/utils/formatters';
import { NavLink } from 'react-router-dom';
import i18next from 'i18next';
import { useEnums } from 'src/common/enums/useEnumsHook';
import { useSelector, useDispatch } from 'react-redux';
import { selectShippingSelectedOrders } from './ShippingPlanningSelectors';
import { updateSelectedOrdersIds } from './ShippingPlanningActions';
import { toggleArrayItem } from 'src/common/UtilsService';
import { OrderPrintView } from 'src/order/OrderPrintView';
import { ExpectedShippingDate } from './ExpectedShippingDate';
import { OrderToShipProduct } from './OrderToShipProduct';

interface Props {
    order: OrderToShip;
    hideDeliveryType: boolean;
    showExpectedShippingDate: boolean;
}

export const OrderToShipItem: React.FC<Props> = ({
    order,
    hideDeliveryType,
    showExpectedShippingDate
}) => {
    const dispatch = useDispatch();
    const enums = useEnums();
    const selectedOrdersIds = useSelector(selectShippingSelectedOrders);

    const handleCheckChange = useCallback(() => {
        dispatch(
            updateSelectedOrdersIds(
                toggleArrayItem(selectedOrdersIds, order._id)
            )
        );
    }, [dispatch, order._id, selectedOrdersIds]);

    const isChecked = selectedOrdersIds.includes(order._id);

    return (
        <>
            {isChecked && <OrderPrintView order={order} />}
            <TableRow className="no-print">
                <TableCell>
                    <Checkbox
                        name={order._id}
                        checked={isChecked}
                        onChange={handleCheckChange}
                    />
                </TableCell>
                <TableCell>
                    <OrderNumber
                        orderNumber={order.orderNumber}
                        deliveryType={order.delivery.type}
                    />
                </TableCell>
                {!hideDeliveryType && (
                    <TableCell>
                        {resolveEnumValue(
                            enums.deliveryType.enum,
                            order.delivery.type
                        )}
                    </TableCell>
                )}
                {!showExpectedShippingDate && (
                    <TableCell>{formatDateTime(order.createdDate)}</TableCell>
                )}
                {showExpectedShippingDate && (
                    <TableCell>
                        <ExpectedShippingDate
                            date={order.expectedShippingDate}
                        />
                    </TableCell>
                )}
                <TableCell>
                    <Typography display="block">{`${order.customer.delivery.zip} ${order.customer.delivery.city}`}</Typography>
                    <Typography display="block" variant="caption">
                        {order.customer.delivery.street}
                    </Typography>
                </TableCell>
                <TableCell>
                    {order.items.map(item => {
                        if (!item.subItems?.length) {
                            return (
                                <OrderToShipProduct
                                    key={item._id}
                                    item={item}
                                    showWeight={order.items.length > 1}
                                />
                            );
                        }

                        return item.subItems.map(subItem => (
                            <OrderToShipProduct
                                key={subItem._id}
                                item={subItem}
                                showWeight={order.items.length > 1}
                            />
                        ));
                    })}
                    <Typography variant="body2">
                        {i18next.t('Total weight')}{' '}
                        {formatWeight(getOrderTotalWeight(order.items))}
                    </Typography>
                </TableCell>
                <TableCell>{order.shippingNote}</TableCell>
                <TableCell>
                    <NavLink to={`/order/${order._id}`}>
                        {i18next.t('Display')}
                    </NavLink>
                </TableCell>
            </TableRow>
        </>
    );
};
