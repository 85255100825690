import { TableRow, TableCell, CircularProgress, Chip } from '@material-ui/core';
import i18next from 'i18next';
import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { appPaths } from 'src/AppRouterService';
import { CartageGroup } from 'src/cartageGroups/CartageGroupTypes';
import { API_URLS } from 'src/common/ApiService';
import { useRequest } from 'src/common/useRequestHook';
import { RequestMethod } from 'src/utils/request';

interface Props {
    group: CartageGroup;
    country: string;
}

function getChipValue(
    hasFailed: boolean,
    ordersCount?: number,
    carsCount?: number,
    cartageMaxWeight?: number
): string {
    if (hasFailed) {
        return 'chyba';
    }

    if (!ordersCount) {
        return '-';
    }

    if (!carsCount) {
        return ordersCount.toString();
    }

    return `${ordersCount} (${carsCount} x ${cartageMaxWeight}kg)`;
}

export const CartagePlanningGroupRow: React.FC<Props> = ({
    country,
    group: { _id, name, zipCodes }
}) => {
    const [fetchOrdersCount, { response, inProgress, hasFailed }] = useRequest<{
        ordersCount: number;
        carsCount: number;
        cartageMaxWeight: number;
    }>(RequestMethod.POST, API_URLS.ordersCount);

    useEffect(() => {
        fetchOrdersCount({ zipCodes, country });
    }, [zipCodes, country, fetchOrdersCount]);

    return (
        <TableRow key={_id}>
            <TableCell>{name}</TableCell>
            <TableCell>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {inProgress && <CircularProgress size={25} />}
                    {!inProgress && (
                        <Chip
                            label={getChipValue(
                                hasFailed,
                                response?.ordersCount,
                                response?.carsCount,
                                response?.cartageMaxWeight
                            )}
                            color={hasFailed ? 'secondary' : 'default'}
                        />
                    )}
                </div>
            </TableCell>
            <TableCell>
                <NavLink to={`${appPaths.cartagePlanning}/${_id}`}>
                    {i18next.t('cartagePlanning.open')}
                </NavLink>
            </TableCell>
        </TableRow>
    );
};
