import { Typography } from '@material-ui/core';
import React from 'react';

export const ShortLabel: React.FC = ({ children }) => (
    <Typography
        style={{
            width: '80px',
            fontWeight: 700,
            display: 'inline-block',
            marginRight: '10px'
        }}
        variant="body1"
        gutterBottom
    >
        {children}
    </Typography>
);
