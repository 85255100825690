import { Model } from 'src/common/Model';

export enum CartageOrderFlag {
    ANSWERED = 'answered'
}

export enum BulkGateMessageType {
    CARTAGE_READY_TO_SHIP = 'CARTAGE_READY_TO_SHIP'
}

export interface Message {
    smsId?: string;
    error?: string;
    type: BulkGateMessageType;
}

export interface OrderInfo {
    orderId: string;
    sequence: number;
    deliveryTime: string;
    deliveryInterval?: number;
    flags: CartageOrderFlag[];
    messages?: Message[];
}

export interface Cartage extends Model {
    createdDate: string;
    version: string;
    ordersInfo: OrderInfo[];
    status: CartageStatus;
    notes: string;
    name: string;
    shippingDate: string;
    driver: string;
    picker: string;
}

export enum CartageStatus {
    INITIAL = 'INITIAL',
    READY_TO_SHIP = 'READY_TO_SHIP',
    DONE = 'DONE'
}
