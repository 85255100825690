import i18next from 'i18next';
import { Dispatch } from 'redux';
import { createAction } from 'redux-actions';
import { showError } from '../common/errors/ErrorsActions';
import { showNotification } from '../common/notifications/NotificationsActions';
import { NotificationVariant } from '../models/Notification';
import { Order, ProductItem } from '../models/Order';
import { get, patch, post } from '../utils/request';
import { InvoiceActionSuccessResponse } from './OrderService';
import { getOrderValidationError } from './OrderValidationService';

export const removeItem = createAction('REMOVE_ITEM', (item: ProductItem) => ({
    item
}));
export const displayProductPicker = createAction('DISPLAY_PRODUCT_PICKER');
export const closeProductPicker = createAction('CLOSE_PRODUCT_PICKER');
export const addItem = createAction('ADD_ITEM');
export const addSubItem = createAction('ADD_SUB_ITEM', (item: ProductItem) => ({
    item
}));
export const removeSubItem = createAction(
    'REMOVE_SUB_ITEM',
    (item: ProductItem, subItem: ProductItem) => ({
        item,
        subItem
    })
);
export const updateOrder = createAction(
    'UPDATE_ORDER',
    (propName: string, propValue: any) => ({
        propName,
        propValue
    })
);

export const loadingOrder = createAction('LOADING_ORDER');
export const invalidateOrderCache = createAction('INVALIDATE_ORDER_CACHE');
export const loadOrderSuccess = createAction(
    'LOAD_ORDER_SUCCESS',
    (order: Order) => ({ order })
);
export const loadOrderFailure = createAction('LOAD_ORDER_FAILURE');
export function loadOrder(orderId): (dispatch: Dispatch<any>) => Promise<void> {
    return async (dispatch: Dispatch<any>) => {
        dispatch(loadingOrder());
        try {
            const order = await get<Order>(
                `/order/${encodeURIComponent(orderId)}`
            );
            dispatch(loadOrderSuccess(order));
        } catch (err) {
            dispatch(loadOrderFailure());
            dispatch(showError(err));
        }
    };
}

export const savingOrder = createAction('SAVING_ORDER');
export const saveOrderSuccess = createAction('SAVE_ORDER_SUCCESS');
export const saveOrderFailure = createAction('SAVE_ORDER_FAILURE');
export function saveOrder(order: Order) {
    return async (dispatch: Dispatch<any>) => {
        const orderError = getOrderValidationError(order);

        if (orderError) {
            dispatch(
                showNotification({
                    id: `order_save_validation_error_${Date.now()}`,
                    variant: NotificationVariant.Error,
                    text: orderError
                })
            );
            return;
        }

        dispatch(savingOrder());
        try {
            const updatedOrder = await patch('/order', order._id, order);
            dispatch(saveOrderSuccess());
            dispatch(loadOrderSuccess(updatedOrder));
            dispatch(
                showNotification({
                    id: `order_save_success_${Date.now()}`,
                    variant: NotificationVariant.Success,
                    text: i18next.t('Order was sucessfully saved')
                })
            );
        } catch (err) {
            dispatch(showError(err));
            dispatch(saveOrderFailure());
        }
    };
}

export const exportingInvoice = createAction('EXPORTING_INVOICE');
export const exportInvoiceSuccess = createAction(
    'EXPORT_INVOICE_SUCCESS',
    (order: InvoiceActionSuccessResponse) => ({
        order
    })
);
export const exportInvoiceFailure = createAction('EXPORT_INVOICE_FAILURE');
export function exportInvoice(orderId: string, createRegular = false) {
    return async (dispatch: Dispatch<any>) => {
        dispatch(exportingInvoice());
        try {
            const order = await post('/invoice-export', {
                orderId,
                createRegular
            });
            dispatch(exportInvoiceSuccess(order));

            const text = createRegular
                ? i18next.t('Regular invoice was successfully created')
                : i18next.t('Invoice was successfully exported');
            dispatch(
                showNotification({
                    text,
                    id: `invoice_export_success_${Date.now()}`,
                    variant: NotificationVariant.Success
                })
            );
        } catch (err) {
            dispatch(exportInvoiceFailure());
            dispatch(showError(err));
        }
    };
}

export const deletingInvoice = createAction('DELETING_INVOICE');
export const deleteInvoiceSuccess = createAction(
    'DELETE_INVOICE_SUCCESS',
    (order: InvoiceActionSuccessResponse) => ({
        order
    })
);
export const deleteInvoiceFailure = createAction('DELETE_INVOICE_FAILURE');
export function deleteInvoice(orderId: string) {
    return async (dispatch: Dispatch<any>) => {
        dispatch(deletingInvoice());
        try {
            const order = await post('/invoice-delete', { orderId });
            dispatch(deleteInvoiceSuccess(order));
            dispatch(
                showNotification({
                    id: `invoice_delete_success_${Date.now()}`,
                    variant: NotificationVariant.Success,
                    text: i18next.t('Invoice was successfully deleted')
                })
            );
        } catch (err) {
            dispatch(deleteInvoiceFailure());
            dispatch(showError(err));
        }
    };
}
