import React, { ChangeEvent, useCallback } from 'react';
import i18next from 'i18next';
import {
    Grid,
    TextField,
    FormControlLabel,
    Checkbox,
    FormControl,
    InputLabel,
    Select
} from '@material-ui/core';
import { ProductPrices } from './ProductPrices';
import { ProductPartList } from './ProductPartList';
import { useSelector, useDispatch } from 'react-redux';
import { selectSharedProduct } from 'src/common/sharedData/SharedDataSelectors';
import {
    updateSharedData,
    SHARED_DATA_KEY
} from 'src/common/sharedData/SharedDataActions';
import { ProductDatePicker } from './ProductDatePicker';
import { ManufacturerSelect } from './ManufacturerSelect';
import { ProductCodeInput } from './ProductCodeInput';
import { renderEnumOptions } from 'src/utils/enums';
import { useEnums } from 'src/common/enums/useEnumsHook';

export const ProductDetails: React.FC = () => {
    const dispatch = useDispatch();
    const product = useSelector(selectSharedProduct);
    const enums = useEnums();

    const onChange = useCallback(
        (event: ChangeEvent<any>) => {
            dispatch(
                updateSharedData(
                    SHARED_DATA_KEY.product,
                    event.target.name,
                    event.target.value
                )
            );
        },
        [dispatch]
    );

    const onCheckboxChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>, isChecked: boolean) => {
            dispatch(
                updateSharedData(
                    SHARED_DATA_KEY.product,
                    event.currentTarget.name,
                    isChecked
                )
            );
        },
        [dispatch]
    );

    if (!product) {
        return null;
    }

    return (
        <form style={{ paddingTop: 20 }}>
            <Grid container spacing={3}>
                <Grid item xs={4}>
                    <ProductCodeInput
                        name="code"
                        code={product.code}
                        manufacturer={product.manufacturer}
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="status">
                            {i18next.t('product.status')}
                        </InputLabel>
                        <Select
                            id="status"
                            name="status"
                            value={product.status}
                            onChange={onChange}
                            fullWidth
                        >
                            {renderEnumOptions(enums.productGeneralStatus.enum)}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="manufacturer">
                            {i18next.t('product.manufacturer')}
                        </InputLabel>
                        <ManufacturerSelect
                            name="manufacturer"
                            value={product.manufacturer}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        name="name.SK"
                        label={i18next.t('product.nameSK')}
                        value={product.name.SK}
                        fullWidth
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        name="name.PL"
                        label={i18next.t('product.namePL')}
                        value={product.name.PL}
                        fullWidth
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        name="name.HU"
                        label={i18next.t('product.nameHU')}
                        value={product.name.HU}
                        fullWidth
                        onChange={onChange}
                    />
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            name="variant"
                            label={i18next.t('product.variant')}
                            value={product.variant}
                            fullWidth
                            onChange={onChange}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <ProductDatePicker
                            name="deliveryDate"
                            label={i18next.t('product.deliveryDate')}
                            value={product.deliveryDate}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <ProductDatePicker
                            name="publishDate"
                            label={i18next.t('product.publishDate')}
                            value={product.publishDate}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextField
                            name="notes"
                            label={i18next.t('product.notes')}
                            value={product.notes ?? ''}
                            multiline
                            fullWidth
                            onChange={onChange}
                        />
                    </Grid>
                    <Grid item md={3} xs={6}>
                        <FormControlLabel
                            key="atypicalDelivery"
                            control={
                                <Checkbox
                                    checked={product.atypicalDelivery}
                                    name="atypicalDelivery"
                                    color="primary"
                                    onChange={onCheckboxChange}
                                />
                            }
                            label={i18next.t('product.atypicalDelivery')}
                        />
                    </Grid>
                    <Grid item md={3} xs={6}>
                        <FormControlLabel
                            key="atypicalSize"
                            control={
                                <Checkbox
                                    checked={product.atypicalSize}
                                    name="atypicalSize"
                                    color="primary"
                                    onChange={onCheckboxChange}
                                />
                            }
                            label={i18next.t('product.atypicalSize')}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <ProductPrices
                        discountPrice={product.discountPrice}
                        sellingPrice={product.sellingPrice}
                        purchasePrice={product.purchasePrice}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ProductPartList
                        parts={product.parts}
                        onChange={onChange}
                    />
                </Grid>
            </Grid>
        </form>
    );
};
