import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { Tooltip, Button, Select, MenuItem } from '@material-ui/core';
import { tooltipEnterDelay } from 'src/config';
import AddIcon from '@material-ui/icons/PlaylistAdd';

import { RequestMethod } from 'src/utils/request';
import { useRequest } from 'src/common/useRequestHook';
import { API_URLS } from 'src/common/ApiService';
import { NotificationVariant } from 'src/models/Notification';
import { showNotification } from 'src/common/notifications/NotificationsActions';
import { selectShippingSelectedOrders } from 'src/shippingPlanning/ShippingPlanningSelectors';
import { useSharedData } from 'src/common/sharedData/useSharedData';
import { SHARED_DATA_KEY } from 'src/common/sharedData/SharedDataActions';
import { CartagePlanningGroupSharedData } from './CartagePlanningGroupService';
import { updateSelectedOrdersIds } from 'src/shippingPlanning/ShippingPlanningActions';
import { Cartage, CartageStatus } from 'src/models/Cartage';
import { appPaths } from 'src/AppRouterService';
import { generatePath } from 'react-router-dom';

const CARTAGE_PLACEHOLDER_ID = 'CARTAGE_PLACEHOLDER_ID';
const CARTAGES_QUERY = {
    status: [CartageStatus.INITIAL, CartageStatus.READY_TO_SHIP]
};

export const CartagePlanningGroupAppBarView: React.FC = () => {
    const dispatch = useDispatch();
    const [sharedData] = useSharedData<CartagePlanningGroupSharedData>(
        SHARED_DATA_KEY.cartagePlanningGroup
    );
    const [selectedCartageId, setSelectedCartageId] = useState<string>(
        CARTAGE_PLACEHOLDER_ID
    );

    const selectedOrdersIds = useSelector(selectShippingSelectedOrders);

    const [createCartage, { inProgress: isCreatingCartage }] = useRequest<
        Cartage
    >(RequestMethod.POST, API_URLS.cartages);

    const [
        addOrdersToCartage,
        { inProgress: isAddingOrdersToCartage }
    ] = useRequest<Cartage>(
        RequestMethod.POST,
        `${API_URLS.cartages}/${selectedCartageId}/add-orders`
    );

    const [
        fetchCartages,
        { inProgress: isFetchingCartages, response: cartages }
    ] = useRequest<Cartage[]>(
        RequestMethod.GET,
        API_URLS.cartages,
        CARTAGES_QUERY
    );

    const handleAddOrdersToCartage = () => {
        addOrdersToCartage({
            ordersIds: selectedOrdersIds
        })
            .then(cartage => {
                dispatch(
                    showNotification({
                        id: `add_orders_to_cartage_${Date.now()}`,
                        variant: NotificationVariant.Success,
                        text: i18next.t(
                            'cartagePlanningGroup.ordersAddedToCartageSuccess'
                        ),
                        link: generatePath(appPaths.cartage, {
                            cartageId: cartage._id
                        })
                    })
                );
            })
            .catch(() => {
                dispatch(
                    showNotification({
                        id: `add_orders_to__cartage_${Date.now()}`,
                        variant: NotificationVariant.Error,
                        text: i18next.t(
                            'cartagePlanningGroup.ordersAddedToCartageError'
                        )
                    })
                );
            })
            .finally(() => {
                dispatch(updateSelectedOrdersIds([]));
                sharedData?.fetchCartageGroupOrders();
            });
    };

    const handleCreateCartage = () => {
        createCartage({
            groupName: sharedData?.groupName,
            ordersIds: selectedOrdersIds
        })
            .then(cartage => {
                dispatch(
                    showNotification({
                        id: `create_cartage_${Date.now()}`,
                        variant: NotificationVariant.Success,
                        text: i18next.t(
                            'cartagePlanningGroup.createCartageSuccess'
                        ),
                        link: generatePath(appPaths.cartage, {
                            cartageId: cartage._id
                        })
                    })
                );
            })
            .catch(() => {
                dispatch(
                    showNotification({
                        id: `create_cartage_${Date.now()}`,
                        variant: NotificationVariant.Error,
                        text: i18next.t(
                            'cartagePlanningGroup.createCartageError'
                        )
                    })
                );
            })
            .finally(() => {
                dispatch(updateSelectedOrdersIds([]));
                sharedData?.fetchCartageGroupOrders();
            });
    };

    const handleSelectedCartageChange = evt => {
        setSelectedCartageId(evt.target.value);
    };

    useEffect(() => {
        fetchCartages();
    }, [fetchCartages]);

    const isAddingToExistingCartageBtnDisabled =
        !selectedOrdersIds.length ||
        selectedCartageId === CARTAGE_PLACEHOLDER_ID ||
        isAddingOrdersToCartage;

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%'
            }}
        >
            <h1>{sharedData?.groupName}</h1>
            <div
                style={{
                    display: 'flex'
                }}
            >
                <div
                    style={{
                        marginRight: '10px'
                    }}
                >
                    <Select
                        id="cartage"
                        name="cartage"
                        disabled={isFetchingCartages}
                        value={selectedCartageId}
                        onChange={handleSelectedCartageChange}
                        style={{ color: 'white' }}
                    >
                        <MenuItem value={CARTAGE_PLACEHOLDER_ID}>
                            <em>
                                {i18next.t(
                                    'cartagePlanningGroup.existingCartage'
                                )}
                            </em>
                        </MenuItem>
                        {cartages?.map(cartage => (
                            <MenuItem key={cartage._id} value={cartage._id}>
                                {cartage.name}
                            </MenuItem>
                        ))}
                    </Select>
                    <Tooltip
                        title={i18next.t(
                            'cartagePlanningGroup.addToExistingCartageTooltip'
                        )}
                        enterDelay={tooltipEnterDelay}
                    >
                        <Button
                            color="inherit"
                            disabled={isAddingToExistingCartageBtnDisabled}
                            onClick={handleAddOrdersToCartage}
                        >
                            {i18next.t(
                                'cartagePlanningGroup.addToExistingCartage'
                            )}
                        </Button>
                    </Tooltip>
                </div>
                <Tooltip
                    title={i18next.t(
                        'cartagePlanningGroup.createCartageTooltip'
                    )}
                    enterDelay={tooltipEnterDelay}
                >
                    <div>
                        <Button
                            color="inherit"
                            onClick={handleCreateCartage}
                            disabled={
                                !selectedOrdersIds.length || isCreatingCartage
                            }
                        >
                            <AddIcon />
                            {i18next.t('cartagePlanningGroup.createCartage')}
                        </Button>
                    </div>
                </Tooltip>
            </div>
        </div>
    );
};
