import {
    AppBar,
    Hidden,
    IconButton,
    Toolbar,
    Tooltip,
    Typography
} from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import PowerSettingsIcon from '@material-ui/icons/PowerSettingsNew';
import classNames from 'classnames';
import i18next from 'i18next';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { menuWidth, tooltipEnterDelay } from '../config';
import { OrderAppBarContainer } from '../order/appBar/OrderAppBarContainer';
import { OrderListAppBarContainer } from '../orderList/OrderListAppBarContainer';
import { ProductListAppBarView } from '../productList/ProductListAppBarView';
import { ProductAppBarView } from 'src/product/ProductAppBarView';
import { ShippingPlanningAppBarView } from 'src/shippingPlanning/ShippingPlanningAppBarView';
import { appPaths } from 'src/AppRouterService';
import { ShippingOrdersPrintingAppBarView } from 'src/shippingPlanning/ShippingOrdersPrintingAppBarView';
import { isTestingEnv } from 'src/common/EnvironmentService';
import { CartageGroupListAppBarView } from 'src/cartageGroups/CartageGroupListAppBarView';
import { CartagePlanningGroupAppBarView } from 'src/cartagePlanningGroup/CartagePlanningGroupAppBarView';
import { CartageAppBarView } from 'src/cartage/CartageAppBarView';
import { CartageListAppBarView } from 'src/cartageList/CartageListAppBarView';

interface Props extends WithStyles {
    onLogoutBtnClick: () => void;
    onNavIconClick: () => void;
}

const decorate = withStyles(theme => ({
    root: {
        marginLeft: menuWidth,
        [theme.breakpoints.up('lg')]: {
            width: `calc(100% - ${menuWidth}px)`
        }
    },
    toolbar: {
        justifyContent: 'space-between',
        [theme.breakpoints.up('lg')]: {
            justifyContent: 'flex-end'
        }
    },
    switch: {
        width: '100%'
    },
    logoutIcon: {
        marginLeft: theme.spacing(1)
    }
}));

const renderEmptySpan = () => <span />;

const AppBarLayoutView = decorate(
    ({ onLogoutBtnClick, onNavIconClick, classes }: Props) => (
        <AppBar
            className={classNames('no-print', classes.root)}
            position="fixed"
        >
            <Toolbar className={'no-print ' + classes.toolbar}>
                <Hidden lgUp>
                    <Tooltip
                        title={i18next.t('Show menu')}
                        enterDelay={tooltipEnterDelay}
                    >
                        <IconButton color="inherit" onClick={onNavIconClick}>
                            <MenuIcon />
                        </IconButton>
                    </Tooltip>
                </Hidden>
                {isTestingEnv() && (
                    <Typography
                        variant="h6"
                        color="error"
                        component="div"
                        style={{ flexGrow: 1 }}
                    >
                        Test_env
                    </Typography>
                )}
                <Switch>
                    <Route
                        exact
                        path="/order"
                        component={OrderListAppBarContainer}
                    />
                    <Route
                        path="/order/:orderId"
                        component={OrderAppBarContainer}
                    />
                    <Route
                        exact
                        path="/product"
                        component={ProductListAppBarView}
                    />
                    <Route
                        exact
                        path="/product/:productId"
                        component={ProductAppBarView}
                    />
                    <Route
                        exact
                        path={appPaths.shippingPlanning}
                        component={ShippingPlanningAppBarView}
                    />
                    <Route
                        exact
                        path={appPaths.shippingOrdersPrinting}
                        component={ShippingOrdersPrintingAppBarView}
                    />
                    <Route
                        exact
                        path={appPaths.cartageGroups}
                        component={CartageGroupListAppBarView}
                    />
                    <Route
                        exact
                        path={appPaths.cartagePlanning}
                        render={() => (
                            <CartageGroupListAppBarView hideAddGroupBtn />
                        )}
                    />
                    <Route
                        exact
                        path={appPaths.cartagePlanningGroup}
                        component={CartagePlanningGroupAppBarView}
                    />
                    <Route
                        exact
                        path={appPaths.cartageList}
                        component={CartageListAppBarView}
                    />
                    <Route
                        exact
                        path={appPaths.cartage}
                        component={CartageAppBarView}
                    />
                    <Route path="*" component={renderEmptySpan} />
                </Switch>
                <Tooltip
                    title={i18next.t('Logout')}
                    enterDelay={tooltipEnterDelay}
                >
                    <IconButton
                        className={classes.logoutIcon}
                        color="inherit"
                        onClick={onLogoutBtnClick}
                    >
                        <PowerSettingsIcon />
                    </IconButton>
                </Tooltip>
            </Toolbar>
        </AppBar>
    )
);

export { AppBarLayoutView };
