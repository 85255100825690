import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from '@material-ui/core';
import yellow from '@material-ui/core/colors/yellow';
import FitnessIcon from '@material-ui/icons/FitnessCenter';
import i18next from 'i18next';
import React from 'react';
import { LongLabel } from 'src/common/print/LongLabel';
import { ShortLabel } from 'src/common/print/ShortLabel';
import { Value } from 'src/common/print/Value';
import { Cartage } from 'src/models/Cartage';
import { OrderToShip } from 'src/shippingPlanning/ShippingPlanningService';
import { formatDate } from 'src/utils/formatters';
import { getOrderCurrencySymbol } from 'src/utils/price';
import { getCartageTotalCashValue, getCashValue } from './CartagePrintService';
import {
    getOrdersInfoById,
    getSequenceNumber
} from './SortableOrdersToShipListService';
import { containsOrderOversizedItem, getOrderBoxCount } from './CartageService';

interface Props {
    cartage: Cartage | undefined;
    orders: OrderToShip[];
    oversizedProductCodes: string[] | undefined;
}

export const CartagePrintView: React.FC<Props> = ({
    cartage,
    orders,
    oversizedProductCodes
}) => {
    if (!cartage || !orders) {
        return null;
    }

    const ordersCurrency = orders[0] ? getOrderCurrencySymbol(orders[0]) : '';
    return (
        <div className="print">
            <div className="print-page-header">
                <div style={{ textAlign: 'right' }}>
                    <img
                        alt=""
                        style={{ width: '250px' }}
                        src="/img/wilsondo_complet_horizontal_black.png"
                    />
                </div>
            </div>
            <table style={{ width: '100%' }}>
                <thead>
                    <tr>
                        <td>
                            <div className="print-page-header-space"></div>
                        </td>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td>
                            <div className="print-page">
                                <Grid
                                    container
                                    alignItems="center"
                                    style={{ marginTop: 0 }}
                                >
                                    <Grid item xs={6}>
                                        <Typography
                                            style={{ marginTop: 0 }}
                                            variant="h5"
                                            gutterBottom
                                        >
                                            {cartage.name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <LongLabel>
                                            {i18next.t('cartagePrint.date')}
                                        </LongLabel>
                                        <Value>
                                            {formatDate(cartage.shippingDate)}
                                        </Value>
                                    </Grid>
                                </Grid>
                                <Grid container alignItems="center">
                                    <Grid item xs={6}>
                                        <ShortLabel>
                                            {i18next.t('cartagePrint.driver')}
                                        </ShortLabel>
                                        <Value>{cartage.driver}</Value>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <LongLabel>
                                            {i18next.t('cartagePrint.car')}
                                        </LongLabel>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <ShortLabel>
                                            {i18next.t('cartagePrint.picker')}
                                        </ShortLabel>
                                        <Value>{cartage.picker}</Value>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <LongLabel>
                                            {i18next.t(
                                                'cartagePrint.cashTotal'
                                            )}
                                        </LongLabel>
                                        <Value>
                                            {getCartageTotalCashValue(orders)}{' '}
                                            {ordersCurrency}
                                        </Value>
                                    </Grid>
                                </Grid>
                                <Table
                                    style={{
                                        marginTop: '20px'
                                    }}
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell />
                                            <TableCell>
                                                {i18next.t(
                                                    'cartagePrint.customerName'
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {i18next.t('cartagePrint.note')}
                                            </TableCell>
                                            <TableCell>
                                                {i18next.t('cartagePrint.card')}
                                            </TableCell>
                                            <TableCell>
                                                {i18next.t('cartagePrint.cash')}
                                            </TableCell>
                                            <TableCell>
                                                {i18next.t(
                                                    'cartagePrint.packagesCount'
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {i18next.t(
                                                    'cartagePrint.customerSigning'
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {orders.map((order, index) => {
                                            const orderInfo = getOrdersInfoById(
                                                cartage.ordersInfo,
                                                order._id
                                            );
                                            const isOversized = containsOrderOversizedItem(
                                                order.items,
                                                oversizedProductCodes
                                            );
                                            return (
                                                <TableRow key={order._id}>
                                                    <TableCell>
                                                        <Value>
                                                            {getSequenceNumber(
                                                                orderInfo?.sequence,
                                                                index,
                                                                false
                                                            ) ?? '-'}
                                                        </Value>
                                                    </TableCell>
                                                    <TableCell
                                                        className="print-background"
                                                        style={
                                                            isOversized
                                                                ? {
                                                                      backgroundColor:
                                                                          yellow[200]
                                                                  }
                                                                : undefined
                                                        }
                                                    >
                                                        <div
                                                            style={{
                                                                display: 'flex'
                                                            }}
                                                        >
                                                            {isOversized && (
                                                                <FitnessIcon />
                                                            )}
                                                            <Value>
                                                                {
                                                                    order
                                                                        .customer
                                                                        .delivery
                                                                        .name
                                                                }
                                                            </Value>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell />
                                                    <TableCell />
                                                    <TableCell>
                                                        <Value>
                                                            {getCashValue(
                                                                order
                                                            )}
                                                        </Value>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Value>
                                                            {getOrderBoxCount(
                                                                order
                                                            )}
                                                        </Value>
                                                    </TableCell>
                                                    <TableCell />
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};
