import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { API_URLS } from 'src/common/ApiService';
import { useEnums } from 'src/common/enums/useEnumsHook';
import { useRequest } from 'src/common/useRequestHook';
import { Shipping } from 'src/shippingPlanning/ShippingPlanningService';
import { RequestMethod } from 'src/utils/request';
import { getOrdersCounts, getResolvedStatusesValues } from './OverviewService';
import { OrderStats } from './OverviewTypes';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid
} from '@material-ui/core';

const OPEN_ORDER_STATUSES = [
    'RECEIVED',
    'TO_ORDER',
    'ORDERED',
    'PROCESSED',
    'READY_TO_SHIP'
];

const SHIPPING_TYPES = [
    Shipping.PERSONAL_PICKUP,
    Shipping.COURIER_H,
    Shipping.COURIER_Z,
    Shipping.CARTAGE
];

const ALL_COUNTRIES = 'all';

const getCountryQuery = (country: string) => {
    if (country === ALL_COUNTRIES) {
        return undefined;
    }

    return { country };
};

export const OverviewView: React.FC = () => {
    const [country, setCountry] = useState(ALL_COUNTRIES);
    const enums = useEnums();
    const [
        fetchOpenOrdersStats,
        { response: ordersStatusStats = [] }
    ] = useRequest<OrderStats[]>(
        RequestMethod.GET,
        API_URLS.openOrdersStats,
        getCountryQuery(country)
    );

    const [
        fetchReadyToShipStats,
        { response: readyToShipStats = [] }
    ] = useRequest<OrderStats[]>(
        RequestMethod.GET,
        API_URLS.readyToShipOrdersStats,
        getCountryQuery(country)
    );

    useEffect(() => {
        fetchOpenOrdersStats();
        fetchReadyToShipStats();
    }, [fetchOpenOrdersStats, fetchReadyToShipStats]);

    const openOrdersData = {
        labels: getResolvedStatusesValues(
            enums.orderStatus,
            OPEN_ORDER_STATUSES
        ),
        datasets: [
            {
                label: i18next.t('Orders count'),
                data: getOrdersCounts(ordersStatusStats, OPEN_ORDER_STATUSES),
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1
            }
        ]
    };

    const readyToShipData = {
        labels: ['Osobné', 'Kuriér Hamričky', 'Kuriér Zemianska', 'Rozvoz'],
        datasets: [
            {
                label: i18next.t('Orders count'),
                data: getOrdersCounts(readyToShipStats, SHIPPING_TYPES),
                backgroundColor: 'rgba(75, 92, 192, 0.2)',
                borderColor: 'rgba(75, 92, 192, 1)',
                borderWidth: 1
            }
        ]
    };

    return (
        <>
            <h1 style={{ marginTop: '10px', marginBottom: '10px' }}>
                {i18next.t('Open orders')}
            </h1>

            <div>
                <div style={{ maxWidth: '70rem' }}>
                    <Grid item md={4} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="country">
                                {i18next.t('cartageGroup.country')}
                            </InputLabel>
                            <Select
                                id="country"
                                name="country"
                                value={country}
                                onChange={evt =>
                                    setCountry(evt.target.value as string)
                                }
                                fullWidth
                            >
                                <MenuItem key={'all'} value={ALL_COUNTRIES}>
                                    {i18next.t('all')}
                                </MenuItem>
                                <MenuItem key={'SK'} value={'SK'}>
                                    {i18next.t('SK')}
                                </MenuItem>
                                <MenuItem key={'HU'} value={'HU'}>
                                    {i18next.t('HU')}
                                </MenuItem>
                                <MenuItem key={'CZ'} value={'CZ'}>
                                    {i18next.t('CZ')}
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <h2 style={{ marginTop: '10px' }}>
                        {i18next.t('Orders status')}
                    </h2>
                    <Bar
                        data={openOrdersData}
                        options={{
                            scales: {
                                yAxes: [
                                    {
                                        ticks: {
                                            beginAtZero: true
                                        }
                                    }
                                ]
                            }
                        }}
                    />
                </div>
                <div style={{ maxWidth: '70rem' }}>
                    <h2 style={{ marginTop: '10px' }}>
                        {i18next.t('Ready to ship orders shipping')}
                    </h2>
                    <Bar
                        data={readyToShipData}
                        options={{
                            scales: {
                                yAxes: [
                                    {
                                        ticks: {
                                            beginAtZero: true
                                        }
                                    }
                                ]
                            }
                        }}
                    />
                </div>
            </div>
        </>
    );
};
