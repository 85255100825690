import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid
} from '@material-ui/core';
import { StyleRules, withStyles, WithStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import classNames from 'classnames';
import i18next from 'i18next';
import React from 'react';
import { EnumsState } from '../common/enums/EnumsState';
import { Shop } from '../models/Shop';
import { renderEnumOptions } from '../utils/enums';
import { getShopUrlForDialog, isShopUrlDisabled } from './ShopListService';

const decorate = withStyles(theme => {
    const style: StyleRules = {
        container: {
            display: 'flex',
            flexWrap: 'wrap'
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1)
        }
    };
    return style;
});

interface Props extends WithStyles {
    open: boolean;
    shop: Shop;
    enums: EnumsState;
    onCancel: () => void;
    onSubmit: () => void;
    onShopChange: (name: string, value: string) => void;
}

const ShopDialog = decorate(
    ({
        open,
        shop,
        enums,
        onCancel,
        onSubmit,
        onShopChange,
        classes
    }: Props) => {
        const onChange = event => {
            onShopChange(event.target.name, event.target.value);
        };

        return (
            <Dialog open={open} onClose={onCancel}>
                <DialogTitle>
                    {shop._id
                        ? i18next.t('Edit shop')
                        : i18next.t('Add new shop')}
                </DialogTitle>
                <DialogContent>
                    <form className={classes.container} autoComplete="off">
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <TextField
                                    name="type"
                                    className={classes.textField}
                                    label={i18next.t('Type')}
                                    select
                                    margin="normal"
                                    onChange={onChange}
                                    value={shop.type}
                                    fullWidth
                                    autoFocus
                                >
                                    {renderEnumOptions(enums.shopType.enum)}
                                </TextField>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    name="country"
                                    className={classes.textField}
                                    label={i18next.t('Country')}
                                    select
                                    margin="normal"
                                    onChange={onChange}
                                    value={shop.country}
                                    fullWidth
                                >
                                    {renderEnumOptions(enums.country.enum)}
                                </TextField>
                            </Grid>
                        </Grid>
                        <TextField
                            name="name"
                            className={classes.textField}
                            label={i18next.t('Name')}
                            type="text"
                            margin="normal"
                            onChange={onChange}
                            value={shop.name}
                            fullWidth
                        />
                        <TextField
                            name="url"
                            disabled={isShopUrlDisabled(shop)}
                            className={classNames(classes.textField)}
                            label={i18next.t('Import URL')}
                            type="text"
                            margin="normal"
                            onChange={onChange}
                            value={getShopUrlForDialog(shop)}
                            fullWidth
                        />
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={onCancel}
                        color="secondary"
                        variant="contained"
                    >
                        {i18next.t('Cancel')}
                    </Button>
                    <Button
                        onClick={onSubmit}
                        color="primary"
                        variant="contained"
                    >
                        {i18next.t('Save')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
);

export { ShopDialog };
