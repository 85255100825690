import CancelIcon from '@material-ui/icons/Cancel';
import { Tooltip, IconButton } from '@material-ui/core';
import i18next from 'i18next';
import React from 'react';
import { tooltipEnterDelay } from 'src/config';
import { isOrderCanceled } from '../OrderService';
import { Order } from 'src/models/Order';
import { useDispatch } from 'react-redux';
import { orderStatus } from 'src/common/enums/enums';
import { useConfirmationDialog } from 'src/common/confirmationDialog/useConfirmationDialog';
import { saveOrder } from '../OrderActions';

interface Props {
    order: Order;
    className: string;
}

export const CancelOrderButton: React.FC<Props> = ({ className, order }) => {
    const dispatch = useDispatch();
    const confirm = useConfirmationDialog();

    if (isOrderCanceled(order)) {
        return null;
    }

    const handleCancelOrder = async () => {
        if (!(await confirm(i18next.t('order.cancelOrderConfirmation')))) {
            return;
        }

        dispatch(
            saveOrder({
                ...order,
                status: orderStatus.CANCELED
            })
        );
    };

    return (
        <Tooltip
            title={i18next.t('Cancel order')}
            enterDelay={tooltipEnterDelay}
        >
            <div className={className}>
                <IconButton color="inherit" onClick={handleCancelOrder}>
                    <CancelIcon />
                </IconButton>
            </div>
        </Tooltip>
    );
};
