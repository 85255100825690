import React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../models/AppState';
import { Notification } from '../../models/Notification';
import { hideNotification } from './NotificationsActions';
import { NotificationsView } from './NotificationsView';

interface Props {
    notifications: Notification[];
    onHideNotification: (id: string) => void;
}

export class NotificationsContainerBase extends Component<Props> {
    render() {
        const { notifications, onHideNotification } = this.props;
        return (
            <NotificationsView
                notifications={notifications}
                onHideNotification={onHideNotification}
            />
        );
    }
}

const mapStateToProps = (state: AppState) => ({
    notifications: state.notifications.notifications
});

const mapDispatchToProps = {
    onHideNotification: hideNotification
};

const NotificationsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(NotificationsContainerBase);

export { NotificationsContainer };
