import { country, shopType } from '../common/enums/enums';
import { Shop } from '../models/Shop';

export const defaultShop: Shop = {
    name: '',
    type: shopType.WEBNODE,
    country: country.SK,
    url: ''
};

export function getShopUrlForDialog(shop: Shop) {
    if (shop.type === shopType.WEBNODE) {
        return '';
    }
    return shop.url;
}

export function isShopUrlDisabled(shop: Shop) {
    return shop.type === shopType.WEBNODE;
}
