import i18next from 'i18next';
import { OrderFlag } from 'src/models/Order';
import { Shipping } from 'src/shippingPlanning/ShippingPlanningService';
import { EnumsState } from '../../common/enums/EnumsState';
import { resolveEnumValue } from '../../utils/enums';
import { formatDate } from 'src/utils/formatters';

export function formatPath(path: string[], traslationScope: string): string {
    return path
        .map(field => i18next.t(`history.fields.${traslationScope}.${field}`))
        .join(' -> ');
}

export function hasHistory(history) {
    return history && history.length > 0;
}

export function isObject(o) {
    return o !== null && typeof o === 'object';
}

function formatBoolean(value: boolean): string {
    if (value) {
        return i18next.t('yes');
    }

    return i18next.t('no');
}

function formatSimplyValue(value) {
    if (Array.isArray(value) && value.length === 0) {
        return '[]';
    }

    if (typeof value === 'boolean') {
        return formatBoolean(value);
    }

    return value?.toString() ?? '';
}

function isProductItem(path: string[]) {
    return path.includes('items');
}
interface Formatter {
    isField: (path: string[]) => boolean;
    format: (value, enums: EnumsState) => string;
}

const productStatusFormatter: Formatter = {
    isField: path => path.length === 1 && path.includes('status'),
    format: (value, enums) => resolveEnumValue(enums.orderStatus.enum, value)
};

const itemStatusFormatter: Formatter = {
    isField: path => isProductItem(path) && path.includes('status'),
    format: (value, enums) => resolveEnumValue(enums.productStatus.enum, value)
};

const discountTypeFormatter: Formatter = {
    isField: path => path.includes('discountType'),
    format: (value, enums) => resolveEnumValue(enums.discountType.enum, value)
};

const callStatusFormatter: Formatter = {
    isField: path => path.includes('callStatus'),
    format: (value, enums) =>
        resolveEnumValue(enums.orderCallStatus.enum, value)
};

const deliveryTypeFormatter: Formatter = {
    isField: path => path.includes('delivery') && path.includes('type'),
    format: (value, enums) => resolveEnumValue(enums.deliveryType.enum, value)
};

const billingTypeFormatter: Formatter = {
    isField: path => path.includes('billing') && path.includes('type'),
    format: (value, enums) => resolveEnumValue(enums.billingType.enum, value)
};

const shippingTypeFormatter: Formatter = {
    isField: path => path.includes('shipping'),
    format: value => {
        if (value === Shipping.COURIER_H) {
            return i18next.t('history.formatter.courierHamricky');
        }

        if (value === Shipping.COURIER_Z) {
            return i18next.t('history.formatter.courierZemianska');
        }

        if (value === Shipping.CARTAGE) {
            return i18next.t('history.formatter.cartage');
        }

        return value;
    }
};

const flagsFormatter: Formatter = {
    isField: path => path.includes('flags'),
    format: value => {
        if (value === OrderFlag.DoubleCheck) {
            return i18next.t('history.formatter.doubleChangeFlag');
        }

        if (value === OrderFlag.PriceChanged) {
            return i18next.t('history.formatter.priceChangeFlag');
        }

        return value;
    }
};

const dateFormatter: Formatter = {
    isField: path => path.includes('expectedShippingDate'),
    format: value => {
        if (!value) {
            return '';
        }

        return formatDate(value);
    }
};

const formatters: Formatter[] = [
    productStatusFormatter,
    itemStatusFormatter,
    discountTypeFormatter,
    callStatusFormatter,
    deliveryTypeFormatter,
    billingTypeFormatter,
    shippingTypeFormatter,
    flagsFormatter,
    dateFormatter
];

export function formatValue(
    value: any = null,
    path: string[],
    enums: EnumsState
) {
    const formatter = formatters.find(f => f.isField(path));

    if (formatter) {
        return formatSimplyValue(formatter.format(value, enums));
    }

    return formatSimplyValue(value);
}

export function isRichNote(value: any): boolean {
    return value?.startsWith?.('{"blocks":[');
}
