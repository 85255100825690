import { Action, handleActions } from 'redux-actions';
import { loadFromStorage, saveToStorage } from '../common/localStorage';
import * as actions from './ProductListActions';
import { ProductListState } from './ProductListState';

const queryLocalStorageKey = 'productList-query';

const defaultQuery = {
    search: ''
};

const defaultState: ProductListState = {
    query: {
        ...defaultQuery,
        ...loadFromStorage(queryLocalStorageKey)
    }
};

const ProductListReducer = handleActions<ProductListState, any>(
    {
        [actions.updateQuery.toString()]: (state, action: Action<any>) => {
            const query = { ...state.query, ...action.payload.query };

            saveToStorage(queryLocalStorageKey, query);
            return {
                ...state,
                query
            };
        }
    },
    defaultState
);

export { ProductListReducer };
