import { handleActions } from 'redux-actions';
import { showError } from '../errors/ErrorsActions';
import { isNotificationError } from '../errors/ErrorsService';
import * as actions from './NotificationsActions';
import {
    addAndRemoveOldNotification,
    convertErrorToNotification,
    removeNotification
} from './NotificationsService';
import { NotificationsState } from './NotificationsState';

const defaultState: NotificationsState = {
    notifications: []
};

const NotificationsReducer = handleActions<NotificationsState, any>(
    {
        [actions.showNotification.toString()]: (state, action) => ({
            ...state,
            notifications: addAndRemoveOldNotification(
                state.notifications,
                action.payload.notification
            )
        }),
        [actions.hideNotification.toString()]: (state, action) => ({
            ...state,
            notifications: removeNotification(
                state.notifications,
                action.payload.id
            )
        }),
        [showError.toString()]: (state, action) => {
            const error = action.payload.error;

            if (isNotificationError(error)) {
                return {
                    ...state,
                    notifications: addAndRemoveOldNotification(
                        state.notifications,
                        convertErrorToNotification(error)
                    )
                };
            }
            return state;
        }
    },
    defaultState
);

export { NotificationsReducer };
