import { FormControl, FormLabel, Grid, Typography } from '@material-ui/core';
import i18next from 'i18next';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { API_URLS } from 'src/common/ApiService';
import { useRequest } from 'src/common/useRequestHook';
import { JDatePicker } from 'src/components/JDatePicker';
import { formatDate } from 'src/utils/formatters';
import { RequestMethod } from 'src/utils/request';
import {
    CourierOverview,
    formatQueryDate,
    transformCourierOverviewToGraphData
} from './CourierShippingOverviewService';

interface FilterQuery {
    from: Moment;
    to: Moment;
}

export const CourierShippingOverview: React.FC = () => {
    const [query, setQuery] = useState<FilterQuery>({
        to: moment(),
        from: moment().add('days', -13)
    });

    const [fetchCourierOrdersOverview, { response: orders = [] }] = useRequest<
        CourierOverview[]
    >(RequestMethod.GET);

    const data = transformCourierOverviewToGraphData(orders);

    useEffect(() => {
        fetchCourierOrdersOverview(
            undefined,
            `${API_URLS.courierShippingOverview}?from=${formatQueryDate(
                query.from
            )}&to=${formatQueryDate(query.to)}`
        );
    }, [query, fetchCourierOrdersOverview]);

    const openOrdersData = {
        labels: data.map(({ date }) => formatDate(date)),
        datasets: [
            {
                label: i18next.t('courierOrdersOverview.hamricky'),
                data: data.map(({ hamrickyCount }) => hamrickyCount),
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1
            },
            {
                label: i18next.t('courierOrdersOverview.zemianska'),
                data: data.map(({ zemianskaCount }) => zemianskaCount),
                backgroundColor: 'rgba(75, 92, 192, 0.2)',
                borderColor: 'rgba(75, 92, 192, 1)',
                borderWidth: 1
            }
        ]
    };

    const hamrickySum = data.reduce(
        (sum, { hamrickyCount }) => sum + hamrickyCount,
        0
    );
    const zemianskaSum = data.reduce(
        (sum, { zemianskaCount }) => sum + zemianskaCount,
        0
    );

    const handleFilterQueryDateFromUpdate = (from: Moment) => {
        setQuery({ ...query, from });
    };

    const handleFilterQueryDateToUpdate = (to: Moment) => {
        setQuery({ ...query, to });
    };

    return (
        <>
            <h1 style={{ marginTop: '10px', marginBottom: '10px' }}>
                {i18next.t('courierOrdersOverview.title')}
            </h1>
            <FormControl margin="normal">
                <FormLabel component="legend">
                    {i18next.t('courierOrdersOverview.shippingDate')}
                </FormLabel>
                <Grid container>
                    <JDatePicker
                        clearable
                        disableFuture
                        label={i18next.t('From')}
                        value={query.from}
                        onChange={handleFilterQueryDateFromUpdate}
                        style={{ marginRight: 10 }}
                    />
                    <JDatePicker
                        clearable
                        disableFuture
                        label={i18next.t('To')}
                        value={query.to}
                        onChange={handleFilterQueryDateToUpdate}
                    />
                </Grid>
            </FormControl>

            <div>
                <Typography variant="body2">
                    {i18next.t('courierOrdersOverview.sumHamricky') +
                        ' ' +
                        hamrickySum}
                </Typography>
                <Typography variant="body2">
                    {i18next.t('courierOrdersOverview.sumZemianska') +
                        ' ' +
                        zemianskaSum}
                </Typography>
            </div>
            <div style={{ maxWidth: '70rem' }}>
                <Bar
                    data={openOrdersData}
                    options={{
                        scales: {
                            yAxes: [
                                {
                                    id: 'itemsCount',
                                    type: 'linear',
                                    ticks: {
                                        beginAtZero: true,
                                        precision: 0
                                    },
                                    position: 'left',
                                    gridLines: {
                                        display: false
                                    }
                                }
                            ]
                        }
                    }}
                />
            </div>
        </>
    );
};
