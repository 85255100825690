import React, { useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    Paper
} from '@material-ui/core';
import i18next from 'i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { useFetchedItems } from '../common/useFetchedItemsHook';
import { API_URLS } from '../common/ApiService';
import { OrdersToShipItems } from './CartageItems';
import { Cartage, CartageStatus } from 'src/models/Cartage';
import { SHARED_DATA_KEY } from 'src/common/sharedData/SharedDataActions';
import { useSharedData } from 'src/common/sharedData/useSharedData';
import { useSession } from 'src/common/session/useSession';
import { userRole } from 'src/common/enums/enums';

export const CartageList: React.FC = () => {
    const { user } = useSession();
    const [cartageStatus = CartageStatus.INITIAL] = useSharedData<
        CartageStatus
    >(SHARED_DATA_KEY.cartageList);

    const {
        items,
        isLoading,
        hasMore,
        fetchItems,
        fetchNextItems
    } = useFetchedItems<Cartage>(API_URLS.cartages);

    useEffect(() => {
        if (user.role === userRole.DRIVER) {
            fetchItems({
                status: [CartageStatus.READY_TO_SHIP],
                driver: user.username
            });
            return;
        }
        fetchItems({ status: [cartageStatus] });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cartageStatus]);

    return (
        <TableContainer component={Paper}>
            <Table className="table">
                <TableHead>
                    <TableRow>
                        <TableCell>{i18next.t('cartageList.name')}</TableCell>
                        <TableCell>
                            {i18next.t('cartageList.createdDate')}
                        </TableCell>
                        <TableCell>
                            {i18next.t('cartageList.shippingDate')}
                        </TableCell>
                        <TableCell>{i18next.t('cartageList.driver')}</TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <InfiniteScroll
                    element={TableBody}
                    initialLoad={false}
                    loadMore={fetchNextItems}
                    hasMore={hasMore}
                >
                    <OrdersToShipItems
                        isLoading={isLoading}
                        hasMore={hasMore}
                        items={items}
                    />
                </InfiniteScroll>
            </Table>
        </TableContainer>
    );
};
