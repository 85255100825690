import {
    FormControl,
    Grid,
    InputLabel,
    Select,
    TextField
} from '@material-ui/core';
import i18next from 'i18next';
import React from 'react';
import { ChangeEvent, Component } from 'react';
import { Enum } from '../models/Enum';
import { Customer } from '../models/Order';
import { renderEnumOptions } from '../utils/enums';

interface Props {
    disabled?: boolean;
    customer: Customer;
    namePrefix: string;
    countryEnum: Enum;
    onChange: (event: ChangeEvent<HTMLElement>) => void;
}

export class CustomerView extends Component<Props> {
    render() {
        const { customer, namePrefix, onChange, disabled } = this.props;

        return (
            <div
                style={{
                    width: '100%'
                }}
            >
                <Grid container spacing={3}>
                    <Grid item xl={4} md={6} xs={12}>
                        <TextField
                            label={i18next.t('Name')}
                            value={customer.name || ''}
                            name={`${namePrefix}.name`}
                            onChange={onChange}
                            disabled={disabled}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xl={4} md={6} xs={12}>
                        <TextField
                            label={i18next.t('Phone')}
                            value={customer.phone || ''}
                            name={`${namePrefix}.phone`}
                            onChange={onChange}
                            disabled={disabled}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xl={4} md={12} xs={12}>
                        <TextField
                            label={i18next.t('Email')}
                            value={customer.email || ''}
                            name={`${namePrefix}.email`}
                            onChange={onChange}
                            disabled={disabled}
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xl={4} md={12} xs={12}>
                        <TextField
                            label={i18next.t('Company')}
                            value={customer.company || ''}
                            name={`${namePrefix}.company`}
                            onChange={onChange}
                            disabled={disabled}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xl={4} md={6} xs={12}>
                        <TextField
                            label={i18next.t('Company ID')}
                            value={customer.companyId || ''}
                            name={`${namePrefix}.companyId`}
                            onChange={onChange}
                            disabled={disabled}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xl={4} md={6} xs={12}>
                        <TextField
                            label={i18next.t('VAT ID')}
                            value={customer.vatId || ''}
                            name={`${namePrefix}.vatId`}
                            onChange={onChange}
                            disabled={disabled}
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xl={4} md={12} xs={12}>
                        <TextField
                            label={i18next.t('Street')}
                            value={customer.street || ''}
                            name={`${namePrefix}.street`}
                            onChange={onChange}
                            disabled={disabled}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xl={4} md={6} xs={12}>
                        <TextField
                            label={i18next.t('City')}
                            value={customer.city || ''}
                            name={`${namePrefix}.city`}
                            onChange={onChange}
                            disabled={disabled}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xl={4} md={6} xs={12}>
                        <TextField
                            label={i18next.t('ZIP')}
                            value={customer.zip || ''}
                            name={`${namePrefix}.zip`}
                            onChange={onChange}
                            disabled={disabled}
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                        <TextField
                            label={i18next.t('State_country')}
                            value={customer.state || ''}
                            name={`${namePrefix}.state`}
                            onChange={onChange}
                            disabled={disabled}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="country">
                                {i18next.t('Country')}
                            </InputLabel>
                            <Select
                                id="country"
                                value={customer.country || ''}
                                name={`${namePrefix}.country`}
                                // @ts-ignore select
                                onChange={onChange}
                                disabled={disabled}
                                fullWidth
                            >
                                {renderEnumOptions(this.props.countryEnum)}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </div>
        );
    }
}
