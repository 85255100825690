import { Moment } from 'moment';
import { Shipping } from 'src/shippingPlanning/ShippingPlanningService';

export interface CourierOverview {
    shipping: Shipping.COURIER_Z | Shipping.COURIER_H;
    date: string;
    count: number;
}

interface OrderCounts {
    zemianskaCount: number;
    hamrickyCount: number;
}

interface CourierOverviewGraph extends OrderCounts {
    date: string;
}

export const formatQueryDate = (date: Moment) =>
    date ? date.format('YYYY-MM-DD') : undefined;

export const transformCourierOverviewToGraphData = (
    data: CourierOverview[]
): CourierOverviewGraph[] => {
    const obj: { [key: string]: OrderCounts } = {};
    data.forEach(({ date, count, shipping }) => {
        const orderCounts = obj[date] ?? {
            zemianskaCount: 0,
            hamrickyCount: 0
        };
        if (shipping === Shipping.COURIER_Z) {
            orderCounts.zemianskaCount += count;
        }

        if (shipping === Shipping.COURIER_H) {
            orderCounts.hamrickyCount += count;
        }

        obj[date] = orderCounts;
    });

    return Object.entries(obj)
        .map(([date, orderCounts]) => ({
            date,
            ...orderCounts
        }))
        .sort(
            ({ date: dateA }, { date: dateB }) =>
                new Date(dateA).valueOf() - new Date(dateB).valueOf()
        );
};
