import barcodeScanListener from 'barcode-scan-listener';

export const orderBarcodeValuePrefix = 'J%';

export function onOrderScan(
    listener: (orderNumber: string) => void
): () => void {
    const removeListener = barcodeScanListener.onScan(
        {
            barcodePrefix: orderBarcodeValuePrefix,
            barcodeValueTest: /^(\d{9}|\d{8})$/,
            finishScanOnMatch: false,
            scanDuration: 50
        },
        async (orderNumber: string) => {
            listener(orderNumber);
        }
    );

    return removeListener;
}
