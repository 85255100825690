import {
    Button,
    Grid,
    TableHead,
    Table,
    TableBody,
    TableRow,
    TableCell,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TableContainer,
    Paper
} from '@material-ui/core';
import { KeyboardTimePicker } from '@material-ui/pickers';
import ScheduleIcon from '@material-ui/icons/Schedule';
import i18next from 'i18next';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useRequest } from 'src/common/useRequestHook';
import { JDatePicker } from 'src/components/JDatePicker';
import { AppState } from 'src/models/AppState';
import { loadUsers } from 'src/userList/UserListActions';
import { RequestMethod } from 'src/utils/request';

interface OrderChangelog {
    _id: string;
    orderNumber: string;
}

const FIELDS = [
    { value: 'status', label: 'stav' },
    { value: 'discountType', label: 'typ zľavy' },
    { value: 'discount', label: 'zľava' },
    { value: 'shopNote', label: 'poznámka' },
    { value: 'callStatus', label: 'telefonát zákazníkovi' },
    { value: 'customer', label: 'zákazník' },
    { value: 'items', label: 'položky' },
    { value: 'billing', label: 'platba' },
    { value: 'delivery', label: 'doručenie' },
    { value: 'totalPriceWithTax', label: 'celková cena' },
    { value: 'invoice', label: 'faktúra' },
    { value: 'isPaid', label: 'zaplatená' },
    { value: 'shipping', label: 'spôsob doručenia' },
    { value: 'flags', label: 'označenie' }
];

function getChangelogUrl(
    startDate?: Moment,
    endDate?: Moment,
    user?: string,
    field?: string
): string {
    return `/order/export/user-changelog?startDate=${startDate?.toISOString()}&endDate=${endDate?.toISOString()}&user=${user}&field=${field}`;
}

function formatOrdersCount(ordersCount): string {
    if (ordersCount === undefined) {
        return '';
    }

    return ` (${ordersCount})`;
}

export const UserChangelogView: React.FC = () => {
    const dispatch = useDispatch();

    const { isUserListLoading, userList } = useSelector((state: AppState) => ({
        isUserListLoading: state.userList.isLoading,
        userList: state.userList.userList
    }));

    const [startDate, setStartDate] = useState<Moment>(
        moment()
            .hours(0)
            .minutes(0)
    );
    const [endDate, setEndDate] = useState<Moment>(
        moment()
            .hours(23)
            .minutes(0)
    );
    const [field, setField] = useState('status');
    const [username, setUsername] = useState('');
    const [fetchChangelog, { inProgress, response }] = useRequest<
        OrderChangelog[]
    >(RequestMethod.GET, getChangelogUrl(startDate, endDate, username, field));

    useEffect(() => {
        if (userList.length) {
            return;
        }

        dispatch(loadUsers());
    }, [dispatch, userList.length]);

    useEffect(() => {
        if (username) {
            return;
        }

        setUsername(userList[0]?.username);
    }, [userList, username]);

    const areAllQueryParamSet = username && field && startDate && endDate;
    return (
        <>
            <h1 style={{ marginTop: '10px', marginBottom: '10px' }}>
                {i18next.t('userChangelog.heading')}
            </h1>
            <Grid container alignItems="center">
                <Grid item xs={3}>
                    <FormControl>
                        <InputLabel htmlFor="user">
                            {i18next.t('userChangelog.user')}
                        </InputLabel>
                        <Select
                            id="user"
                            name="user"
                            value={username}
                            disabled={isUserListLoading}
                            onChange={evt =>
                                setUsername(evt.target.value as string)
                            }
                            fullWidth
                        >
                            {userList.map(({ username }) => (
                                <MenuItem key={username} value={username}>
                                    {username}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl>
                        <InputLabel htmlFor="field">
                            {i18next.t('userChangelog.field')}
                        </InputLabel>
                        <Select
                            id="field"
                            name="field"
                            value={field}
                            onChange={evt =>
                                setField(evt.target.value as string)
                            }
                            fullWidth
                        >
                            {FIELDS.map(({ value, label }) => (
                                <MenuItem key={value} value={value}>
                                    {label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <JDatePicker
                        disableFuture
                        label={i18next.t('userChangelog.startDate')}
                        value={startDate}
                        onChange={date => {
                            if (date?.isValid() === false) {
                                return;
                            }
                            setStartDate(date);
                        }}
                    />
                    <KeyboardTimePicker
                        margin="normal"
                        keyboardIcon={<ScheduleIcon />}
                        id="time-picker"
                        label="Čas"
                        ampm={false}
                        value={startDate}
                        onChange={date => {
                            if (date?.isValid() === false) {
                                return;
                            }
                            setStartDate(date);
                        }}
                        KeyboardButtonProps={{
                            'aria-label': 'change time'
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <JDatePicker
                        disableFuture
                        label={i18next.t('userChangelog.endDate')}
                        value={endDate}
                        onChange={date => {
                            if (date?.isValid() === false) {
                                return;
                            }
                            setEndDate(date);
                        }}
                    />
                    <KeyboardTimePicker
                        margin="normal"
                        keyboardIcon={<ScheduleIcon />}
                        id="time-picker"
                        label="Čas"
                        ampm={false}
                        value={endDate}
                        onChange={date => {
                            if (date?.isValid() === false) {
                                return;
                            }
                            setEndDate(date);
                        }}
                        KeyboardButtonProps={{
                            'aria-label': 'change time'
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ marginLeft: '10px' }}
                        onClick={() => fetchChangelog()}
                        disabled={inProgress || !areAllQueryParamSet}
                    >
                        {i18next.t('userChangelog.fetchChangelogButton')}
                    </Button>
                </Grid>
            </Grid>
            <h2 style={{ marginTop: '10px', marginBottom: '10px' }}>
                {i18next.t('userChangelog.orderListHeading')}
                {formatOrdersCount(response?.length)}
            </h2>
            <TableContainer component={Paper}>
                <Table className="table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                {i18next.t('userChangelog.orderNumber')}
                            </TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!response && (
                            <TableRow>
                                <TableCell
                                    colSpan={2}
                                    style={{ textAlign: 'center' }}
                                >
                                    {i18next.t(
                                        'userChangelog.noDataFetchedYet'
                                    )}
                                </TableCell>
                            </TableRow>
                        )}
                        {response?.length === 0 && (
                            <TableRow>
                                <TableCell
                                    colSpan={2}
                                    style={{ textAlign: 'center' }}
                                >
                                    {i18next.t(
                                        'userChangelog.noDataMatchedCriteria'
                                    )}
                                </TableCell>
                            </TableRow>
                        )}
                        {response?.map(({ _id, orderNumber }) => (
                            <TableRow key={_id}>
                                <TableCell className="numberOrder">
                                    {orderNumber}
                                </TableCell>
                                <TableCell>
                                    <NavLink to={`/order/${_id}`}>
                                        {i18next.t('userChangelog.display')}
                                    </NavLink>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};
