import { billingType } from 'src/common/enums/enums';
import { InvoiceType, Order } from 'src/models/Order';

const hasAccountRightsForOrder = (
    order: Order | undefined,
    hasAccountantRights: boolean
) => {
    if (!order) {
        return false;
    }
    if (hasAccountantRights) {
        return true;
    }

    if (order.isClaim || order.isClone) {
        return true;
    }

    return false;
};

export const isPriceChangeConfirmationAllowed = (
    order: Order,
    hasAccountantRights: boolean
): boolean => {
    if (hasAccountRightsForOrder(order, hasAccountantRights)) {
        return true;
    }

    if (
        [billingType.CASH_ON_DELIVERY, billingType.CASH_ON_PLACE].includes(
            order.billing.type
        ) &&
        !order.billNumber &&
        order?.invoice?.type !== InvoiceType.Regular
    ) {
        return true;
    }

    return false;
};

export const isIsPaidChangeAllowed = (
    order: Order | undefined,
    hasAccountantRights: boolean
): boolean => {
    if (hasAccountRightsForOrder(order, hasAccountantRights)) {
        return true;
    }

    if (order.billing.type === billingType.GOPAY) {
        return true;
    }

    return false;
};

export const isWithoutInvoiceChangeAllowed = (
    order: Order | undefined,
    hasAccountantRights: boolean
): boolean => {
    return hasAccountRightsForOrder(order, hasAccountantRights);
};

export const getDisabledBillingCodes = (
    order: Order | undefined,
    hasAccountantRights: boolean
): string[] => {
    if (!order || order.isClaim || order.isClone || hasAccountantRights) {
        return [];
    }

    return [billingType.GOPAY];
};
