import { Model } from './Model';

export function replaceItemInArray<T extends Model>(arr: T[], model: T): T[] {
    const modelIndex = arr.findIndex(m => m._id === model._id);
    if (modelIndex > -1) {
        const newArr = [...arr];
        newArr[modelIndex] = model;
        return newArr;
    }
    return arr;
}

function itemIsInArray(itemIndex) {
    return itemIndex > -1;
}

export function toggleArrayItem(array: any[], item: any, addItem?: boolean) {
    const newArray = [...array];
    const itemIndex = newArray.indexOf(item);

    if (
        // tslint:disable-next-line no-boolean-literal-compare
        (itemIsInArray(itemIndex) && addItem === true) ||
        // tslint:disable-next-line no-boolean-literal-compare
        (!itemIsInArray(itemIndex) && addItem === false)
    ) {
        return newArray;
    }

    if (itemIsInArray(itemIndex)) {
        newArray.splice(itemIndex, 1);
    } else {
        newArray.push(item);
    }
    return newArray;
}

export function onlyUnique(
    value: string,
    index: number,
    array: string[]
): boolean {
    return array.indexOf(value) === index;
}
