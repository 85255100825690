import i18next from 'i18next';
import { productStatus } from 'src/common/enums/enums';
import { Order, ProductItem } from 'src/models/Order';

const MINIMAL_PRODUCT_QUANTITY = 1;
const isItemPriceInvalid = (price?: number): boolean => {
    return price === null || price === undefined || !(price >= 0);
};
const hasSomeItemInvalidPriceOrQuantity = (items: ProductItem[]): boolean =>
    items.some(
        ({ unitPriceWithTax, quantity }) =>
            quantity < MINIMAL_PRODUCT_QUANTITY ||
            isItemPriceInvalid(unitPriceWithTax)
    );

const hasSetMarkAsStockedWithoutAllSubItemsStocked = (
    items: ProductItem[]
): boolean => {
    return items.some(({ subItems, status }) => {
        if (!subItems) {
            return false;
        }

        if (status !== productStatus.STOCKED) {
            return false;
        }

        return subItems.some(({ status }) => status !== productStatus.STOCKED);
    });
};

export const getOrderValidationError = (order: Order): string | undefined => {
    if (hasSomeItemInvalidPriceOrQuantity(order.items)) {
        return i18next.t('orderValidation.itemsPriceOrCountIsMissing');
    }

    if (hasSetMarkAsStockedWithoutAllSubItemsStocked(order.items)) {
        return i18next.t('orderValidation.notAllSetItemsStocked');
    }

    return undefined;
};
