import i18next from 'i18next';
import { DeliveryConfirmationStatus } from 'src/models/DeliveryConfirmation';

type Severity = 'error' | 'warning' | 'success' | 'info';
interface TextAndSeverity {
    severity: Severity;
    text: string;
}

const statusTextAndSeverityMap = {
    [DeliveryConfirmationStatus.DELIVERED]: {
        severity: 'success',
        getText: () =>
            i18next.t(
                'cartageShippingPlanning.smsDeliveryConfirmation.status_DELIVERED'
            )
    },
    [DeliveryConfirmationStatus.BUFFERED_ON_SMC]: {
        severity: 'warning',
        getText: () =>
            i18next.t(
                'cartageShippingPlanning.smsDeliveryConfirmation.status_BUFFERED_ON_SMC'
            )
    },
    [DeliveryConfirmationStatus.NOT_DELIVERED]: {
        severity: 'error',
        getText: () =>
            i18next.t(
                'cartageShippingPlanning.smsDeliveryConfirmation.status_NOT_DELIVERED'
            )
    },
    [DeliveryConfirmationStatus.INCOMING_ANSWER]: {
        severity: 'success',
        getText: () =>
            i18next.t(
                'cartageShippingPlanning.smsDeliveryConfirmation.status_ANSWER'
            )
    },
    [DeliveryConfirmationStatus.SEEN]: {
        severity: 'success',
        getText: () =>
            i18next.t(
                'cartageShippingPlanning.smsDeliveryConfirmation.status_SEEN'
            )
    }
};

const DEFAULT_TEXT_AND_SEVERITY = {
    severity: 'error',
    getText: () =>
        i18next.t(
            'cartageShippingPlanning.smsDeliveryConfirmation.status_UNKNOWN'
        )
};

export function getAlertTextAndSeverity(
    status: DeliveryConfirmationStatus,
    hasFetchingFailed: boolean
): TextAndSeverity {
    if (hasFetchingFailed) {
        return {
            severity: 'info',
            text: i18next.t(
                'cartageShippingPlanning.smsDeliveryConfirmation.fetchingFailed'
            )
        };
    }

    const { severity, getText } =
        statusTextAndSeverityMap[status] ?? DEFAULT_TEXT_AND_SEVERITY;

    return {
        severity: severity as Severity,
        text: getText()
    };
}
