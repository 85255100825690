import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { appPaths } from './AppRouterService';
import { isDriver } from './common/session/SessionService';
import { AppState } from './models/AppState';

export const CustomRedirect: React.FC = () => {
    const session = useSelector((state: AppState) => state.session);

    const redirectTo = isDriver(session)
        ? appPaths.cartageList
        : appPaths.orderList;

    return <Redirect from="*" to={redirectTo} />;
};
