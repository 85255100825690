import { Action, handleActions } from 'redux-actions';
import { User } from '../models/User';
import * as actions from './LoginActions';
import { LoginState } from './LoginState';

const defaultUser: User = {
    username: '',
    password: ''
};

const defaultState: LoginState = {
    user: defaultUser
};

interface UpdateUserPayload {
    propName: string;
    propValue: string;
}

type PayloadType = UpdateUserPayload;

const LoginReducer = handleActions<LoginState, PayloadType>(
    {
        [actions.updateUser.toString()]: (
            state,
            action: Action<UpdateUserPayload>
        ) => ({
            ...state,
            user: {
                ...state.user,
                [action.payload.propName]: action.payload.propValue
            }
        }),

        [actions.resetUser.toString()]: state => ({
            ...state,
            user: defaultUser
        })
    },
    defaultState
);

export { LoginReducer };
