import React from 'react';
import i18next from 'i18next';
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { KeyboardDatePicker, DatePickerProps } from '@material-ui/pickers';

export const JDatePicker: React.FC<DatePickerProps> = props => (
    <KeyboardDatePicker
        allowKeyboardControl
        format="DD/MM/YYYY"
        placeholder="13/1/2018"
        animateYearScrolling={false}
        leftArrowIcon={<ArrowLeftIcon />}
        rightArrowIcon={<ArrowRightIcon />}
        cancelLabel={i18next.t('Cancel')}
        clearLabel={i18next.t('Clear')}
        maxDateMessage={i18next.t('Date should not be after maximal date')}
        minDateMessage={i18next.t('Date should not be before minimal date')}
        invalidDateMessage={i18next.t('Invalid Date Format')}
        {...props}
    />
);
