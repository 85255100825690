import React from 'react';
import { Typography, CircularProgress } from '@material-ui/core';
import i18next from 'i18next';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { ScannedOrderState, ScannedOrder } from './useScannedOrdersReducer';
import { NavLink, generatePath } from 'react-router-dom';
import { appPaths } from 'src/AppRouterService';

interface Props {
    orders: ScannedOrder[];
}

export const ScannedOrders: React.FC<Props> = ({ orders }) => {
    if (orders.length === 0) {
        return (
            <Typography align="center">
                {i18next.t('markAsDone.noOrdersScanned')}
            </Typography>
        );
    }

    return (
        <>
            {orders.map(order => (
                <div key={order.orderNumber} style={{ textAlign: 'center' }}>
                    <NavLink
                        to={generatePath(appPaths.order, {
                            orderId: order.orderNumber
                        })}
                        exact
                    >
                        <Typography display="inline">
                            {order.orderNumber}&nbsp;
                            {order.state === ScannedOrderState.SCANNED && (
                                <CircularProgress size={20} />
                            )}
                        </Typography>
                    </NavLink>
                    {order.state === ScannedOrderState.SUCCEED && <CheckIcon />}
                    {order.state === ScannedOrderState.FAILED && <CloseIcon />}
                </div>
            ))}
        </>
    );
};
