import { Button, Input } from '@material-ui/core';
import i18next from 'i18next';
import React from 'react';
import { Component, FormEvent } from 'react';
import { shopType } from '../../common/enums/enums';

interface Props {
    shopId: string;
    onSubmit: (event: FormEvent<HTMLFormElement>) => void;
}

interface State {
    fileSelected: boolean;
}

export class WebnodeOrdersImportView extends Component<Props, State> {
    fileInput: HTMLInputElement;

    constructor(props: Props) {
        super(props);

        this.state = { fileSelected: false };
    }

    onFileSelected = () => {
        this.setState({ fileSelected: !!this.fileInput.files.length });
    };

    render() {
        const { onSubmit, shopId } = this.props;
        return (
            <div>
                <form onSubmit={onSubmit}>
                    <input type="hidden" name="shopId" value={shopId} />
                    <input
                        type="hidden"
                        name="shopType"
                        value={shopType.WEBNODE}
                    />
                    <Input
                        inputRef={ref => {
                            this.fileInput = ref;
                        }}
                        type="file"
                        name="importFile"
                        style={{ display: 'none' }}
                        onChange={this.onFileSelected}
                    />
                    <Button onClick={() => this.fileInput.click()}>
                        {i18next.t('Select file')}
                    </Button>
                    <Button type="submit" disabled={!this.state.fileSelected}>
                        {i18next.t('Import orders')}
                    </Button>
                </form>
            </div>
        );
    }
}
