import i18next from 'i18next';
import React from 'react';
import { Bar } from 'react-chartjs-2';
import { formatDate } from 'src/utils/formatters';
import { ExpeditionStats } from './ExpeditionService';

interface Props {
    stats: ExpeditionStats[];
}

export const ExpeditionChart: React.FC<Props> = ({ stats }) => {
    const data = {
        labels: stats.map(({ _id }) => formatDate(_id)),
        datasets: [
            {
                label: i18next.t('expeditionStats.courier'),
                data: stats.map(({ courierCount }) => courierCount),
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 1
            },
            {
                label: i18next.t('expeditionStats.cartage'),
                data: stats.map(({ cartageCount }) => cartageCount),
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1
            }
        ]
    };

    return (
        <div>
            <Bar
                data={data}
                options={{
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true
                                },
                                stacked: true
                            }
                        ],
                        xAxes: [
                            {
                                stacked: true
                            }
                        ]
                    }
                }}
            />
        </div>
    );
};
