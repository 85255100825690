import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateSelectedOrdersIds } from './ShippingPlanningActions';
import { selectShippingSelectedOrders } from './ShippingPlanningSelectors';
import { getSelectedOrders, OrderToShip } from './ShippingPlanningService';

export function useOrderToShipCheckAll(orders: OrderToShip[]) {
    const dispatch = useDispatch();
    const selectedOrdersIds = useSelector(selectShippingSelectedOrders);

    const isCheckAllChecked =
        selectedOrdersIds.length > 0 &&
        orders.length === selectedOrdersIds.length;
    const isCheckAllIndeterminate =
        selectedOrdersIds.length > 0 && !isCheckAllChecked;

    const handleCheckChange = useCallback(() => {
        dispatch(
            updateSelectedOrdersIds(
                getSelectedOrders(!isCheckAllChecked, orders)
            )
        );
    }, [dispatch, isCheckAllChecked, orders]);

    const resetCheckAll = useCallback(() => {
        dispatch(updateSelectedOrdersIds([]));
    }, [dispatch]);

    useEffect(
        () => (): void => {
            resetCheckAll();
        },
        [resetCheckAll]
    );

    return {
        isCheckAllChecked,
        isCheckAllIndeterminate,
        handleCheckChange,
        resetCheckAll
    };
}
