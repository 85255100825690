export enum NotificationVariant {
    Success = 'success',
    Warning = 'warning',
    Error = 'error',
    Info = 'info'
}

export interface Notification {
    id: string;
    variant: NotificationVariant;
    text: string;
    link?: string;
    preventAutoHide?: boolean;
}
