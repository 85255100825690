export interface DeliveryConfirmation {
    _id: string;
    status: DeliveryConfirmationStatus;
    // to?: string;
    // price?: string;
    // date?: string;
    // from?: string;
    // message?: string;
}

export enum DeliveryConfirmationStatus {
    DELIVERED = '1', //	SMS successfully delivered
    BUFFERED_ON_SMC = '2', //	SMS buffered on SMSC. SMS will be delivered later. Recipient is unavailable.
    NOT_DELIVERED = '3', //	SMS not delivered. Unknown/unavailable recipient.
    INCOMING_ANSWER = '10', //	Incoming SMS or SMS answer
    SEEN = '13' //
}
