import React from 'react';
import {
    Table,
    TableBody,
    TableRow,
    TableCell,
    makeStyles
} from '@material-ui/core';
import i18next from 'i18next';
import { formatValue, isObject } from './HistoryService';
import { useEnums } from 'src/common/enums/useEnumsHook';

const useStyles = makeStyles({
    itemRow: {
        height: 'auto'
    },
    itemCell: {
        fontSize: '0.75rem',
        padding: 0,
        border: 0
    }
});

interface Props {
    item: any;
    path: string[];
    translationScope: string;
}

export const HistoryChangeObject: React.FC<Props> = ({
    item,
    path,
    translationScope
}) => {
    const classes = useStyles();
    const enums = useEnums();

    if (!item) {
        return null;
    }

    if (!isObject(item)) {
        return formatValue(item, path, enums);
    }

    return (
        <Table>
            <TableBody>
                {Object.keys(item).map(key => (
                    <TableRow key={key} className={classes.itemRow}>
                        <TableCell className={classes.itemCell}>
                            {i18next.t(
                                `history.fields.${translationScope}.${key}`
                            )}
                        </TableCell>
                        <TableCell className={classes.itemCell}>
                            <HistoryChangeObject
                                item={item[key]}
                                path={[...path, key]}
                                translationScope={translationScope}
                            />
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};
