import React, { useCallback } from 'react';
import {
    TableRow,
    TableCell,
    Checkbox,
    Typography,
    TextField,
    Link,
    Select,
    MenuItem
} from '@material-ui/core';
import { OrderNumber } from 'src/orderList/OrderNumber';
import { NavLink } from 'react-router-dom';
import i18next from 'i18next';
import { useSelector, useDispatch } from 'react-redux';
import { toggleArrayItem } from 'src/common/UtilsService';
import { OrderPrintView } from 'src/order/OrderPrintView';
import { updateSelectedOrdersIds } from 'src/shippingPlanning/ShippingPlanningActions';
import { selectShippingSelectedOrders } from 'src/shippingPlanning/ShippingPlanningSelectors';
import { OrderToShip } from 'src/shippingPlanning/ShippingPlanningService';
import { TimePicker } from '@material-ui/pickers';
import { OrderInfo } from 'src/models/Cartage';
import { Moment } from 'moment';
import moment from 'moment';
import { SmsMessage } from './SmsMessage';
import {
    getAddressGoogleLink,
    getOrderBoxCount,
    getReadyToShipMessage
} from './CartageService';
import { Invoice } from './Invoice';
import { CartageProductsToShip } from './CartageProductsToShip';
import { useOversizedProductCodes } from 'src/products-info/useOversizedProductCodes';

interface Props {
    order: OrderToShip;
    sequenceNumber: number;
    orderInfo?: OrderInfo;
    disabled?: boolean;
    isSequenceNumberDisabled?: boolean;
    showOrderItemStatusIcon: boolean;
    onOrderInfoChange(orderInfo: OrderInfo): void;
    innerRef(element?: HTMLElement | null): any;
}

const DEFAULT_DELIVERY_INTERVAL = 120;
const DELIVERY_INTERVALS = [30, 60, DEFAULT_DELIVERY_INTERVAL];

export const CartageOrderToShipItem: React.FC<Props> = ({
    order,
    orderInfo,
    disabled,
    isSequenceNumberDisabled,
    sequenceNumber,
    showOrderItemStatusIcon,
    innerRef,
    onOrderInfoChange,
    ...props
}) => {
    const dispatch = useDispatch();
    const selectedOrdersIds = useSelector(selectShippingSelectedOrders);
    const {
        inProgress: isFetchingOversizedProductCodes,
        oversizedProductCodes
    } = useOversizedProductCodes();

    const handleCheckChange = useCallback(() => {
        dispatch(
            updateSelectedOrdersIds(
                toggleArrayItem(selectedOrdersIds, order._id)
            )
        );
    }, [dispatch, order._id, selectedOrdersIds]);

    const handleDeliveryTimeChange = (date: Moment) => {
        onOrderInfoChange({
            ...orderInfo,
            deliveryTime: date.toISOString()
        });
    };

    const handleDeliveryIntervalChange = evt => {
        onOrderInfoChange({
            ...orderInfo,
            deliveryInterval: evt.target.value
        });
    };

    const handleSequenceChange = evt => {
        onOrderInfoChange({
            ...orderInfo,
            sequence: +evt.target.value
        });
    };

    const deliveryInterval =
        orderInfo.deliveryInterval ?? DEFAULT_DELIVERY_INTERVAL;

    const isChecked = selectedOrdersIds.includes(order._id);

    const formatShippingTime = (date: Moment): string => {
        return `${date.format('HH:mm')} - ${moment(date)
            .add(deliveryInterval, 'minutes')
            .format('HH:mm')}`;
    };

    return (
        <>
            {isChecked && (
                <OrderPrintView
                    order={order}
                    oversizedProductCodes={oversizedProductCodes}
                    sequenceNumber={sequenceNumber}
                    deliveryTime={formatShippingTime(
                        moment(orderInfo.deliveryTime)
                    )}
                    marginTop={65}
                />
            )}
            <TableRow className="no-print" ref={innerRef} {...props}>
                <TableCell>
                    <Checkbox
                        name={order._id}
                        checked={isChecked}
                        onChange={handleCheckChange}
                    />
                </TableCell>
                <TableCell>
                    <TextField
                        type="number"
                        disabled={disabled || isSequenceNumberDisabled}
                        value={sequenceNumber}
                        onChange={handleSequenceChange}
                    />
                </TableCell>
                <TableCell>
                    <OrderNumber
                        orderNumber={order.orderNumber}
                        deliveryType={order.delivery.type}
                    />
                </TableCell>
                <TableCell align="center">
                    <TimePicker
                        ampm={false}
                        autoOk
                        disabled={disabled}
                        variant="inline"
                        value={orderInfo?.deliveryTime}
                        minutesStep={5}
                        onChange={handleDeliveryTimeChange}
                        labelFunc={formatShippingTime}
                    />
                    <Select
                        value={deliveryInterval}
                        onChange={handleDeliveryIntervalChange}
                    >
                        {DELIVERY_INTERVALS.map(interval => (
                            <MenuItem key={interval} value={interval}>
                                {i18next.t(
                                    `cartageShippingPlanning.deliveryInterval__${interval}`
                                )}
                            </MenuItem>
                        ))}
                    </Select>
                </TableCell>
                <TableCell>{getOrderBoxCount(order)}</TableCell>
                <TableCell>
                    <SmsMessage
                        message={getReadyToShipMessage(orderInfo?.messages)}
                    />
                </TableCell>
                <TableCell>
                    <Invoice
                        billNumber={order.billNumber}
                        type={order.invoice?.type}
                        invoiceId={order.invoice?.id}
                        numberFormatted={order.invoice?.numberFormatted}
                        withoutInvoice={order.withoutInvoice}
                    />
                </TableCell>
                <TableCell>
                    <Link
                        href={getAddressGoogleLink(order.customer.delivery)}
                        target="_blank"
                    >
                        <Typography display="block">{`${order.customer.delivery.zip} ${order.customer.delivery.city}`}</Typography>
                        <Typography display="block" variant="caption">
                            {order.customer.delivery.street}
                        </Typography>
                    </Link>
                    <Typography display="block">
                        {order.customer.delivery.phone}
                    </Typography>
                </TableCell>
                <TableCell>
                    <CartageProductsToShip
                        inProgress={isFetchingOversizedProductCodes}
                        oversizedProductCodes={oversizedProductCodes}
                        items={order.items}
                        showOrderItemStatusIcon={showOrderItemStatusIcon}
                    />
                </TableCell>
                <TableCell>{order.shippingNote}</TableCell>
                <TableCell>
                    <NavLink to={`/order/${order._id}`}>
                        {i18next.t('Display')}
                    </NavLink>
                </TableCell>
            </TableRow>
        </>
    );
};
