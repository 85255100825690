import { Tooltip, IconButton } from '@material-ui/core';
import i18next from 'i18next';
import React, { useEffect } from 'react';
import { tooltipEnterDelay } from 'src/config';
import RefreshIcon from '@material-ui/icons/Refresh';

interface Props {
    disabled?: boolean;
    onRefreshOrders(): void;
}

const R_KEY_CODE = 82;

export const OrderListRefreshButton: React.FC<Props> = ({
    disabled,
    onRefreshOrders
}) => {
    const handleRefreshShortcut = evt => {
        if ((evt.ctrlKey || evt.metaKey) && evt.keyCode === R_KEY_CODE) {
            evt.preventDefault();
            if (disabled) {
                return;
            }

            onRefreshOrders();
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleRefreshShortcut, false);

        return () => {
            document.removeEventListener(
                'keydown',
                handleRefreshShortcut,
                false
            );
        };
    });

    return (
        <Tooltip
            title={i18next.t('Refresh orders')}
            enterDelay={tooltipEnterDelay}
            style={{ marginLeft: 'auto' }}
        >
            <IconButton
                color="inherit"
                onClick={onRefreshOrders}
                disabled={disabled}
            >
                <RefreshIcon />
            </IconButton>
        </Tooltip>
    );
};
