import React, { useCallback, useEffect, useState } from 'react';
import {
    Checkbox,
    FormControl,
    Grid,
    InputLabel,
    Link,
    MenuItem,
    Select,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Paper,
    TableContainer
} from '@material-ui/core';
import i18next from 'i18next';
import { API_URLS } from '../common/ApiService';
import { match, Prompt } from 'react-router-dom';
import { useRequest } from 'src/common/useRequestHook';
import { RequestMethod } from 'src/utils/request';
import { Cartage, CartageStatus, OrderInfo } from 'src/models/Cartage';
import { OrderToShip } from 'src/shippingPlanning/ShippingPlanningService';
import { SortableOrdersToShipList } from './SortableOrdersToShipList';
import {
    reorderOrdersIds,
    sortOrders,
    updateOrdersInfo
} from './SortableOrdersToShipListService';
import { SHARED_DATA_KEY } from 'src/common/sharedData/SharedDataActions';
import { useSharedData } from 'src/common/sharedData/useSharedData';
import { JDatePicker } from 'src/components/JDatePicker';
import { Moment } from 'moment';
import { CartagePrintView } from './CartagePrintView';
import { useOrderToShipCheckAll } from 'src/shippingPlanning/useOrdersToShipCheckAll';
import {
    CartageSharedData,
    getCartageDirectionGoogleLink,
    getCartageTotalWeight,
    getDriverAddress
} from './CartageService';
import { isAdmin, isDriver } from 'src/common/session/SessionService';
import { useSession } from 'src/common/session/useSession';
import { useDriverUsers } from './useDriverUsers';
import { Alert } from '@material-ui/lab';
import { useOversizedProductCodes } from 'src/products-info/useOversizedProductCodes';
import { useSelector } from 'react-redux';
import { selectShippingSelectedOrders } from 'src/shippingPlanning/ShippingPlanningSelectors';
import { OrdersPayment } from './OrdersPayment';
interface Props {
    match: match<{
        cartageId: string;
    }>;
}

export const CartageView: React.FC<Props> = ({ match }) => {
    const session = useSession();
    const { oversizedProductCodes } = useOversizedProductCodes();
    const [showOnlyNotPaidOrders, setShowOnlyNotPaidOrders] = useState(false);
    const selectedOrdersIds = useSelector(selectShippingSelectedOrders);

    const drivers = useDriverUsers();
    const [
        fetchCartage,
        { response: fetchedCartage, hasSucceed: cartageFetchingHasSucceed }
    ] = useRequest<Cartage>(
        RequestMethod.GET,
        API_URLS.cartages + '/' + match.params.cartageId
    );
    const [
        fetchCartageOrders,
        { response: orders, hasSucceed: cartageOrdersFetchingHasSucceed }
    ] = useRequest<OrderToShip[]>(RequestMethod.GET, API_URLS.ordersToShip, {
        cartageId: fetchedCartage?._id
    });

    const [{ cartage, isEditing }, setCartageSharedData] = useSharedData<
        CartageSharedData
    >(SHARED_DATA_KEY.cartage);

    const setCartage = useCallback(
        (cartage: Cartage, isEditingValue = true): void => {
            setCartageSharedData({
                cartage,
                isEditing: isEditingValue,
                fetchCartageOrders
            });
        },
        [fetchCartageOrders, setCartageSharedData]
    );

    const handleOrdersSort = (
        orderIdSourceIndex: number,
        orderIdDestinationIndex: number
    ): void => {
        const resortedOrdersInfo = reorderOrdersIds(
            cartage?.ordersInfo,
            orderIdSourceIndex,
            orderIdDestinationIndex
        );

        setCartage({ ...cartage, ordersInfo: resortedOrdersInfo });
    };

    const handleOrderInfoChange = (orderInfo: OrderInfo): void => {
        setCartage({
            ...cartage,
            ordersInfo: updateOrdersInfo(cartage.ordersInfo, orderInfo)
        });
    };

    const handleShippingDateChange = (date: Moment) => {
        setCartage({ ...cartage, shippingDate: date.toISOString() });
    };

    const handleDriverChange = evt => {
        setCartage({ ...cartage, driver: evt.target.value });
    };

    const handlePickerChange = evt => {
        setCartage({ ...cartage, picker: evt.target.value });
    };

    useEffect(() => {
        fetchCartage();
    }, [fetchCartage]);

    useEffect(() => {
        if (!fetchedCartage) {
            return;
        }
        setCartage(fetchedCartage, false);
    }, [fetchedCartage, setCartage]);

    useEffect(() => {
        if (!fetchedCartage?._id) {
            return;
        }

        fetchCartageOrders();
    }, [fetchCartageOrders, fetchedCartage]);

    const {
        isCheckAllChecked,
        isCheckAllIndeterminate,
        handleCheckChange,
        resetCheckAll
    } = useOrderToShipCheckAll(orders);

    const handleShowUnpaidChange = evt => {
        setShowOnlyNotPaidOrders(evt.target.checked);
        resetCheckAll();
    };

    const handleOrdersPaymentUpdate = () => {
        resetCheckAll();
        fetchCartageOrders();
    };

    const isLoading = !(
        cartageFetchingHasSucceed && cartageOrdersFetchingHasSucceed
    );

    const isCartageDone = cartage?.status === CartageStatus.DONE;
    const isDisabled = isCartageDone || isDriver(session);
    const isDragDisabled =
        isDisabled || cartage?.status !== CartageStatus.INITIAL;

    const sortedOrders = sortOrders(orders, cartage?.ordersInfo);
    const driverAddress = getDriverAddress(drivers, cartage?.driver);

    const isPaidSectionShown =
        cartage && cartage.status !== CartageStatus.INITIAL;

    return (
        <>
            <Prompt when={isEditing} message={i18next.t('prompt.notSaved')} />
            <Grid
                container
                spacing={3}
                className="no-print"
                style={{ marginTop: '10px', marginBottom: '10px' }}
            >
                {isEditing && (
                    <Grid item xs={12}>
                        <Alert severity="warning">
                            {i18next.t(`cartageShippingPlanning.notSaved`)}
                        </Alert>
                    </Grid>
                )}
                <Grid item xs={12} sm={2}>
                    <JDatePicker
                        disabled={isLoading || isDisabled}
                        label={i18next.t('cartageShippingPlanning.shippingDay')}
                        value={cartage?.shippingDate}
                        onChange={handleShippingDateChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextField
                        disabled={!isAdmin(session) && !!fetchedCartage?.picker}
                        label={i18next.t('cartageShippingPlanning.picker')}
                        fullWidth
                        value={cartage?.picker ?? ''}
                        onChange={handlePickerChange}
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="driver">
                            {i18next.t('cartageShippingPlanning.driver')}
                        </InputLabel>
                        <Select
                            id="driver"
                            name="driver"
                            disabled={isLoading || isDisabled}
                            value={cartage?.driver ?? ''}
                            onChange={handleDriverChange}
                            fullWidth
                        >
                            {drivers.map(({ username }) => (
                                <MenuItem key={username} value={username}>
                                    {username}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={1}>
                    <TextField
                        disabled
                        label={i18next.t('cartageShippingPlanning.totalWeight')}
                        fullWidth
                        value={
                            Math.round(getCartageTotalWeight(orders)) + ' kg'
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <TextField
                        disabled
                        label={i18next.t('cartageShippingPlanning.status')}
                        fullWidth
                        value={i18next.t(
                            `cartageShippingPlanning.status__${cartage?.status}`
                        )}
                    />
                </Grid>
                <Grid item container xs={12} sm={2} alignItems="center">
                    {driverAddress && (
                        <Link
                            href={getCartageDirectionGoogleLink(
                                sortedOrders,
                                driverAddress
                            )}
                            target="_blank"
                        >
                            {i18next.t('shippingPlanning.directionLink')}
                        </Link>
                    )}
                    {!driverAddress &&
                        i18next.t('shippingPlanning.noDriverAddress')}
                </Grid>
                {isPaidSectionShown && (
                    <OrdersPayment
                        selectedOrdersIds={selectedOrdersIds}
                        showOnlyNotPaidOrders={showOnlyNotPaidOrders}
                        onShowUnpaidChange={handleShowUnpaidChange}
                        onOrdersPaymentUpdate={handleOrdersPaymentUpdate}
                        shippingDate={cartage.shippingDate}
                        disabled={
                            !showOnlyNotPaidOrders ||
                            selectedOrdersIds.length === 0
                        }
                    />
                )}
            </Grid>
            <TableContainer component={Paper}>
                <Table className="table">
                    <TableHead className="no-print">
                        <TableRow>
                            <TableCell>
                                <Checkbox
                                    disabled={showOnlyNotPaidOrders}
                                    checked={isCheckAllChecked}
                                    indeterminate={isCheckAllIndeterminate}
                                    onChange={handleCheckChange}
                                />
                            </TableCell>
                            <TableCell>
                                {i18next.t('shippingPlanning.sequence')}
                            </TableCell>
                            <TableCell>
                                {i18next.t('shippingPlanning.orderNumber')}
                            </TableCell>
                            <TableCell
                                style={{ width: '130px', maxWidth: '130px' }}
                            >
                                {i18next.t(
                                    'cartageShippingPlanning.deliveryTime'
                                )}
                            </TableCell>
                            <TableCell>
                                {i18next.t(
                                    'cartageShippingPlanning.partsCount'
                                )}
                            </TableCell>
                            {/* <TableCell>
                            {i18next.t('cartageShippingPlanning.answered')}
                        </TableCell> */}
                            <TableCell>
                                {i18next.t('cartageShippingPlanning.SMS')}
                            </TableCell>
                            <TableCell>
                                {i18next.t('cartageShippingPlanning.invoice')}
                            </TableCell>
                            <TableCell>
                                {i18next.t('shippingPlanning.address')}
                            </TableCell>
                            <TableCell>
                                {i18next.t('shippingPlanning.items')}
                            </TableCell>
                            <TableCell>
                                {i18next.t('shippingPlanning.note')}
                            </TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <SortableOrdersToShipList
                        isLoading={isLoading}
                        orders={sortedOrders}
                        ordersInfo={cartage?.ordersInfo}
                        disabled={isDisabled}
                        isDragDisabled={isDragDisabled}
                        onOrdersSort={handleOrdersSort}
                        onOrderInfoChange={handleOrderInfoChange}
                        showOnlyNotPaidOrdersWithInvoice={showOnlyNotPaidOrders}
                        showOrderItemStatusIcon={
                            cartage?.status === CartageStatus.READY_TO_SHIP
                        }
                    />
                </Table>
            </TableContainer>
            <CartagePrintView
                cartage={cartage}
                orders={sortedOrders}
                oversizedProductCodes={oversizedProductCodes}
            />
        </>
    );
};
