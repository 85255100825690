import React from 'react';
import { SessionState } from 'src/common/session/SessionState';
import { useSession } from 'src/common/session/useSession';

interface Props {
    isRole(session: SessionState): boolean;
}

export const Role: React.FC<Props> = ({ isRole, children }) => {
    const session = useSession();

    if (!isRole(session)) {
        return null;
    }
    return <>{children}</>;
};
