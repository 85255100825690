import { useReducer } from 'react';

export enum ScannedOrderState {
    SCANNED = 'SCANNED',
    SUCCEED = 'SUCCEED',
    FAILED = 'FAILED'
}

export interface ScannedOrder {
    orderNumber: string;
    state: ScannedOrderState;
    date: number;
}

interface State {
    orders: { [key: string]: ScannedOrder };
}

interface Action {
    state: ScannedOrderState;
    orderNumber: string;
}

const initialState = { orders: {} };

function reducer(state: State, action: Action): State {
    switch (action.state) {
        case ScannedOrderState.SCANNED:
            return {
                ...state,
                orders: {
                    ...state.orders,
                    [action.orderNumber]: {
                        ...action,
                        date: Date.now()
                    }
                }
            };
        case ScannedOrderState.SUCCEED:
        case ScannedOrderState.FAILED:
            return {
                ...state,
                orders: {
                    ...state.orders,
                    [action.orderNumber]: {
                        ...state.orders[action.orderNumber],
                        date:
                            state.orders[action.orderNumber]?.date ??
                            Date.now(),
                        ...action
                    }
                }
            };
        default:
            throw new Error();
    }
}

export function useScannedOrdersReducer() {
    return useReducer(reducer, initialState);
}
