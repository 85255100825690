import React from 'react';
import { useSession } from 'src/common/session/useSession';
import { Order } from 'src/models/Order';
import { ItemView } from './ItemView';
import { CurrencySymbol } from 'src/utils/price';
import { useEnums } from 'src/common/enums/useEnumsHook';
import { getDisabledBillingCodes } from './OrderAccountRightsService';
import { isAccountant } from 'src/common/session/SessionService';

interface Props {
    order: Order;
    currency: CurrencySymbol;
    disabled: boolean;
    onChange(evt): void;
}

export const BillingItem: React.FC<Props> = ({
    order,
    currency,
    disabled,
    onChange
}) => {
    const session = useSession();
    const enums = useEnums();

    return (
        <ItemView
            country={order.shop.country}
            currency={currency}
            item={order.billing}
            namePrefix="billing"
            onChange={onChange}
            disabled={disabled}
            disabledEnumCodes={getDisabledBillingCodes(
                order,
                isAccountant(session)
            )}
            typeEnum={enums.billingType.enum}
        />
    );
};
