import MomentUtils from '@date-io/moment';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import React from 'react';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import reduxThunk from 'redux-thunk';
import { JarvisBrowserRouter } from './utils/history';
import './App.css';
import { ErrorsContainer } from './common/errors/ErrorsContainer';
import { NotificationsContainer } from './common/notifications/NotificationsContainer';
import { loadSession } from './common/session/SessionActions';
import reducer from './reducer';
import { AppRoutes } from './AppRoutes';
import 'moment/locale/sk';
import 'moment-business-days';
import { ConfirmationDialogProvider } from './common/confirmationDialog/ConfirmationDialogProvider';
import { ConfirmationDialog } from './common/confirmationDialog/ConfirmationDialog';
import { MOMENT_BUSINESS_DAYS_CONFIG } from './config';

let composeEnhancers;
// tslint:disable-next-line:no-any
if ((window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
} else {
    composeEnhancers = compose;
}

moment.locale('sk');
moment.updateLocale('sk', MOMENT_BUSINESS_DAYS_CONFIG);

const store = createStore(
    reducer,
    composeEnhancers(applyMiddleware(reduxThunk))
);

function App() {
    // @ts-ignore select
    store.dispatch(loadSession());

    return (
        <CssBaseline>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <Provider store={store}>
                    <ConfirmationDialogProvider>
                        <JarvisBrowserRouter>
                            <>
                                <AppRoutes />
                                <ErrorsContainer />
                                <NotificationsContainer />
                                <ConfirmationDialog />
                            </>
                        </JarvisBrowserRouter>
                    </ConfirmationDialogProvider>
                </Provider>
            </MuiPickersUtilsProvider>
        </CssBaseline>
    );
}

export default App;
