import { AppState } from '../models/AppState';
import { ShippingPlanningSharedData } from './ShippingPlanningService';
import { selectSharedData } from 'src/common/sharedData/SharedDataSelectors';
import { SHARED_DATA_KEY } from 'src/common/sharedData/SharedDataActions';

export const selectShippingQuery = (state: AppState) =>
    selectSharedData<ShippingPlanningSharedData>(
        state,
        SHARED_DATA_KEY.shippingPlanning
    ).query;

export const selectShippingSelectedOrders = (state: AppState) =>
    selectSharedData<ShippingPlanningSharedData>(
        state,
        SHARED_DATA_KEY.shippingPlanning
    ).selectedOrdersIds;
