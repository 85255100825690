const IFRAME_CLEAN_UP_TIMEOUT = 1000 * 60;

function onPrintFileCleanup(iframe: HTMLIFrameElement): void {
    document.body.removeChild(iframe);
    window.URL.revokeObjectURL(iframe.src);
}

export enum CourierCompany {
    GLS = 'GLS',
    DPD = 'DPD'
}

function printLabel(src: string): void {
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = src;
    document.body.appendChild(iframe);

    iframe.onload = function() {
        iframe.contentWindow.print();
    };

    setTimeout(() => onPrintFileCleanup(iframe), IFRAME_CLEAN_UP_TIMEOUT);
}

export function printBlob(blob: any): void {
    printLabel(window.URL.createObjectURL(blob));
}

export function removeOrderNumberPrefix(value = ''): string {
    return value.replace(/J%/, '');
}
