import { Dispatch } from 'redux';
import { createAction } from 'redux-actions';
import { showError } from '../common/errors/ErrorsActions';
import { User } from '../models/User';
import { del, get, patch, post } from '../utils/request';

export const loadingUsers = createAction('LOADING_USERS');
export const loadUsersSuccess = createAction(
    'LOAD_USERS_SUCCESS',
    (users: User[]) => ({
        users
    })
);
export const loadUsersFailure = createAction('LOAD_USERS_FAILURE');
export function loadUsers() {
    return async (dispatch: Dispatch<any>) => {
        dispatch(loadingUsers());

        try {
            const users = await get<User[]>('/user');
            dispatch(loadUsersSuccess(users));
        } catch (err) {
            dispatch(loadUsersFailure());
            dispatch(showError(err));
        }
    };
}

export const savingUser = createAction('SAVING_USER');
export const saveUserSuccess = createAction('SAVE_USER_SUCCESS');
export const saveUserFailure = createAction('SAVE_USER_FAILURE');
export function saveUser(user: User) {
    return async (dispatch: Dispatch<any>) => {
        dispatch(savingUser());
        try {
            if (user._id != null) {
                await patch('/user', user._id, user);
            } else {
                await post('/user', user);
            }
            // @ts-ignore select
            dispatch(loadUsers());
            dispatch(saveUserSuccess());
        } catch (err) {
            dispatch(saveUserFailure());
            dispatch(showError(err));
        }
    };
}

export const showNewUserDialog = createAction(
    'SHOW_NEW_USER_DIALOG',
    (show: boolean) => ({
        show
    })
);
export const resetNewUser = createAction('RESET_NEW_USER');
export const updateNewUser = createAction(
    'UPDATE_NEW_USER',
    (propName: string, propValue: string) => ({
        propName,
        propValue
    })
);

export const removingUser = createAction('REMOVING_USER');
export const removeUserSuccess = createAction('REMOVE_USER_SUCCESS');
export const removeUserFailure = createAction('REMOVE_USER_FAILURE');
export function removeUser(id: string) {
    return async (dispatch: Dispatch<any>) => {
        dispatch(removingUser());
        try {
            await del('/user', id);
            // @ts-ignore select
            dispatch(loadUsers());
            dispatch(removeUserSuccess());
        } catch (err) {
            dispatch(removeUserFailure());
            dispatch(showError(err));
        }
    };
}
