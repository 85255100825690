import { Action, handleActions } from 'redux-actions';
import * as actions from './ErrorsActions';
import { isNotificationError } from './ErrorsService';
import { ErrorsState } from './ErrorsState';

const defaultState: ErrorsState = {
    errors: []
};

type PayloadType = actions.ShowErrorPayload;

const ErrorsReducer = handleActions<ErrorsState, PayloadType>(
    {
        [actions.showError.toString()]: (
            state,
            action: Action<actions.ShowErrorPayload>
        ) => {
            const error = action.payload.error;

            if (error && error.status !== 401 && !isNotificationError(error)) {
                return {
                    ...state,
                    errors: [...state.errors, action.payload.error]
                };
            }

            return state;
        },
        [actions.hideError.toString()]: state => ({
            ...state,
            errors: state.errors.splice(1)
        })
    },
    defaultState
);

export { ErrorsReducer };
