import { userRole } from '../enums/enums';
import { SessionState } from './SessionState';

export function isAuthenticated(session: SessionState): boolean {
    return !!session.user;
}

export function isAdmin({ user }: SessionState): boolean {
    return user?.role === userRole.ADMIN;
}

const ACCOUNTANT_USERS = [userRole.ADMIN, userRole.ACCOUNTANT];
export function isAccountant({ user }: SessionState): boolean {
    return ACCOUNTANT_USERS.includes(user?.role);
}

const ADVANCED_USERS = [...ACCOUNTANT_USERS, userRole.USER];
export function isAdvancedUser({ user }: SessionState): boolean {
    return ADVANCED_USERS.includes(user?.role);
}

const BASIC_USERS = [...ADVANCED_USERS, userRole.SIMPLE_USER];
export function isBasicUser({ user }: SessionState): boolean {
    return BASIC_USERS.includes(user?.role);
}

export function isDriver({ user }: SessionState): boolean {
    return user?.role === userRole.DRIVER;
}

export function isSimpleUser({ user }: SessionState): boolean {
    return user?.role === userRole.SIMPLE_USER;
}
