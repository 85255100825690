import { CircularProgress } from '@material-ui/core';
import React from 'react';
import FitnessIcon from '@material-ui/icons/FitnessCenter';
import { useOversizedProductCodes } from 'src/products-info/useOversizedProductCodes';

interface Props {
    isShown: boolean;
}

export const OversizedItemIndicator: React.FC<Props> = ({ isShown }) => {
    const { inProgress } = useOversizedProductCodes();

    if (inProgress) {
        return <CircularProgress size={20} />;
    }

    if (!isShown) {
        return null;
    }

    return <FitnessIcon />;
};
