import {
    CircularProgress,
    FormControl,
    FormLabel,
    Grid
} from '@material-ui/core';
import i18next from 'i18next';
import moment, { Moment } from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { API_URLS } from 'src/common/ApiService';
import { useRequest } from 'src/common/useRequestHook';
import { JDatePicker } from 'src/components/JDatePicker';
import { RequestMethod } from 'src/utils/request';
import {
    ExpeditionInfoPerDaySum,
    OrderExpeditionInfo,
    formatQueryDate,
    getExpeditionInfoPerDaySum
} from './CartagesExpeditionTimeService';
import { useDispatch } from 'react-redux';
import { showNotification } from 'src/common/notifications/NotificationsActions';
import { NotificationVariant } from 'src/models/Notification';

interface FilterQuery {
    from: Moment;
    to: Moment;
}

export const CartagesExpeditionTime: React.FC = () => {
    const dispatch = useDispatch();
    const [query, setQuery] = useState<FilterQuery>({
        to: moment().add('days', 5),
        from: moment().add('days', -5)
    });

    const [
        fetchOrders,
        { response: ordersExpeditionInfo, inProgress: isFetchingOrders }
    ] = useRequest<OrderExpeditionInfo[]>(RequestMethod.GET);

    const isFetching = isFetchingOrders;

    const expeditionInfo = useMemo<ExpeditionInfoPerDaySum[]>(() => {
        if (!ordersExpeditionInfo) {
            return [];
        }
        return getExpeditionInfoPerDaySum(ordersExpeditionInfo);
    }, [ordersExpeditionInfo]);

    useEffect(() => {
        if (!(query.to.isValid() && query.from.isValid)) {
            return;
        }

        fetchOrders(
            undefined,
            `${API_URLS.cartageExpeditionTimeOverview}?from=${formatQueryDate(
                query.from
            )}&to=${formatQueryDate(query.to)}`
        );
    }, [query, fetchOrders]);

    const labels = expeditionInfo.map(({ expeditionDays }) => expeditionDays);

    const openOrdersData = {
        labels,
        datasets: [
            {
                label: i18next.t('cartagesExpeditionTime.orderCountSK'),
                data: expeditionInfo.map(
                    ({ countOfOrders }) => countOfOrders.SK
                ),
                backgroundColor: 'rgba(75, 92, 192, 0.2)',
                borderColor: 'rgba(75, 92, 192, 1)',
                borderWidth: 1
            },
            {
                label: i18next.t('cartagesExpeditionTime.orderCountHU'),
                data: expeditionInfo.map(
                    ({ countOfOrders }) => countOfOrders.HU
                ),
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1
            }
        ]
    };

    const handleFilterQueryDateFromUpdate = (from: Moment) => {
        setQuery({ ...query, from });
    };

    const handleFilterQueryDateToUpdate = (to: Moment) => {
        setQuery({ ...query, to });
    };

    const handleOnChartClick = ([chartElement]) => {
        if (!chartElement) {
            return null;
        }

        const country = chartElement._datasetIndex === 0 ? 'SK' : 'HU';

        dispatch(
            showNotification({
                id: 'cartage_orders',
                variant: NotificationVariant.Info,
                text:
                    'Čísla objednávok: \n' +
                    expeditionInfo[chartElement._index].orderNumbers[
                        country
                    ].join(', '),
                preventAutoHide: true
            })
        );
    };

    return (
        <>
            <h1 style={{ marginTop: '10px', marginBottom: '10px' }}>
                {i18next.t('cartagesExpeditionTime.title')}
            </h1>
            <FormControl margin="normal">
                <FormLabel component="legend">
                    {i18next.t('cartagesExpeditionTime.doneDate')}
                </FormLabel>
                <Grid container alignItems="center">
                    <JDatePicker
                        clearable
                        disabled={isFetching}
                        label={i18next.t('From')}
                        value={query.from}
                        onChange={handleFilterQueryDateFromUpdate}
                        style={{ marginRight: 10 }}
                    />
                    <JDatePicker
                        clearable
                        disabled={isFetching}
                        label={i18next.t('To')}
                        value={query.to}
                        onChange={handleFilterQueryDateToUpdate}
                    />
                    {isFetching && (
                        <CircularProgress
                            style={{ marginLeft: '20px' }}
                            size={25}
                        />
                    )}
                </Grid>
            </FormControl>

            {expeditionInfo && (
                <div style={{ maxWidth: '70rem' }}>
                    <Bar
                        data={openOrdersData}
                        options={{
                            scales: {
                                yAxes: [
                                    {
                                        id: 'itemsCount',
                                        type: 'linear',
                                        scaleLabel: {
                                            display: true,
                                            labelString: i18next.t(
                                                'cartagesExpeditionTime.ordersCount'
                                            )
                                        },
                                        ticks: {
                                            beginAtZero: true,
                                            precision: 0
                                        },
                                        position: 'left',
                                        gridLines: {
                                            display: false
                                        }
                                    }
                                ],
                                xAxes: [
                                    {
                                        scaleLabel: {
                                            display: true,
                                            labelString: i18next.t(
                                                'cartagesExpeditionTime.workingDaysCount'
                                            )
                                        }
                                    }
                                ]
                            }
                        }}
                        getElementAtEvent={handleOnChartClick}
                    />
                </div>
            )}
        </>
    );
};
