import { handleActions } from 'redux-actions';
import * as actions from './LayoutActions';
import { LayoutState } from './LayoutState';

const defaultState: LayoutState = {
    isMobileMenuOpen: false
};

const LayoutReducer = handleActions<LayoutState>(
    {
        [actions.toggleMobileMenu.toString()]: state => ({
            ...state,
            isMobileMenuOpen: !state.isMobileMenuOpen
        })
    },
    defaultState
);

export { LayoutReducer };
