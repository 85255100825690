import { User } from '../../models/User';
import { get, post } from '../../utils/request';
import { loadEnums } from '../enums/EnumsActions';
import { showError } from '../errors/ErrorsActions';
import { createAction } from 'redux-actions';
import { Dispatch } from 'redux';

export const loadingSession = createAction('LOADING_SESSION');
export const loadSessionSuccess = createAction(
    'LOAD_SESSION_SUCCESS',
    (user: User) => ({ user })
);
export const loadSessionFailure = createAction('LOAD_SESSION_FAILURE');
export function loadSession() {
    return async dispatch => {
        dispatch(loadingSession());
        try {
            const user = await get<User>('/me');
            dispatch(loadSessionSuccess(user));
            dispatch(loadEnums());
        } catch (err) {
            dispatch(loadSessionFailure());
            dispatch(showError(err));
        }
    };
}

export const loggingin = createAction('LOGGINGIN');
export const loginSuccess = createAction('LOGIN_SUCCESS');
export const loginFailure = createAction('LOGIN_FAILURE');
export function login(user: User) {
    return async (dispatch: Dispatch<any>) => {
        dispatch(loggingin());
        try {
            await post('/login', user);
            dispatch(loginSuccess());
            // @ts-ignore select
            dispatch(loadSession());
        } catch (err) {
            dispatch(loginFailure());
            dispatch(showError(err));
        }
    };
}

export const loggingout = createAction('LOGGINGOUT');
export const logoutSuccess = createAction('LOGOUT_SUCCESS');
export const logoutFailure = createAction('LOGOUT_FAILURE');
export function logout() {
    return async (dispatch: Dispatch<any>) => {
        dispatch(loggingout());
        try {
            await get('/logout');
            dispatch(logoutSuccess());
        } catch (err) {
            dispatch(logoutFailure());
            dispatch(showError(err));
        }
    };
}
