import { CircularProgress } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import React from 'react';

interface Props extends WithStyles {
    colSpan: number;
}

const decorate = withStyles(theme => {
    const style: StyleRules = {
        loader: {
            margin: theme.spacing(2)
        }
    };
    return style;
});

const TableLoader = decorate(({ colSpan, classes }: Props) => (
    <tr data-testid="orders-loader">
        <td colSpan={colSpan} style={{ textAlign: 'center' }}>
            <CircularProgress className={classes.loader} size={50} />
        </td>
    </tr>
));

export { TableLoader };
