import { SHARED_DATA_KEY } from './SharedDataActions';
import { AppState } from 'src/models/AppState';
import { Product } from 'src/models/Product';

export const selectSharedData = <T>(
    state: AppState,
    key: SHARED_DATA_KEY
): T | undefined => (state.sharedData[key] as unknown) as T | undefined;

export const selectSharedProduct = (state: AppState) =>
    selectSharedData<Product>(state, SHARED_DATA_KEY.product);
