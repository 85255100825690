import React from 'react';
import { TableLoader } from 'src/components/TableLoader';
import { TableRow, TableCell, Typography } from '@material-ui/core';
import i18next from 'i18next';
import { Availability } from 'src/models/Availability';
import { AvailabilityRow } from './AvailabilityRow';

const TABLE_COLUMNS_COUNT = 4;

interface Props {
    isLoading: boolean;
    availabilities: Availability[];
    onEditClick: (availability: Availability) => void;
    onRemoveClick: (id: string) => void;
}

export const AvailabilityRows: React.FC<Props> = ({
    isLoading,
    availabilities,
    onEditClick,
    onRemoveClick
}) => {
    if (isLoading) {
        return <TableLoader key="loader" colSpan={TABLE_COLUMNS_COUNT} />;
    }

    if (!isLoading && availabilities.length === 0) {
        return (
            <TableRow data-testid="no-availabilities" key="no-availabilities">
                <TableCell colSpan={TABLE_COLUMNS_COUNT}>
                    <Typography
                        variant="caption"
                        gutterBottom
                        align="center"
                        display="block"
                    >
                        {i18next.t('No availabilities found')}
                    </Typography>
                </TableCell>
            </TableRow>
        );
    }

    const rows = availabilities.map(availability => (
        <AvailabilityRow
            key={availability._id}
            availability={availability}
            onEditClick={onEditClick}
            onRemoveClick={onRemoveClick}
        />
    ));
    return <>{rows}</>;
};
