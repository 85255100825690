import React, { useCallback, useRef, useState } from 'react';
import { Confirm, ConfirmationModalContext } from './ConfirmationDialogContext';

type ResolveConfirm = (value: boolean) => void;

export const ConfirmationDialogProvider: React.FC = ({ children }) => {
    const [question, setQuestion] = useState('');
    const resolveConfirmRef = useRef(undefined);

    const confirm: Confirm = useCallback((questionValue: string) => {
        setQuestion(questionValue);
        return new Promise(resolve => {
            resolveConfirmRef.current = resolve;
        });
    }, []);

    const handleResolveConfirm = useCallback((value: boolean): void => {
        resolveConfirmRef.current(value);
        setQuestion('');
    }, []);

    return (
        <ConfirmationModalContext.Provider
            value={{ question, resolveConfirm: handleResolveConfirm, confirm }}
        >
            {children}
        </ConfirmationModalContext.Provider>
    );
};
