import React, { useCallback } from 'react';
import { TableRow, TableCell, Button } from '@material-ui/core';
import i18next from 'i18next';
import { formatDateTime } from 'src/utils/formatters';
import { AdminRole } from 'src/components/AdminRole';
import { OrdersImportContainer } from '../components/ordersImport/OrdersImportContainer';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Shop } from 'src/models/Shop';
import { useEnums } from 'src/common/enums/useEnumsHook';
import { resolveEnumValue } from 'src/utils/enums';
import { useConfirmationDialog } from 'src/common/confirmationDialog/useConfirmationDialog';

interface Props {
    shop: Shop;
    onEditShopClick: (shop: Shop) => void;
    onRemoveShopClick: (id: string) => void;
}

export const ShopRow: React.FC<Props> = ({
    shop,
    onEditShopClick,
    onRemoveShopClick
}) => {
    const enums = useEnums();
    const confirm = useConfirmationDialog();

    const handleEditShopClick = useCallback(() => {
        onEditShopClick(shop);
    }, [onEditShopClick, shop]);

    const handleRemoveShopClick = useCallback(async () => {
        const isConfirmed = await confirm(
            i18next.t('shopList.removeShopConfirmation')
        );
        if (!isConfirmed) {
            return;
        }

        onRemoveShopClick(shop._id);
    }, [onRemoveShopClick, confirm, shop._id]);

    return (
        <>
            <TableRow>
                <TableCell>{shop.name}</TableCell>
                <TableCell>
                    {resolveEnumValue(enums.shopType.enum, shop.type)}
                </TableCell>
                <TableCell>
                    {resolveEnumValue(enums.country.enum, shop.country)}
                </TableCell>
                <TableCell>
                    {shop.lastImportDate
                        ? formatDateTime(shop.lastImportDate)
                        : i18next.t('shopList_noImportDate')}
                </TableCell>
                <TableCell>
                    <OrdersImportContainer
                        shopId={shop._id}
                        shopType={shop.type}
                    />
                </TableCell>
                <AdminRole>
                    <TableCell>
                        <Button
                            size="small"
                            color="primary"
                            onClick={handleEditShopClick}
                        >
                            <EditIcon />
                        </Button>
                        <Button
                            size="small"
                            color="secondary"
                            onClick={handleRemoveShopClick}
                        >
                            <DeleteIcon />
                        </Button>
                    </TableCell>
                </AdminRole>
            </TableRow>
        </>
    );
};
