import React, { FC } from 'react';
import TimerIcon from '@material-ui/icons/Timer';
import { makeStyles } from '@material-ui/core';
import { deliveryType as deliveryTypeEnum } from '../common/enums/enums';

const useStyles = makeStyles({
    orderNumberWithIcon: {
        display: 'flex',
        alignItems: 'center'
    }
});

interface Props {
    orderNumber: string;
    deliveryType: string;
}

export const OrderNumber: FC<Props> = ({ orderNumber, deliveryType }) => {
    const classes = useStyles();

    if (deliveryType === deliveryTypeEnum.EXPRESS) {
        return (
            <span
                data-testid="express-order-icon"
                className={classes.orderNumberWithIcon}
            >
                {orderNumber}
                <TimerIcon color="error" />
            </span>
        );
    }

    return <>{orderNumber}</>;
};
